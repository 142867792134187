export enum hygieneMicrocopy {
  HYGIENE_BENEFIT_COLUMN_1_CONTENT = 'HYGIENE_BENEFIT_COLUMN_1_CONTENT',
  HYGIENE_BENEFIT_COLUMN_1_TITLE = 'HYGIENE_BENEFIT_COLUMN_1_TITLE',
  HYGIENE_BENEFIT_COLUMN_2_CONTENT = 'HYGIENE_BENEFIT_COLUMN_2_CONTENT',
  HYGIENE_BENEFIT_COLUMN_2_TITLE = 'HYGIENE_BENEFIT_COLUMN_2_TITLE',
  HYGIENE_BENEFIT_COLUMN_3_CONTENT = 'HYGIENE_BENEFIT_COLUMN_3_CONTENT',
  HYGIENE_BENEFIT_COLUMN_3_TITLE = 'HYGIENE_BENEFIT_COLUMN_3_TITLE',
  HYGIENE_BENEFIT_HEADER = 'HYGIENE_BENEFIT_HEADER',
  HYGIENE_CAR_FINANCE = 'HYGIENE_CAR_FINANCE',
  HYGIENE_CAR_FINANCE_APPLICATION = 'HYGIENE_CAR_FINANCE_APPLICATION',
  HYGIENE_CAR_FINANCE_CTA = 'HYGIENE_CAR_FINANCE_CTA',
  HYGIENE_CAR_FINANCE_QUOTE = 'HYGIENE_CAR_FINANCE_QUOTE',
  HYGIENE_CAR_FINANCE_RATES = 'HYGIENE_CAR_FINANCE_RATES',
  HYGIENE_CAROUSEL_BODY_TYPE_0_CTA = 'HYGIENE_CAROUSEL_BODY_TYPE_0_CTA',
  HYGIENE_CAROUSEL_BODY_TYPE_0_LABEL = 'HYGIENE_CAROUSEL_BODY_TYPE_0_LABEL',
  HYGIENE_CAROUSEL_BODY_TYPE_1_CTA = 'HYGIENE_CAROUSEL_BODY_TYPE_1_CTA',
  HYGIENE_CAROUSEL_BODY_TYPE_1_LABEL = 'HYGIENE_CAROUSEL_BODY_TYPE_1_LABEL',
  HYGIENE_CAROUSEL_BODY_TYPE_2_CTA = 'HYGIENE_CAROUSEL_BODY_TYPE_2_CTA',
  HYGIENE_CAROUSEL_BODY_TYPE_2_LABEL = 'HYGIENE_CAROUSEL_BODY_TYPE_2_LABEL',
  HYGIENE_CAROUSEL_BODY_TYPE_3_CTA = 'HYGIENE_CAROUSEL_BODY_TYPE_3_CTA',
  HYGIENE_CAROUSEL_BODY_TYPE_3_LABEL = 'HYGIENE_CAROUSEL_BODY_TYPE_3_LABEL',
  HYGIENE_CAROUSEL_BODY_TYPE_4_CTA = 'HYGIENE_CAROUSEL_BODY_TYPE_4_CTA',
  HYGIENE_CAROUSEL_BODY_TYPE_4_LABEL = 'HYGIENE_CAROUSEL_BODY_TYPE_4_LABEL',
  HYGIENE_CAROUSEL_BODY_TYPE_5_CTA = 'HYGIENE_CAROUSEL_BODY_TYPE_5_CTA',
  HYGIENE_CAROUSEL_BODY_TYPE_5_LABEL = 'HYGIENE_CAROUSEL_BODY_TYPE_5_LABEL',
  HYGIENE_CAROUSEL_BODY_TYPE_6_CTA = 'HYGIENE_CAROUSEL_BODY_TYPE_6_CTA',
  HYGIENE_CAROUSEL_BODY_TYPE_6_LABEL = 'HYGIENE_CAROUSEL_BODY_TYPE_6_LABEL',
  HYGIENE_CAROUSEL_BODY_TYPE_7_CTA = 'HYGIENE_CAROUSEL_BODY_TYPE_7_CTA',
  HYGIENE_CAROUSEL_BODY_TYPE_7_LABEL = 'HYGIENE_CAROUSEL_BODY_TYPE_7_LABEL',
  HYGIENE_CAROUSEL_BODY_TYPE_8_CTA = 'HYGIENE_CAROUSEL_BODY_TYPE_8_CTA',
  HYGIENE_CAROUSEL_BODY_TYPE_8_LABEL = 'HYGIENE_CAROUSEL_BODY_TYPE_8_LABEL',
  HYGIENE_CAROUSEL_BODY_TYPE_TITLE = 'HYGIENE_CAROUSEL_BODY_TYPE_TITLE',
  HYGIENE_CAROUSEL_BUDGET_0_CTA = 'HYGIENE_CAROUSEL_BUDGET_0_CTA',
  HYGIENE_CAROUSEL_BUDGET_0_LABEL = 'HYGIENE_CAROUSEL_BUDGET_0_LABEL',
  HYGIENE_CAROUSEL_BUDGET_1_CTA = 'HYGIENE_CAROUSEL_BUDGET_1_CTA',
  HYGIENE_CAROUSEL_BUDGET_1_LABEL = 'HYGIENE_CAROUSEL_BUDGET_1_LABEL',
  HYGIENE_CAROUSEL_BUDGET_2_CTA = 'HYGIENE_CAROUSEL_BUDGET_2_CTA',
  HYGIENE_CAROUSEL_BUDGET_2_LABEL = 'HYGIENE_CAROUSEL_BUDGET_2_LABEL',
  HYGIENE_CAROUSEL_BUDGET_3_CTA = 'HYGIENE_CAROUSEL_BUDGET_3_CTA',
  HYGIENE_CAROUSEL_BUDGET_3_LABEL = 'HYGIENE_CAROUSEL_BUDGET_3_LABEL',
  HYGIENE_CAROUSEL_BUDGET_4_CTA = 'HYGIENE_CAROUSEL_BUDGET_4_CTA',
  HYGIENE_CAROUSEL_BUDGET_4_LABEL = 'HYGIENE_CAROUSEL_BUDGET_4_LABEL',
  HYGIENE_CAROUSEL_BUDGET_TITLE = 'HYGIENE_CAROUSEL_BUDGET_TITLE',
  HYGIENE_CAROUSEL_PROMOTION_TITLE = 'HYGIENE_CAROUSEL_PROMOTION_TITLE',
  HYGIENE_CONTACT_US_GET_IN_TOUCH_CONTENT = 'HYGIENE_CONTACT_US_GET_IN_TOUCH_CONTENT',
  HYGIENE_CONTACT_US_GET_IN_TOUCH_HOURS = 'HYGIENE_CONTACT_US_GET_IN_TOUCH_HOURS',
  HYGIENE_COVID_MESSAGE = 'HYGIENE_COVID_MESSAGE',
  HYGIENE_EMAIL_CAR_ALERT_UNSUBSCRIBE_CONTENT = 'HYGIENE_EMAIL_CAR_ALERT_UNSUBSCRIBE_CONTENT',
  HYGIENE_EMAIL_CAR_ALERT_UNSUBSCRIBE_TITLE = 'HYGIENE_EMAIL_CAR_ALERT_UNSUBSCRIBE_TITLE',
  HYGIENE_EMAIL_DEFAULT_UNSUBSCRIBE_TITLE = 'HYGIENE_EMAIL_DEFAULT_UNSUBSCRIBE_TITLE',
  HYGIENE_EMAIL_UNSUBSCRIBE_BUTTON_LABEL = 'HYGIENE_EMAIL_UNSUBSCRIBE_BUTTON_LABEL',
  HYGIENE_EMAIL_UNSUBSCRIBE_TITLE = 'HYGIENE_EMAIL_UNSUBSCRIBE_TITLE',
  HYGIENE_EMAIL_UNSUBSCRIBE_WATCHLIST_VEHICLE_AVAILABLE_TITLE = 'HYGIENE_EMAIL_UNSUBSCRIBE_WATCHLIST_VEHICLE_AVAILABLE_TITLE',
  HYGIENE_FINANCE_CALCULATOR_CTA = 'HYGIENE_FINANCE_CALCULATOR_CTA',
  HYGIENE_FINANCE_CALCULATOR_INPUT_LABEL_1 = 'HYGIENE_FINANCE_CALCULATOR_INPUT_LABEL_1',
  HYGIENE_FINANCE_CALCULATOR_INPUT_LABEL_2 = 'HYGIENE_FINANCE_CALCULATOR_INPUT_LABEL_2',
  HYGIENE_FINANCE_CALCULATOR_INPUT_LABEL_3 = 'HYGIENE_FINANCE_CALCULATOR_INPUT_LABEL_3',
  HYGIENE_FINANCE_CALCULATOR_LOAN_TITLE = 'HYGIENE_FINANCE_CALCULATOR_LOAN_TITLE',
  HYGIENE_FINANCE_CALCULATOR_LOAN_TITLE_ESTIMATED_BORROW_AMOUNT = 'HYGIENE_FINANCE_CALCULATOR_LOAN_TITLE_ESTIMATED_BORROW_AMOUNT',
  HYGIENE_FINANCE_CALCULATOR_LOAN_TITLE_ESTIMATED_REPAYMENT = 'HYGIENE_FINANCE_CALCULATOR_LOAN_TITLE_ESTIMATED_REPAYMENT',
  HYGIENE_FINANCE_CALCULATOR_TITLE = 'HYGIENE_FINANCE_CALCULATOR',
  HYGIENE_FINANCE_CALCULATOR_WEEKLY_FEE = 'HYGIENE_FINANCE_CALCULATOR_WEEKLY_FEE',
  HYGIENE_FINANCE_PROVIDERS_COLUMN_1_DESCRIPTION = 'HYGIENE_FINANCE_PROVIDERS_COLUMN_1_DESCRIPTION',
  HYGIENE_FINANCE_PROVIDERS_COLUMN_2_DESCRIPTION = 'HYGIENE_FINANCE_PROVIDERS_COLUMN_2_DESCRIPTION',
  HYGIENE_FINANCE_PROVIDERS_COLUMN_3_DESCRIPTION = 'HYGIENE_FINANCE_PROVIDERS_COLUMN_3_DESCRIPTION',
  HYGIENE_FINANCE_PROVIDERS_HEADER = 'HYGIENE_FINANCE_PROVIDERS_HEADER',
  HYGIENE_FIVE_GREAT_THINGS_ABOUT_FINANCE_HEADER = 'HYGIENE_FIVE_GREAT_THINGS_ABOUT_FINANCE_HEADER',
  HYGIENE_FIVE_GREAT_THINGS_ABOUT_FINANCE_ITEM_1 = 'HYGIENE_FIVE_GREAT_THINGS_ABOUT_FINANCE_ITEM_1',
  HYGIENE_FIVE_GREAT_THINGS_ABOUT_FINANCE_ITEM_2 = 'HYGIENE_FIVE_GREAT_THINGS_ABOUT_FINANCE_ITEM_2',
  HYGIENE_FIVE_GREAT_THINGS_ABOUT_FINANCE_ITEM_3 = 'HYGIENE_FIVE_GREAT_THINGS_ABOUT_FINANCE_ITEM_3',
  HYGIENE_FIVE_GREAT_THINGS_ABOUT_FINANCE_ITEM_4 = 'HYGIENE_FIVE_GREAT_THINGS_ABOUT_FINANCE_ITEM_4',
  HYGIENE_FIVE_GREAT_THINGS_ABOUT_FINANCE_ITEM_5 = 'HYGIENE_FIVE_GREAT_THINGS_ABOUT_FINANCE_ITEM_5',
  HYGIENE_FLEXIBLE_PAYMENT_ADS = 'HYGIENE_FLEXIBLE_PAYMENT_ADS',
  HYGIENE_FLEXIBLE_PAYMENT_COLUMN_1 = 'HYGIENE_FLEXIBLE_PAYMENT_COLUMN_1',
  HYGIENE_FLEXIBLE_PAYMENT_COLUMN_2 = 'HYGIENE_FLEXIBLE_PAYMENT_COLUMN_2',
  HYGIENE_FLEXIBLE_PAYMENT_COLUMN_3 = 'HYGIENE_FLEXIBLE_PAYMENT_COLUMN_3',
  HYGIENE_FLEXIBLE_PAYMENT_CTA = 'HYGIENE_FLEXIBLE_PAYMENT_CTA',
  HYGIENE_FLEXIBLE_PAYMENT_HEADER = 'HYGIENE_FLEXIBLE_PAYMENT_HEADER',
  HYGIENE_FLEXIBLE_PAYMENT_REMINDER = 'HYGIENE_FLEXIBLE_PAYMENT_REMINDER',
  HYGIENE_FLEXIBLE_TABLE_SECTION_NONE_LABEL = 'HYGIENE_FLEXIBLE_TABLE_SECTION_NONE_LABEL',
  HYGIENE_HOMEPAGE_BROWSE_ALL_CARS = 'HYGIENE_HOMEPAGE_BROWSE_ALL_CARS',
  HYGIENE_HOMEPAGE_HEADER_NEW_SEARCH_PLACEHOLDER = 'HYGIENE_HOMEPAGE_HEADER_NEW_SEARCH_PLACEHOLDER',
  HYGIENE_HOMEPAGE_HERO_CARMATCH = 'HYGIENE_HOMEPAGE_HERO_CARMATCH',
  HYGIENE_HOMEPAGE_HERO_HEADING = 'HYGIENE_HOMEPAGE_HERO_HEADING',
  HYGIENE_HOMEPAGE_HERO_SUBHEADING = 'HYGIENE_HOMEPAGE_HERO_SUBHEADING',
  HYGIENE_HOMEPAGE_HIGHLIGHT_CARMA_SPONSORSHIPS_DESCRIPTION = 'HYGIENE_HOMEPAGE_HIGHLIGHT_CARMA_SPONSORSHIPS_DESCRIPTION',
  HYGIENE_HOMEPAGE_HIGHLIGHT_CARMA_SPONSORSHIPS_HEADER = 'HYGIENE_HOMEPAGE_HIGHLIGHT_CARMA_SPONSORSHIPS_HEADER',
  HYGIENE_HOMEPAGE_NEW_HERO_HEADING = 'HYGIENE_HOMEPAGE_NEW_HERO_HEADING',
  HYGIENE_HOMEPAGE_NEW_USP_CHECKLIST = 'HYGIENE_HOMEPAGE_NEW_USP_CHECKLIST',
  HYGIENE_HOMEPAGE_NEW_USP_RETURN = 'HYGIENE_HOMEPAGE_NEW_USP_RETURN',
  HYGIENE_HOMEPAGE_SEARCH_MAKE = 'HYGIENE_HOMEPAGE_SEARCH_MAKE',
  HYGIENE_HOMEPAGE_SEARCH_MODEL = 'HYGIENE_HOMEPAGE_SEARCH_MODEL',
  HYGIENE_HOMEPAGE_USP_CHECKLIST = 'HYGIENE_HOMEPAGE_USP_CHECKLIST',
  HYGIENE_HOMEPAGE_USP_RETURN = 'HYGIENE_HOMEPAGE_USP_RETURN',
  HYGIENE_HOMEPAGE_USP_WARRANTY = 'HYGIENE_HOMEPAGE_USP_WARRANTY',
  HYGIENE_HOW_CARMA_WORKS_COLUMN_1_SUMMARY = 'HYGIENE_HOW_CARMA_WORKS_COLUMN_1_SUMMARY',
  HYGIENE_HOW_CARMA_WORKS_COLUMN_1_TITLE = 'HYGIENE_HOW_CARMA_WORKS_COLUMN_1_TITLE',
  HYGIENE_HOW_CARMA_WORKS_COLUMN_2_SUMMARY = 'HYGIENE_HOW_CARMA_WORKS_COLUMN_2_SUMMARY',
  HYGIENE_HOW_CARMA_WORKS_COLUMN_2_TITLE = 'HYGIENE_HOW_CARMA_WORKS_COLUMN_2_TITLE',
  HYGIENE_HOW_CARMA_WORKS_COLUMN_3_SUMMARY = 'HYGIENE_HOW_CARMA_WORKS_COLUMN_3_SUMMARY',
  HYGIENE_HOW_CARMA_WORKS_COLUMN_3_TITLE = 'HYGIENE_HOW_CARMA_WORKS_COLUMN_3_TITLE',
  HYGIENE_HOW_CARMA_WORKS_CTA = 'HYGIENE_HOW_CARMA_WORKS_CTA',
  HYGIENE_HOW_CARMA_WORKS_HEADER = 'HYGIENE_HOW_CARMA_WORKS_HEADER',
  HYGIENE_HOW_FINANCE_WORKS_HERO = 'HYGIENE_HOW_FINANCE_WORKS_HERO',
  HYGIENE_JOIN_THE_1000S = 'HYGIENE_JOIN_THE_1000S',
  HYGIENE_NEW_CARS_EVERY_DAY = 'HYGIENE_NEW_CARS_EVERY_DAY',
  HYGIENE_NRMA = 'HYGIENE_NRMA',
  HYGIENE_OUR_COMMITMENT_COLUMN_1 = 'HYGIENE_OUR_COMMITMENT_COLUMN_1',
  HYGIENE_OUR_COMMITMENT_COLUMN_2 = 'HYGIENE_OUR_COMMITMENT_COLUMN_2',
  HYGIENE_OUR_COMMITMENT_COLUMN_3 = 'HYGIENE_OUR_COMMITMENT_COLUMN_3',
  HYGIENE_OUR_COMMITMENT_COLUMN_4 = 'HYGIENE_OUR_COMMITMENT_COLUMN_4',
  HYGIENE_OUR_COMMITMENT_HEADER = 'HYGIENE_OUR_COMMITMENT_HEADER',
  HYGIENE_PROMOTION = 'HYGIENE_PROMOTION',
  HYGIENE_PROMOTION_CTA = 'HYGIENE_PROMOTION_CTA',
  HYGIENE_PROMOTION_LINK = 'HYGIENE_PROMOTION_LINK',
  HYGIENE_SOCIAL_PROOF_DESCRIPTION_1 = 'HYGIENE_SOCIAL_PROOF_DESCRIPTION_1',
  HYGIENE_SOCIAL_PROOF_DESCRIPTION_2 = 'HYGIENE_SOCIAL_PROOF_DESCRIPTION_2',
  HYGIENE_SOCIAL_PROOF_HEADER = 'HYGIENE_SOCIAL_PROOF_HEADER',
  HYGIENE_TRADE_IN_ENQUIRY_CAR_ADDITIONAL_DETAILS_FIELD_COLOUR = 'HYGIENE_TRADE_IN_ENQUIRY_CAR_ADDITIONAL_DETAILS_FIELD_COLOUR',
  HYGIENE_TRADE_IN_ENQUIRY_CAR_ADDITIONAL_DETAILS_FIELD_FINANCE_OWING = 'HYGIENE_TRADE_IN_ENQUIRY_CAR_ADDITIONAL_DETAILS_FIELD_FINANCE_OWING',
  HYGIENE_TRADE_IN_ENQUIRY_CAR_ADDITIONAL_DETAILS_FIELD_FINANCE_OWING_SUBTITLE = 'HYGIENE_TRADE_IN_ENQUIRY_CAR_ADDITIONAL_DETAILS_FIELD_FINANCE_OWING_SUBTITLE',
  HYGIENE_TRADE_IN_ENQUIRY_CAR_ADDITIONAL_DETAILS_FIELD_ODOMETER = 'HYGIENE_TRADE_IN_ENQUIRY_CAR_ADDITIONAL_DETAILS_FIELD_ODOMETER',
  HYGIENE_TRADE_IN_ENQUIRY_CAR_ADDITIONAL_DETAILS_FIELD_ODOMETER_PLACEHOLDER = 'HYGIENE_TRADE_IN_ENQUIRY_CAR_ADDITIONAL_DETAILS_FIELD_ODOMETER_PLACEHOLDER',
  HYGIENE_TRADE_IN_ENQUIRY_CAR_ADDITIONAL_DETAILS_FIELD_OPTIONAL_EXTRAS = 'HYGIENE_TRADE_IN_ENQUIRY_CAR_ADDITIONAL_DETAILS_FIELD_OPTIONAL_EXTRAS',
  HYGIENE_TRADE_IN_ENQUIRY_CAR_ADDITIONAL_DETAILS_FIELD_OPTIONAL_EXTRAS_HELPER = 'HYGIENE_TRADE_IN_ENQUIRY_CAR_ADDITIONAL_DETAILS_FIELD_OPTIONAL_EXTRAS_HELPER',
  HYGIENE_TRADE_IN_ENQUIRY_CAR_ADDITIONAL_DETAILS_FIELD_OPTIONAL_EXTRAS_PLACEHOLDER = 'HYGIENE_TRADE_IN_ENQUIRY_CAR_ADDITIONAL_DETAILS_FIELD_OPTIONAL_EXTRAS_PLACEHOLDER',
  HYGIENE_TRADE_IN_ENQUIRY_CAR_ADDITIONAL_DETAILS_SUBTITLE = 'HYGIENE_TRADE_IN_ENQUIRY_CAR_ADDITIONAL_DETAILS_SUBTITLE',
  HYGIENE_TRADE_IN_ENQUIRY_CAR_ADDITIONAL_DETAILS_TITLE = 'HYGIENE_TRADE_IN_ENQUIRY_CAR_ADDITIONAL_DETAILS_TITLE',
  HYGIENE_TRADE_IN_ENQUIRY_CAR_HISTORY_FIELD_DISTANCE_TYRES = 'HYGIENE_TRADE_IN_ENQUIRY_CAR_HISTORY_FIELD_DISTANCE_TYRES',
  HYGIENE_TRADE_IN_ENQUIRY_CAR_HISTORY_FIELD_DISTANCE_TYRES_OPTION_1K_TO_5K = 'HYGIENE_TRADE_IN_ENQUIRY_CAR_HISTORY_FIELD_DISTANCE_TYRES_OPTION_1K_TO_5K',
  HYGIENE_TRADE_IN_ENQUIRY_CAR_HISTORY_FIELD_DISTANCE_TYRES_OPTION_OVER_5K = 'HYGIENE_TRADE_IN_ENQUIRY_CAR_HISTORY_FIELD_DISTANCE_TYRES_OPTION_OVER_5K',
  HYGIENE_TRADE_IN_ENQUIRY_CAR_HISTORY_FIELD_DISTANCE_TYRES_OPTION_UNDER_1K = 'HYGIENE_TRADE_IN_ENQUIRY_CAR_HISTORY_FIELD_DISTANCE_TYRES_OPTION_UNDER_1K',
  HYGIENE_TRADE_IN_ENQUIRY_CAR_HISTORY_FIELD_DISTANCE_TYRES_OPTION_UNKNOWN = 'HYGIENE_TRADE_IN_ENQUIRY_CAR_HISTORY_FIELD_DISTANCE_TYRES_OPTION_UNKNOWN',
  HYGIENE_TRADE_IN_ENQUIRY_CAR_HISTORY_FIELD_DISTANCE_TYRES_PLACEHOLDER = 'HYGIENE_TRADE_IN_ENQUIRY_CAR_HISTORY_FIELD_DISTANCE_TYRES_PLACEHOLDER',
  HYGIENE_TRADE_IN_ENQUIRY_CAR_HISTORY_FIELD_FIRST_OWNER = 'HYGIENE_TRADE_IN_ENQUIRY_CAR_HISTORY_FIELD_FIRST_OWNER',
  HYGIENE_TRADE_IN_ENQUIRY_CAR_HISTORY_FIELD_MAJOR_ACCIDENT = 'HYGIENE_TRADE_IN_ENQUIRY_CAR_HISTORY_FIELD_MAJOR_ACCIDENT',
  HYGIENE_TRADE_IN_ENQUIRY_CAR_HISTORY_FIELD_NUMBER_OF_KEYS = 'HYGIENE_TRADE_IN_ENQUIRY_CAR_HISTORY_FIELD_NUMBER_OF_KEYS',
  HYGIENE_TRADE_IN_ENQUIRY_CAR_HISTORY_FIELD_NUMBER_OF_KEYS_OPTION_1 = 'HYGIENE_TRADE_IN_ENQUIRY_CAR_HISTORY_FIELD_NUMBER_OF_KEYS_OPTION_1',
  HYGIENE_TRADE_IN_ENQUIRY_CAR_HISTORY_FIELD_NUMBER_OF_KEYS_OPTION_2_PLUS = 'HYGIENE_TRADE_IN_ENQUIRY_CAR_HISTORY_FIELD_NUMBER_OF_KEYS_OPTION_2_PLUS',
  HYGIENE_TRADE_IN_ENQUIRY_CAR_HISTORY_FIELD_SERVICE_HISTORY = 'HYGIENE_TRADE_IN_ENQUIRY_CAR_HISTORY_FIELD_SERVICE_HISTORY',
  HYGIENE_TRADE_IN_ENQUIRY_CAR_HISTORY_FIELD_SERVICE_HISTORY_OPTION_FULL = 'HYGIENE_TRADE_IN_ENQUIRY_CAR_HISTORY_FIELD_SERVICE_HISTORY_OPTION_FULL',
  HYGIENE_TRADE_IN_ENQUIRY_CAR_HISTORY_FIELD_SERVICE_HISTORY_OPTION_NONE = 'HYGIENE_TRADE_IN_ENQUIRY_CAR_HISTORY_FIELD_SERVICE_HISTORY_OPTION_NONE',
  HYGIENE_TRADE_IN_ENQUIRY_CAR_HISTORY_FIELD_SERVICE_HISTORY_OPTION_PARTIAL = 'HYGIENE_TRADE_IN_ENQUIRY_CAR_HISTORY_FIELD_SERVICE_HISTORY_OPTION_PARTIAL',
  HYGIENE_TRADE_IN_ENQUIRY_CAR_HISTORY_FIELD_SERVICE_HISTORY_OPTION_PARTIAL_GENUINE = 'HYGIENE_TRADE_IN_ENQUIRY_CAR_HISTORY_FIELD_SERVICE_HISTORY_OPTION_PARTIAL_GENUINE',
  HYGIENE_TRADE_IN_ENQUIRY_CAR_HISTORY_FIELD_SERVICE_HISTORY_PLACEHOLDER = 'HYGIENE_TRADE_IN_ENQUIRY_CAR_HISTORY_FIELD_SERVICE_HISTORY_PLACEHOLDER',
  HYGIENE_TRADE_IN_ENQUIRY_CAR_HISTORY_TITLE = 'HYGIENE_TRADE_IN_ENQUIRY_CAR_HISTORY_TITLE',
  HYGIENE_TRADE_IN_ENQUIRY_CAR_PHOTOS_EMAIL_LATER_BUTTON_LABEL = 'HYGIENE_TRADE_IN_ENQUIRY_CAR_PHOTOS_EMAIL_LATER_BUTTON_LABEL',
  HYGIENE_TRADE_IN_ENQUIRY_CAR_PHOTOS_EMAIL_LATER_DESCRIPTION = 'HYGIENE_TRADE_IN_ENQUIRY_CAR_PHOTOS_EMAIL_LATER_DESCRIPTION',
  HYGIENE_TRADE_IN_ENQUIRY_CAR_PHOTOS_EMAIL_LATER_TITLE = 'HYGIENE_TRADE_IN_ENQUIRY_CAR_PHOTOS_EMAIL_LATER_TITLE',
  HYGIENE_TRADE_IN_ENQUIRY_CAR_PHOTOS_EXTERIOR_CONDITION_SUBTITLE = 'HYGIENE_TRADE_IN_ENQUIRY_CAR_PHOTOS_EXTERIOR_CONDITION_SUBTITLE',
  HYGIENE_TRADE_IN_ENQUIRY_CAR_PHOTOS_EXTERIOR_CONDITION_TITLE = 'HYGIENE_TRADE_IN_ENQUIRY_CAR_PHOTOS_EXTERIOR_CONDITION_TITLE',
  HYGIENE_TRADE_IN_ENQUIRY_CAR_PHOTOS_FIELD_BACK = 'HYGIENE_TRADE_IN_ENQUIRY_CAR_PHOTOS_FIELD_BACK',
  HYGIENE_TRADE_IN_ENQUIRY_CAR_PHOTOS_FIELD_FRONT = 'HYGIENE_TRADE_IN_ENQUIRY_CAR_PHOTOS_FIELD_FRONT',
  HYGIENE_TRADE_IN_ENQUIRY_CAR_PHOTOS_FIELD_LEFT = 'HYGIENE_TRADE_IN_ENQUIRY_CAR_PHOTOS_FIELD_LEFT',
  HYGIENE_TRADE_IN_ENQUIRY_CAR_PHOTOS_FIELD_RIGHT = 'HYGIENE_TRADE_IN_ENQUIRY_CAR_PHOTOS_FIELD_RIGHT',
  HYGIENE_TRADE_IN_ENQUIRY_CAR_PHOTOS_INTERIOR_CONDITION_SUBTITLE = 'HYGIENE_TRADE_IN_ENQUIRY_CAR_PHOTOS_INTERIOR_CONDITION_SUBTITLE',
  HYGIENE_TRADE_IN_ENQUIRY_CAR_PHOTOS_INTERIOR_CONDITION_TITLE = 'HYGIENE_TRADE_IN_ENQUIRY_CAR_PHOTOS_INTERIOR_CONDITION_TITLE',
  HYGIENE_TRADE_IN_ENQUIRY_CAR_PHOTOS_MAKE_AND_MODEL_SUBTITLE = 'HYGIENE_TRADE_IN_ENQUIRY_CAR_PHOTOS_MAKE_AND_MODEL_SUBTITLE',
  HYGIENE_TRADE_IN_ENQUIRY_CAR_PHOTOS_MAKE_AND_MODEL_TITLE = 'HYGIENE_TRADE_IN_ENQUIRY_CAR_PHOTOS_MAKE_AND_MODEL_TITLE',
  HYGIENE_TRADE_IN_ENQUIRY_CAR_PHOTOS_OPTIONAL_EXTRAS_SUBTITLE = 'HYGIENE_TRADE_IN_ENQUIRY_CAR_PHOTOS_OPTIONAL_EXTRAS_SUBTITLE',
  HYGIENE_TRADE_IN_ENQUIRY_CAR_PHOTOS_OPTIONAL_EXTRAS_TITLE = 'HYGIENE_TRADE_IN_ENQUIRY_CAR_PHOTOS_OPTIONAL_EXTRAS_TITLE',
  HYGIENE_TRADE_IN_ENQUIRY_CAR_PHOTOS_QR_DESCRIPTION = 'HYGIENE_TRADE_IN_ENQUIRY_CAR_PHOTOS_QR_DESCRIPTION',
  HYGIENE_TRADE_IN_ENQUIRY_CAR_PHOTOS_QR_TITLE = 'HYGIENE_TRADE_IN_ENQUIRY_CAR_PHOTOS_QR_TITLE',
  HYGIENE_TRADE_IN_ENQUIRY_CAR_PHOTOS_SAVE_UPLOAD_BUTTON = 'HYGIENE_TRADE_IN_ENQUIRY_CAR_PHOTOS_SAVE_UPLOAD_BUTTON',
  HYGIENE_TRADE_IN_ENQUIRY_CAR_PHOTOS_SUBTITLE = 'HYGIENE_TRADE_IN_ENQUIRY_CAR_PHOTOS_SUBTITLE',
  HYGIENE_TRADE_IN_ENQUIRY_CAR_PHOTOS_TITLE = 'HYGIENE_TRADE_IN_ENQUIRY_CAR_PHOTOS_TITLE',
  HYGIENE_TRADE_IN_ENQUIRY_CAR_PHOTOS_UPLOAD_BUTTON = 'HYGIENE_TRADE_IN_ENQUIRY_CAR_PHOTOS_UPLOAD_BUTTON',
  HYGIENE_TRADE_IN_ENQUIRY_CAR_PHOTOS_UPLOAD_PLACEHOLDER_HEADING = 'HYGIENE_TRADE_IN_ENQUIRY_CAR_PHOTOS_UPLOAD_PLACEHOLDER_HEADING',
  HYGIENE_TRADE_IN_ENQUIRY_CAR_PHOTOS_UPLOAD_PLACEHOLDER_TEXT = 'HYGIENE_TRADE_IN_ENQUIRY_CAR_PHOTOS_UPLOAD_PLACEHOLDER_TEXT',
  HYGIENE_TRADE_IN_ENQUIRY_DEFAULT_NEXT = 'HYGIENE_TRADE_IN_ENQUIRY_DEFAULT_NEXT',
  HYGIENE_TRADE_IN_ENQUIRY_DROP_IMAGE = 'HYGIENE_TRADE_IN_ENQUIRY_DROP_IMAGE',
  HYGIENE_TRADE_IN_ENQUIRY_EDIT_CAR_DETAILS_FIELD_BODY_TYPE = 'HYGIENE_TRADE_IN_ENQUIRY_EDIT_CAR_DETAILS_FIELD_BODY_TYPE',
  HYGIENE_TRADE_IN_ENQUIRY_EDIT_CAR_DETAILS_FIELD_BODY_TYPE_OPTION_CONVERTIBLE = 'HYGIENE_TRADE_IN_ENQUIRY_EDIT_CAR_DETAILS_FIELD_BODY_TYPE_OPTION_CONVERTIBLE',
  HYGIENE_TRADE_IN_ENQUIRY_EDIT_CAR_DETAILS_FIELD_BODY_TYPE_OPTION_COUPE = 'HYGIENE_TRADE_IN_ENQUIRY_EDIT_CAR_DETAILS_FIELD_BODY_TYPE_OPTION_COUPE',
  HYGIENE_TRADE_IN_ENQUIRY_EDIT_CAR_DETAILS_FIELD_BODY_TYPE_OPTION_HATCH = 'HYGIENE_TRADE_IN_ENQUIRY_EDIT_CAR_DETAILS_FIELD_BODY_TYPE_OPTION_HATCH',
  HYGIENE_TRADE_IN_ENQUIRY_EDIT_CAR_DETAILS_FIELD_BODY_TYPE_OPTION_PEOPLE_MOVER = 'HYGIENE_TRADE_IN_ENQUIRY_EDIT_CAR_DETAILS_FIELD_BODY_TYPE_OPTION_PEOPLE_MOVER',
  HYGIENE_TRADE_IN_ENQUIRY_EDIT_CAR_DETAILS_FIELD_BODY_TYPE_OPTION_SEDAN = 'HYGIENE_TRADE_IN_ENQUIRY_EDIT_CAR_DETAILS_FIELD_BODY_TYPE_OPTION_SEDAN',
  HYGIENE_TRADE_IN_ENQUIRY_EDIT_CAR_DETAILS_FIELD_BODY_TYPE_OPTION_SUV = 'HYGIENE_TRADE_IN_ENQUIRY_EDIT_CAR_DETAILS_FIELD_BODY_TYPE_OPTION_SUV',
  HYGIENE_TRADE_IN_ENQUIRY_EDIT_CAR_DETAILS_FIELD_BODY_TYPE_OPTION_UTE = 'HYGIENE_TRADE_IN_ENQUIRY_EDIT_CAR_DETAILS_FIELD_BODY_TYPE_OPTION_UTE',
  HYGIENE_TRADE_IN_ENQUIRY_EDIT_CAR_DETAILS_FIELD_BODY_TYPE_OPTION_VAN = 'HYGIENE_TRADE_IN_ENQUIRY_EDIT_CAR_DETAILS_FIELD_BODY_TYPE_OPTION_VAN',
  HYGIENE_TRADE_IN_ENQUIRY_EDIT_CAR_DETAILS_FIELD_BODY_TYPE_OPTION_WAGON = 'HYGIENE_TRADE_IN_ENQUIRY_EDIT_CAR_DETAILS_FIELD_BODY_TYPE_OPTION_WAGON',
  HYGIENE_TRADE_IN_ENQUIRY_EDIT_CAR_DETAILS_FIELD_FUEL_TYPE = 'HYGIENE_TRADE_IN_ENQUIRY_EDIT_CAR_DETAILS_FIELD_FUEL_TYPE',
  HYGIENE_TRADE_IN_ENQUIRY_EDIT_CAR_DETAILS_FIELD_FUEL_TYPE_OPTION_DIESEL = 'HYGIENE_TRADE_IN_ENQUIRY_EDIT_CAR_DETAILS_FIELD_FUEL_TYPE_OPTION_DIESEL',
  HYGIENE_TRADE_IN_ENQUIRY_EDIT_CAR_DETAILS_FIELD_FUEL_TYPE_OPTION_ELECTRIC = 'HYGIENE_TRADE_IN_ENQUIRY_EDIT_CAR_DETAILS_FIELD_FUEL_TYPE_OPTION_ELECTRIC',
  HYGIENE_TRADE_IN_ENQUIRY_EDIT_CAR_DETAILS_FIELD_FUEL_TYPE_OPTION_HYBRID = 'HYGIENE_TRADE_IN_ENQUIRY_EDIT_CAR_DETAILS_FIELD_FUEL_TYPE_OPTION_HYBRID',
  HYGIENE_TRADE_IN_ENQUIRY_EDIT_CAR_DETAILS_FIELD_FUEL_TYPE_OPTION_LPG = 'HYGIENE_TRADE_IN_ENQUIRY_EDIT_CAR_DETAILS_FIELD_FUEL_TYPE_OPTION_LPG',
  HYGIENE_TRADE_IN_ENQUIRY_EDIT_CAR_DETAILS_FIELD_FUEL_TYPE_OPTION_PETROL = 'HYGIENE_TRADE_IN_ENQUIRY_EDIT_CAR_DETAILS_FIELD_FUEL_TYPE_OPTION_PETROL',
  HYGIENE_TRADE_IN_ENQUIRY_EDIT_CAR_DETAILS_FIELD_FUEL_TYPE_OPTION_PLUG_IN_HYBRID = 'HYGIENE_TRADE_IN_ENQUIRY_EDIT_CAR_DETAILS_FIELD_FUEL_TYPE_OPTION_PLUG_IN_HYBRID',
  HYGIENE_TRADE_IN_ENQUIRY_EDIT_CAR_DETAILS_FIELD_MAKE_NAME = 'HYGIENE_TRADE_IN_ENQUIRY_EDIT_CAR_DETAILS_FIELD_MAKE_NAME',
  HYGIENE_TRADE_IN_ENQUIRY_EDIT_CAR_DETAILS_FIELD_MODEL_NAME = 'HYGIENE_TRADE_IN_ENQUIRY_EDIT_CAR_DETAILS_FIELD_MODEL_NAME',
  HYGIENE_TRADE_IN_ENQUIRY_EDIT_CAR_DETAILS_FIELD_MODEL_YEAR = 'HYGIENE_TRADE_IN_ENQUIRY_EDIT_CAR_DETAILS_FIELD_MODEL_YEAR',
  HYGIENE_TRADE_IN_ENQUIRY_EDIT_CAR_DETAILS_FIELD_REGO = 'HYGIENE_TRADE_IN_ENQUIRY_EDIT_CAR_DETAILS_FIELD_REGO',
  HYGIENE_TRADE_IN_ENQUIRY_EDIT_CAR_DETAILS_FIELD_TRANSMISSION = 'HYGIENE_TRADE_IN_ENQUIRY_EDIT_CAR_DETAILS_FIELD_TRANSMISSION',
  HYGIENE_TRADE_IN_ENQUIRY_EDIT_CAR_DETAILS_FIELD_TRANSMISSION_OPTION_AUTOMATIC = 'HYGIENE_TRADE_IN_ENQUIRY_EDIT_CAR_DETAILS_FIELD_TRANSMISSION_OPTION_AUTOMATIC',
  HYGIENE_TRADE_IN_ENQUIRY_EDIT_CAR_DETAILS_FIELD_TRANSMISSION_OPTION_MANUAL = 'HYGIENE_TRADE_IN_ENQUIRY_EDIT_CAR_DETAILS_FIELD_TRANSMISSION_OPTION_MANUAL',
  HYGIENE_TRADE_IN_ENQUIRY_EDIT_CAR_DETAILS_FIELD_VARIANT = 'HYGIENE_TRADE_IN_ENQUIRY_EDIT_CAR_DETAILS_FIELD_VARIANT',
  HYGIENE_TRADE_IN_ENQUIRY_EXTERIOR_CONDITION_FIELD_EXTERIOR_DAMAGE = 'HYGIENE_TRADE_IN_ENQUIRY_EXTERIOR_CONDITION_FIELD_EXTERIOR_DAMAGE',
  HYGIENE_TRADE_IN_ENQUIRY_EXTERIOR_CONDITION_FIELD_EXTERIOR_DAMAGE_OPTION_MAJOR_HAIL = 'HYGIENE_TRADE_IN_ENQUIRY_EXTERIOR_CONDITION_FIELD_EXTERIOR_DAMAGE_OPTION_MAJOR_HAIL',
  HYGIENE_TRADE_IN_ENQUIRY_EXTERIOR_CONDITION_FIELD_EXTERIOR_DAMAGE_OPTION_MAJOR_SCRATCH_DENT = 'HYGIENE_TRADE_IN_ENQUIRY_EXTERIOR_CONDITION_FIELD_EXTERIOR_DAMAGE_OPTION_MAJOR_SCRATCH_DENT',
  HYGIENE_TRADE_IN_ENQUIRY_EXTERIOR_CONDITION_FIELD_EXTERIOR_DAMAGE_OPTION_MINOR_HAIL = 'HYGIENE_TRADE_IN_ENQUIRY_EXTERIOR_CONDITION_FIELD_EXTERIOR_DAMAGE_OPTION_MINOR_HAIL',
  HYGIENE_TRADE_IN_ENQUIRY_EXTERIOR_CONDITION_FIELD_EXTERIOR_DAMAGE_OPTION_MINOR_SCRATCH_DENT = 'HYGIENE_TRADE_IN_ENQUIRY_EXTERIOR_CONDITION_FIELD_EXTERIOR_DAMAGE_OPTION_MINOR_SCRATCH_DENT',
  HYGIENE_TRADE_IN_ENQUIRY_EXTERIOR_CONDITION_FIELD_EXTERIOR_DAMAGE_OPTION_NULL = 'HYGIENE_TRADE_IN_ENQUIRY_EXTERIOR_CONDITION_FIELD_EXTERIOR_DAMAGE_OPTION_NULL',
  HYGIENE_TRADE_IN_ENQUIRY_EXTERIOR_CONDITION_FIELD_EXTERIOR_DAMAGE_OPTION_PAINT = 'HYGIENE_TRADE_IN_ENQUIRY_EXTERIOR_CONDITION_FIELD_EXTERIOR_DAMAGE_OPTION_PAINT',
  HYGIENE_TRADE_IN_ENQUIRY_EXTERIOR_CONDITION_FIELD_EXTERIOR_DAMAGE_OPTION_RUST = 'HYGIENE_TRADE_IN_ENQUIRY_EXTERIOR_CONDITION_FIELD_EXTERIOR_DAMAGE_OPTION_RUST',
  HYGIENE_TRADE_IN_ENQUIRY_EXTERIOR_CONDITION_FIELD_EXTERIOR_DAMAGE_OPTION_WINDSHIELD = 'HYGIENE_TRADE_IN_ENQUIRY_EXTERIOR_CONDITION_FIELD_EXTERIOR_DAMAGE_OPTION_WINDSHIELD',
  HYGIENE_TRADE_IN_ENQUIRY_EXTERIOR_CONDITION_TITLE = 'HYGIENE_TRADE_IN_ENQUIRY_EXTERIOR_CONDITION_TITLE',
  HYGIENE_TRADE_IN_ENQUIRY_FILE_TOO_LARGE = 'HYGIENE_TRADE_IN_ENQUIRY_FILE_TOO_LARGE',
  HYGIENE_TRADE_IN_ENQUIRY_FILE_TOO_SMALL = 'HYGIENE_TRADE_IN_ENQUIRY_FILE_TOO_SMALL',
  HYGIENE_TRADE_IN_ENQUIRY_FINAL_DETAILS_FIELD_CAR_LOCATION = 'HYGIENE_TRADE_IN_ENQUIRY_FINAL_DETAILS_FIELD_CAR_LOCATION',
  HYGIENE_TRADE_IN_ENQUIRY_FINAL_DETAILS_FIELD_EXPECTED_AMOUNT = 'HYGIENE_TRADE_IN_ENQUIRY_FINAL_DETAILS_FIELD_EXPECTED_AMOUNT',
  HYGIENE_TRADE_IN_ENQUIRY_FINAL_DETAILS_FIELD_EXPECTED_AMOUNT_PLACEHOLDER = 'HYGIENE_TRADE_IN_ENQUIRY_FINAL_DETAILS_FIELD_EXPECTED_AMOUNT_PLACEHOLDER',
  HYGIENE_TRADE_IN_ENQUIRY_FINAL_DETAILS_FIELD_EXPECTED_AMOUNT_SUBTITLE = 'HYGIENE_TRADE_IN_ENQUIRY_FINAL_DETAILS_FIELD_EXPECTED_AMOUNT_SUBTITLE',
  HYGIENE_TRADE_IN_ENQUIRY_FINAL_DETAILS_TITLE = 'HYGIENE_TRADE_IN_ENQUIRY_FINAL_DETAILS_TITLE',
  HYGIENE_TRADE_IN_ENQUIRY_INTERIOR_CONDITION_FIELD_INTERIOR_DAMAGE = 'HYGIENE_TRADE_IN_ENQUIRY_INTERIOR_CONDITION_FIELD_INTERIOR_DAMAGE',
  HYGIENE_TRADE_IN_ENQUIRY_INTERIOR_CONDITION_FIELD_INTERIOR_DAMAGE_OPTION_A_V = 'HYGIENE_TRADE_IN_ENQUIRY_INTERIOR_CONDITION_FIELD_INTERIOR_DAMAGE_OPTION_A_V',
  HYGIENE_TRADE_IN_ENQUIRY_INTERIOR_CONDITION_FIELD_INTERIOR_DAMAGE_OPTION_A_V_DESCRIPTION = 'HYGIENE_TRADE_IN_ENQUIRY_INTERIOR_CONDITION_FIELD_INTERIOR_DAMAGE_OPTION_A_V_DESCRIPTION',
  HYGIENE_TRADE_IN_ENQUIRY_INTERIOR_CONDITION_FIELD_INTERIOR_DAMAGE_OPTION_DASHBOARD_PANELS = 'HYGIENE_TRADE_IN_ENQUIRY_INTERIOR_CONDITION_FIELD_INTERIOR_DAMAGE_OPTION_DASHBOARD_PANELS',
  HYGIENE_TRADE_IN_ENQUIRY_INTERIOR_CONDITION_FIELD_INTERIOR_DAMAGE_OPTION_NULL = 'HYGIENE_TRADE_IN_ENQUIRY_INTERIOR_CONDITION_FIELD_INTERIOR_DAMAGE_OPTION_NULL',
  HYGIENE_TRADE_IN_ENQUIRY_INTERIOR_CONDITION_FIELD_INTERIOR_DAMAGE_OPTION_ODOUR = 'HYGIENE_TRADE_IN_ENQUIRY_INTERIOR_CONDITION_FIELD_INTERIOR_DAMAGE_OPTION_ODOUR',
  HYGIENE_TRADE_IN_ENQUIRY_INTERIOR_CONDITION_FIELD_INTERIOR_DAMAGE_OPTION_ODOUR_DESCRIPTION = 'HYGIENE_TRADE_IN_ENQUIRY_INTERIOR_CONDITION_FIELD_INTERIOR_DAMAGE_OPTION_ODOUR_DESCRIPTION',
  HYGIENE_TRADE_IN_ENQUIRY_INTERIOR_CONDITION_FIELD_INTERIOR_DAMAGE_OPTION_RIPPED_SEATS = 'HYGIENE_TRADE_IN_ENQUIRY_INTERIOR_CONDITION_FIELD_INTERIOR_DAMAGE_OPTION_RIPPED_SEATS',
  HYGIENE_TRADE_IN_ENQUIRY_INTERIOR_CONDITION_FIELD_INTERIOR_DAMAGE_OPTION_STAINS = 'HYGIENE_TRADE_IN_ENQUIRY_INTERIOR_CONDITION_FIELD_INTERIOR_DAMAGE_OPTION_STAINS',
  HYGIENE_TRADE_IN_ENQUIRY_INTERIOR_CONDITION_TITLE = 'HYGIENE_TRADE_IN_ENQUIRY_INTERIOR_CONDITION_TITLE',
  HYGIENE_TRADE_IN_ENQUIRY_INVALID_FILE_TYPE = 'HYGIENE_TRADE_IN_ENQUIRY_INVALID_FILE_TYPE',
  HYGIENE_TRADE_IN_ENQUIRY_MECHANICAL_CONDITION_FIELD_MECHANICAL_ISSUES = 'HYGIENE_TRADE_IN_ENQUIRY_MECHANICAL_CONDITION_FIELD_MECHANICAL_ISSUES',
  HYGIENE_TRADE_IN_ENQUIRY_MECHANICAL_CONDITION_FIELD_MECHANICAL_ISSUES_DESCRIPTION = 'HYGIENE_TRADE_IN_ENQUIRY_MECHANICAL_CONDITION_FIELD_MECHANICAL_ISSUES_DESCRIPTION',
  HYGIENE_TRADE_IN_ENQUIRY_MECHANICAL_CONDITION_FIELD_WARNING_LIGHTS = 'HYGIENE_TRADE_IN_ENQUIRY_MECHANICAL_CONDITION_FIELD_WARNING_LIGHTS',
  HYGIENE_TRADE_IN_ENQUIRY_MECHANICAL_CONDITION_FIELD_WARNING_LIGHTS_OPTION_AIRBAG = 'HYGIENE_TRADE_IN_ENQUIRY_MECHANICAL_CONDITION_FIELD_WARNING_LIGHTS_OPTION_AIRBAG',
  HYGIENE_TRADE_IN_ENQUIRY_MECHANICAL_CONDITION_FIELD_WARNING_LIGHTS_OPTION_CHECK_ENGINE = 'HYGIENE_TRADE_IN_ENQUIRY_MECHANICAL_CONDITION_FIELD_WARNING_LIGHTS_OPTION_CHECK_ENGINE',
  HYGIENE_TRADE_IN_ENQUIRY_MECHANICAL_CONDITION_FIELD_WARNING_LIGHTS_OPTION_NULL = 'HYGIENE_TRADE_IN_ENQUIRY_MECHANICAL_CONDITION_FIELD_WARNING_LIGHTS_OPTION_NULL',
  HYGIENE_TRADE_IN_ENQUIRY_MECHANICAL_CONDITION_FIELD_WARNING_LIGHTS_OPTION_OTHER = 'HYGIENE_TRADE_IN_ENQUIRY_MECHANICAL_CONDITION_FIELD_WARNING_LIGHTS_OPTION_OTHER',
  HYGIENE_TRADE_IN_ENQUIRY_MECHANICAL_CONDITION_FIELD_WARNING_LIGHTS_OPTION_TRACTION_ABS = 'HYGIENE_TRADE_IN_ENQUIRY_MECHANICAL_CONDITION_FIELD_WARNING_LIGHTS_OPTION_TRACTION_ABS',
  HYGIENE_TRADE_IN_ENQUIRY_MECHANICAL_CONDITION_FIELD_WARNING_LIGHTS_OPTION_TRANSMISSION = 'HYGIENE_TRADE_IN_ENQUIRY_MECHANICAL_CONDITION_FIELD_WARNING_LIGHTS_OPTION_TRANSMISSION',
  HYGIENE_TRADE_IN_ENQUIRY_MECHANICAL_CONDITION_TITLE = 'HYGIENE_TRADE_IN_ENQUIRY_MECHANICAL_CONDITION_TITLE',
  HYGIENE_TRADE_IN_ENQUIRY_OPTIONAL_EXTRAS_TITLE = 'HYGIENE_TRADE_IN_ENQUIRY_OPTIONAL_EXTRAS_TITLE',
  HYGIENE_TRADE_IN_ENQUIRY_PHOTOS_INFORMATION_ITEM_1 = 'HYGIENE_TRADE_IN_ENQUIRY_PHOTOS_INFORMATION_ITEM_1',
  HYGIENE_TRADE_IN_ENQUIRY_PHOTOS_INFORMATION_ITEM_2 = 'HYGIENE_TRADE_IN_ENQUIRY_PHOTOS_INFORMATION_ITEM_2',
  HYGIENE_TRADE_IN_ENQUIRY_PHOTOS_INFORMATION_ITEM_3 = 'HYGIENE_TRADE_IN_ENQUIRY_PHOTOS_INFORMATION_ITEM_3',
  HYGIENE_TRADE_IN_ENQUIRY_PHOTOS_INFORMATION_NAME = 'HYGIENE_TRADE_IN_ENQUIRY_PHOTOS_INFORMATION_NAME',
  HYGIENE_TRADE_IN_ENQUIRY_PHOTOS_INFORMATION_SUBTITLE = 'HYGIENE_TRADE_IN_ENQUIRY_PHOTOS_INFORMATION_SUBTITLE',
  HYGIENE_TRADE_IN_ENQUIRY_PHOTOS_INFORMATION_TITLE = 'HYGIENE_TRADE_IN_ENQUIRY_PHOTOS_INFORMATION_TITLE',
  HYGIENE_TRADE_IN_ENQUIRY_REMOVE_IMAGE = 'HYGIENE_TRADE_IN_ENQUIRY_REMOVE_IMAGE',
  HYGIENE_TRADE_IN_ENQUIRY_SELL_OR_TRADE_IN = 'HYGIENE_TRADE_IN_ENQUIRY_SELL_OR_TRADE_IN',
  HYGIENE_TRADE_IN_ENQUIRY_SELL_OR_TRADE_IN_FIELD = 'HYGIENE_TRADE_IN_ENQUIRY_SELL_OR_TRADE_IN_FIELD',
  HYGIENE_TRADE_IN_ENQUIRY_SELL_OR_TRADE_IN_FIELD_OPTION_SELL = 'HYGIENE_TRADE_IN_ENQUIRY_SELL_OR_TRADE_IN_FIELD_OPTION_SELL',
  HYGIENE_TRADE_IN_ENQUIRY_SELL_OR_TRADE_IN_FIELD_OPTION_TRADE_IN = 'HYGIENE_TRADE_IN_ENQUIRY_SELL_OR_TRADE_IN_FIELD_OPTION_TRADE_IN',
  HYGIENE_TRADE_IN_ENQUIRY_SELL_OR_TRADE_IN_FIELD_REASON = 'HYGIENE_TRADE_IN_ENQUIRY_SELL_OR_TRADE_IN_FIELD_REASON',
  HYGIENE_TRADE_IN_ENQUIRY_SELL_OR_TRADE_IN_FIELD_REASON_OPTION_BUYING_NEW = 'HYGIENE_TRADE_IN_ENQUIRY_SELL_OR_TRADE_IN_FIELD_REASON_OPTION_BUYING_NEW',
  HYGIENE_TRADE_IN_ENQUIRY_SELL_OR_TRADE_IN_FIELD_REASON_OPTION_BUYING_USED = 'HYGIENE_TRADE_IN_ENQUIRY_SELL_OR_TRADE_IN_FIELD_REASON_OPTION_BUYING_USED',
  HYGIENE_TRADE_IN_ENQUIRY_SELL_OR_TRADE_IN_FIELD_REASON_OPTION_NO_LONGER_NEED = 'HYGIENE_TRADE_IN_ENQUIRY_SELL_OR_TRADE_IN_FIELD_REASON_OPTION_NO_LONGER_NEED',
  HYGIENE_TRADE_IN_ENQUIRY_SELL_OR_TRADE_IN_FIELD_SEARCH_STATUS = 'HYGIENE_TRADE_IN_ENQUIRY_SELL_OR_TRADE_IN_FIELD_SEARCH_STATUS',
  HYGIENE_TRADE_IN_ENQUIRY_SELL_OR_TRADE_IN_FIELD_SEARCH_STATUS_OPTION_ALREADY_FOUND = 'HYGIENE_TRADE_IN_ENQUIRY_SELL_OR_TRADE_IN_FIELD_SEARCH_STATUS_OPTION_ALREADY_FOUND',
  HYGIENE_TRADE_IN_ENQUIRY_SELL_OR_TRADE_IN_FIELD_SEARCH_STATUS_OPTION_NOT_STARTED = 'HYGIENE_TRADE_IN_ENQUIRY_SELL_OR_TRADE_IN_FIELD_SEARCH_STATUS_OPTION_NOT_STARTED',
  HYGIENE_TRADE_IN_ENQUIRY_SELL_OR_TRADE_IN_FIELD_SEARCH_STATUS_OPTION_STILL_LOOKING = 'HYGIENE_TRADE_IN_ENQUIRY_SELL_OR_TRADE_IN_FIELD_SEARCH_STATUS_OPTION_STILL_LOOKING',
  HYGIENE_TRADE_IN_ENQUIRY_SELL_OR_TRADE_IN_FIELD_TIMELINE = 'HYGIENE_TRADE_IN_ENQUIRY_SELL_OR_TRADE_IN_FIELD_TIMELINE',
  HYGIENE_TRADE_IN_ENQUIRY_SELL_OR_TRADE_IN_FIELD_TIMELINE_OPTION_ASAP = 'HYGIENE_TRADE_IN_ENQUIRY_SELL_OR_TRADE_IN_FIELD_TIMELINE_OPTION_ASAP',
  HYGIENE_TRADE_IN_ENQUIRY_SELL_OR_TRADE_IN_FIELD_TIMELINE_OPTION_NEXT_CAR_FOUND = 'HYGIENE_TRADE_IN_ENQUIRY_SELL_OR_TRADE_IN_FIELD_TIMELINE_OPTION_NEXT_CAR_FOUND',
  HYGIENE_TRADE_IN_ENQUIRY_SELL_OR_TRADE_IN_FIELD_TIMELINE_OPTION_NEXT_FEW_MONTHS = 'HYGIENE_TRADE_IN_ENQUIRY_SELL_OR_TRADE_IN_FIELD_TIMELINE_OPTION_NEXT_FEW_MONTHS',
  HYGIENE_TRADE_IN_ENQUIRY_SELL_OR_TRADE_IN_FIELD_TIMELINE_OPTION_NOT_SELLING_YET = 'HYGIENE_TRADE_IN_ENQUIRY_SELL_OR_TRADE_IN_FIELD_TIMELINE_OPTION_NOT_SELLING_YET',
  HYGIENE_TRADE_IN_ENQUIRY_SELL_OR_TRADE_IN_FIELD_TIMELINE_OPTION3_6_WEEKS = 'HYGIENE_TRADE_IN_ENQUIRY_SELL_OR_TRADE_IN_FIELD_TIMELINE_OPTION3_6_WEEKS',
  HYGIENE_TRADE_IN_ENQUIRY_SHOW_CAR_DETAILS_SUBTITLE = 'HYGIENE_TRADE_IN_ENQUIRY_SHOW_CAR_DETAILS_SUBTITLE',
  HYGIENE_TRADE_IN_ENQUIRY_SHOW_CAR_DETAILS_TITLE = 'HYGIENE_TRADE_IN_ENQUIRY_SHOW_CAR_DETAILS_TITLE',
  HYGIENE_TRADE_IN_ENQUIRY_SUCCESS_BUTTON_CLOSE = 'HYGIENE_TRADE_IN_ENQUIRY_SUCCESS_BUTTON_CLOSE',
  HYGIENE_TRADE_IN_ENQUIRY_SUCCESS_BUTTON_CLOSE_PDP_VARIANT = 'HYGIENE_TRADE_IN_ENQUIRY_SUCCESS_BUTTON_CLOSE_PDP_VARIANT',
  HYGIENE_TRADE_IN_ENQUIRY_SUCCESS_DESCRIPTION_HEADER = 'HYGIENE_TRADE_IN_ENQUIRY_SUCCESS_DESCRIPTION_HEADER',
  HYGIENE_TRADE_IN_ENQUIRY_SUCCESS_DESCRIPTION_MEANWHILE = 'HYGIENE_TRADE_IN_ENQUIRY_SUCCESS_DESCRIPTION_MEANWHILE',
  HYGIENE_TRADE_IN_ENQUIRY_SUCCESS_DESCRIPTION_NEXT = 'HYGIENE_TRADE_IN_ENQUIRY_SUCCESS_DESCRIPTION_NEXT',
  HYGIENE_TRADE_IN_ENQUIRY_SUCCESS_DESCRIPTION_OPTIONAL_PDP = 'HYGIENE_TRADE_IN_ENQUIRY_SUCCESS_DESCRIPTION_OPTIONAL_PDP',
  HYGIENE_TRADE_IN_ENQUIRY_TOO_MANY_FILES = 'HYGIENE_TRADE_IN_ENQUIRY_TOO_MANY_FILES',
  HYGIENE_TRADE_IN_ENQUIRY_UPLOAD_IMAGE = 'HYGIENE_TRADE_IN_ENQUIRY_UPLOAD_IMAGE',
  HYGIENE_TRADE_IN_ENQUIRY_USER_DETAILS_FIELD_EMAIL = 'HYGIENE_TRADE_IN_ENQUIRY_USER_DETAILS_FIELD_EMAIL',
  HYGIENE_TRADE_IN_ENQUIRY_USER_DETAILS_FIELD_FIRSTNAME = 'HYGIENE_TRADE_IN_ENQUIRY_USER_DETAILS_FIELD_FIRSTNAME',
  HYGIENE_TRADE_IN_ENQUIRY_USER_DETAILS_FIELD_LASTNAME = 'HYGIENE_TRADE_IN_ENQUIRY_USER_DETAILS_FIELD_LASTNAME',
  HYGIENE_TRADE_IN_ENQUIRY_USER_DETAILS_FIELD_PHONE = 'HYGIENE_TRADE_IN_ENQUIRY_USER_DETAILS_FIELD_PHONE',
  HYGIENE_TRADE_IN_ENQUIRY_USER_DETAILS_SUBTITLE = 'HYGIENE_TRADE_IN_ENQUIRY_USER_DETAILS_SUBTITLE',
  HYGIENE_TRADE_IN_ENQUIRY_USER_DETAILS_TITLE = 'HYGIENE_TRADE_IN_ENQUIRY_USER_DETAILS_TITLE',
  HYGIENE_TRADE_IN_HOW_IT_WORKS_1 = 'HYGIENE_TRADE_IN_HOW_IT_WORKS_1',
  HYGIENE_TRADE_IN_HOW_IT_WORKS_2 = 'HYGIENE_TRADE_IN_HOW_IT_WORKS_2',
  HYGIENE_TRADE_IN_HOW_IT_WORKS_3 = 'HYGIENE_TRADE_IN_HOW_IT_WORKS_3',
  HYGIENE_TRADE_IN_HOW_IT_WORKS_TITLE = 'HYGIENE_TRADE_IN_HOW_IT_WORKS_TITLE',
  HYGIENE_TRADE_IN_TAG_COMPLETE = 'HYGIENE_TRADE_IN_TAG_COMPLETE',
  HYGIENE_TRADE_IN_TAG_OPTIONAL = 'HYGIENE_TRADE_IN_TAG_OPTIONAL',
  HYGIENE_TRADE_IN_TAG_REQUIRED = 'HYGIENE_TRADE_IN_TAG_REQUIRED',
  HYGIENE_TRADE_IN_USPS_CONTENT_1 = 'HYGIENE_TRADE_IN_USPS_CONTENT_1',
  HYGIENE_TRADE_IN_USPS_CONTENT_2 = 'HYGIENE_TRADE_IN_USPS_CONTENT_2',
  HYGIENE_TRADE_IN_USPS_CONTENT_3 = 'HYGIENE_TRADE_IN_USPS_CONTENT_3',
  HYGIENE_TRADE_IN_USPS_TITLE_1 = 'HYGIENE_TRADE_IN_USPS_TITLE_1',
  HYGIENE_TRADE_IN_USPS_TITLE_2 = 'HYGIENE_TRADE_IN_USPS_TITLE_2',
  HYGIENE_TRADE_IN_USPS_TITLE_3 = 'HYGIENE_TRADE_IN_USPS_TITLE_3',
  HYGIENE_WHAT_HAPPENS_NEXT_DESCRIPTION = 'HYGIENE_WHAT_HAPPENS_NEXT_DESCRIPTION',
  HYGIENE_WHAT_HAPPENS_NEXT_TITLE = 'HYGIENE_WHAT_HAPPENS_NEXT_TITLE',
  HYGIENE_WHY_CARMA_CTA = 'HYGIENE_WHY_CARMA_CTA',
}
