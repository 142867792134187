export enum carMatchMicrocopy {
  CARMATCH_START_PAGE = 'CARMATCH_START_PAGE',
  CARMATCH_START_PAGE_CTA = 'CARMATCH_START_PAGE_CTA',
  CARMATCH_RESULTS_HEADER_MULTIPLE_RESULTS = 'CARMATCH_RESULTS_HEADER',
  CARMATCH_RESULTS_HEADER_SINGLE_RESULT = 'CARMATCH_RESULTS_HEADER_SINGLE_RESULT',
  CARMATCH_RESULTS_VIEW_ALL_TITLE = 'CARMATCH_RESULTS_VIEW_ALL_TITLE',
  CARMATCH_RESULTS_VIEW_ALL_DESCRIPTION = 'CARMATCH_RESULTS_VIEW_ALL_DESCRIPTION',
  CARMATCH_RESULTS_VIEW_ALL_CTA = 'CARMATCH_RESULTS_VIEW_ALL_CTA',
  CARMATCH_RESULTS_SEE_AVAILABLE_CTA = 'CARMATCH_RESULTS_SEE_AVAILABLE_CTA',
  CARMATCH_RESULTS_CAROUSEL_TILE_PRICE_DESCRIPTION = 'CARMATCH_RESULTS_CAROUSEL_TILE_PRICE_DESCRIPTION',
}
