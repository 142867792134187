export enum personalizedQuotesMicrocopy {
  PERSONALIZED_QUOTE_LOAN_DETAILS_FORM_HEADER = 'PERSONALIZED_QUOTE_LOAN_DETAILS_FORM_HEADER',
  PERSONALIZED_QUOTE_LOAN_DETAILS_FORM_DESCRIPTION = 'PERSONALIZED_QUOTE_LOAN_DETAILS_DESCRIPTION',
  PERSONALIZED_QUOTE_LOAN_DETAILS_FORM_CTA = 'PERSONALIZED_QUOTE_LOAN_DETAILS_CTA',
  PERSONALIZED_QUOTE_LOAN_DETAILS_FORM_MODAL_TITLE = 'PERSONALIZED_QUOTE_LOAN_DETAILS_FORM_MODAL_TITLE',
  PERSONALIZED_QUOTE_LOAN_DETAILS_FORM_MODAL_DESCRIPTION = 'PERSONALIZED_QUOTE_LOAN_DETAILS_FORM_MODAL_DESCRIPTION',
  PERSONALIZED_QUOTE_EMAIL_FORM_HEADER = 'PERSONALIZED_QUOTE_EMAIL_FORM_HEADER',
  PERSONALIZED_QUOTE_EMAIL_FORM_CTA = 'PERSONALIZED_QUOTE_EMAIL_FORM_CTA',
  PERSONALIZED_QUOTE_EMAIL_FORM_DESCRIPTION = 'PERSONALIZED_QUOTE_EMAIL_FORM_DESCRIPTION',
  PERSONALIZED_QUOTE_PHONE_NUMBER_FORM_HEADER = 'PERSONALIZED_QUOTE_PHONE_NUMBER_FORM_HEADER',
  PERSONALIZED_QUOTE_PHONE_NUMBER_FORM_CTA = 'PERSONALIZED_QUOTE_PHONE_NUMBER_FORM_CTA',
  PERSONALIZED_QUOTE_PHONE_NUMBER_FORM_DESCRIPTION = 'PERSONALIZED_QUOTE_PHONE_NUMBER_FORM_DESCRIPTION',
  PERSONALIZED_QUOTE_LOAN_TYPE_FORM_HEADER = 'PERSONALIZED_QUOTE_LOAN_TYPE_FORM_HEADER',
  PERSONALIZED_QUOTE_LOAN_TYPE_FORM_CTA_LOGGED_IN = 'PERSONALIZED_QUOTE_LOAN_TYPE_FORM_CTA_LOGGED_IN',
  PERSONALIZED_QUOTE_LOAN_TYPE_FORM_CTA = 'PERSONALIZED_QUOTE_LOAN_TYPE_FORM_CTA',
  PERSONALIZED_QUOTE_SIGN_IN_FORM_FORGOT_PASSWORD_CTA = 'PERSONALIZED_QUOTE_SIGN_IN_FORM_FORGOT_PASSWORD_CTA',
  PERSONALIZED_QUOTE_FORGOT_PASSWORD_DESCRIPTION = 'PERSONALIZED_QUOTE_FORGOT_PASSWORD_DESCRIPTION',
  PERSONALIZED_QUOTE_FORGOT_PASSWORD_CTA = 'PERSONALIZED_QUOTE_FORGOT_PASSWORD_CTA',
  PERSONALIZED_QUOTE_RESEND_PASSWORD_CTA = 'PERSONALIZED_QUOTE_RESEND_PASSWORD_CTA',
  PERSONALIZED_QUOTE_PERSONAL_DETAILS_FORM_PERSONAL_HEADER = 'PERSONALIZED_QUOTE_PERSONAL_DETAILS_FORM_PERSONAL_HEADER',
  PERSONALIZED_QUOTE_PERSONAL_DETAILS_FORM_BUSINESS_HEADER = 'PERSONALIZED_QUOTE_PERSONAL_DETAILS_FORM_BUSINESS_HEADER',
  PERSONALIZED_QUOTE_PERSONAL_DETAILS_FORM_CTA = 'PERSONALIZED_QUOTE_PERSONAL_DETAILS_FORM_CTA',
  PERSONALIZED_QUOTE_FAMILY_RESIDENTIAL_FORM_HEADER = 'PERSONALIZED_QUOTE_FAMILY_RESIDENTIAL_FORM_HEADER',
  PERSONALIZED_QUOTE_FAMILY_RESIDENTIAL_FAMILY_STATUS_TITLE = 'PERSONALIZED_QUOTE_FAMILY_RESIDENTIAL_FAMILY_STATUS_TITLE',
  PERSONALIZED_QUOTE_FAMILY_RESIDENTIAL_RESIDENTIAL_ADDRESS_TITLE = 'PERSONALIZED_QUOTE_FAMILY_RESIDENTIAL_RESIDENTIAL_ADDRESS_TITLE',
  PERSONALIZED_QUOTE_FAMILY_RESIDENTIAL_PERSONAL_BUTTON_SUBMIT = 'PERSONALIZED_QUOTE_FAMILY_RESIDENTIAL_PERSONAL_BUTTON_SUBMIT',
  PERSONALIZED_QUOTE_FAMILY_RESIDENTIAL_BUSINESS_BUTTON_SUBMIT = 'PERSONALIZED_QUOTE_FAMILY_RESIDENTIAL_BUSINESS_BUTTON_SUBMIT',
  PERSONALIZED_QUOTE_EMPLOYMENT_CITIZENSHIP_FORM_HEADER = 'PERSONALIZED_QUOTE_EMPLOYMENT_CITIZENSHIP_FORM_HEADER',
  PERSONALIZED_QUOTE_EMPLOYMENT_CITIZENSHIP_FORM_EMPLOYMENT_STATUS_TITLE = 'PERSONALIZED_QUOTE_EMPLOYMENT_CITIZENSHIP_FORM_EMPLOYMENT_STATUS_TITLE',
  PERSONALIZED_QUOTE_EMPLOYMENT_CITIZENSHIP_FORM_RESIDENCY_STATUS_TITLE = 'PERSONALIZED_QUOTE_EMPLOYMENT_CITIZENSHIP_FORM_RESIDENCY_STATUS_TITLE',
  PERSONALIZED_QUOTE_EMPLOYMENT_CITIZENSHIP_FORM_SUBMIT_BUTTON_LABEL = 'PERSONALIZED_QUOTE_EMPLOYMENT_CITIZENSHIP_FORM_SUBMIT_BUTTON_LABEL',
  PERSONALIZED_QUOTE_TERMS_AND_CONDITIONS_CTA_NEW_USER = 'PERSONALIZED_QUOTE_TERMS_AND_CONDITIONS_CTA_NEW_USER',
  PERSONALIZED_QUOTE_TERMS_AND_CONDITIONS_HEADER = 'PERSONALIZED_QUOTE_TERMS_AND_CONDITIONS_HEADER',
  PERSONALIZED_QUOTE_TERMS_AND_CONDITIONS_CTA = 'PERSONALIZED_QUOTE_TERMS_AND_CONDITIONS_CTA',
  PERSONALIZED_QUOTE_TERMS_AND_CONDITIONS_CHECKBOX = 'PERSONALIZED_QUOTE_TERMS_AND_CONDITIONS_CHECKBOX',
  PERSONALIZED_QUOTE_TERMS_AND_CONDITIONS_DESCRIPTION = 'PERSONALIZED_QUOTE_TERMS_AND_CONDITIONS_DESCRIPTION',
  PERSONALIZED_QUOTE_CONFIRMATION_DESCRIPTION = 'PERSONALIZED_QUOTE_CONFIRMATION_DESCRIPTION',
  PERSONALIZED_QUOTE_CONFIRMATION_DESCRIPTION_NO_QUOTES = 'PERSONALIZED_QUOTE_CONFIRMATION_DESCRIPTION_NO_QUOTES',
  PERSONALIZED_QUOTE_QUOTES_LIST_SUBMISSION_BUTTON_LABEL = 'PERSONALIZED_QUOTE_QUOTES_LIST_SUBMISSION_BUTTON_LABEL',
  PERSONALIZED_QUOTE_QUOTES_LIST_SUBMISSION_NEXT_PAGE_BUTTON_LABEL = 'PERSONALIZED_QUOTE_QUOTES_LIST_SUBMISSION_NEXT_PAGE_BUTTON_LABEL',
  PERSONALIZED_QUOTE_QUOTES_LIST_TITLE = 'PERSONALIZED_QUOTE_QUOTES_LIST_TITLE',
  PERSONALIZED_QUOTE_QUOTES_LIST_DESCRIPTION = 'PERSONALIZED_QUOTE_QUOTES_LIST_DESCRIPTION',
  PERSONALIZED_QUOTE_CONFIRMATION_BUTTON_LABEL = 'PERSONALIZED_QUOTE_CONFIRMATION_BUTTON_LABEL',
  PERSONALIZED_QUOTE_RESULT_CARD_TITLE = 'PERSONALIZED_QUOTE_RESULT_CARD_TITLE',
  PERSONALIZED_QUOTE_RESULT_CARD_DESCRIPTION = 'PERSONALIZED_QUOTE_RESULT_CARD_DESCRIPTION',
  PERSONALIZED_QUOTE_SIGNUP_HEADER = 'PERSONALIZED_QUOTE_SIGNUP_HEADER',
  PERSONALIZED_QUOTE_SIGNUP_HEADER_DESCRIPTION = 'PERSONALIZED_QUOTE_SIGNUP_HEADER_DESCRIPTION',
  PERSONALIZED_QUOTE_SIGNUP_CTA = 'PERSONALIZED_QUOTE_SIGNUP_CTA',
  PERSONALIZED_QUOTE_SIGNUP_CHECKBOX = 'PERSONALIZED_QUOTE_SIGNUP_CHECKBOX',
  PERSONALIZED_QUOTE_SIGNUP_TERM_AND_POLICY = 'PERSONALIZED_QUOTE_SIGNUP_TERM_AND_POLICY',
  PERSONALIZED_QUOTE_MY_FINANCE_QUOTES_DESCRIPTION = 'PERSONALIZED_QUOTE_MY_FINANCE_QUOTES_DESCRIPTION',
  PERSONALIZED_QUOTE_MY_FINANCE_QUOTES_HEADER_BUTTON_LABEL = 'PERSONALIZED_QUOTE_MY_FINANCE_QUOTES_HEADER_BUTTON_LABEL',
  PERSONALIZED_QUOTE_MY_FINANCE_QUOTES_BLOCK_1_CONTENT = 'PERSONALIZED_QUOTE_MY_FINANCE_QUOTES_BLOCK_1_CONTENT',
  PERSONALIZED_QUOTE_MY_FINANCE_QUOTES_BLOCK_2_CONTENT = 'PERSONALIZED_QUOTE_MY_FINANCE_QUOTES_BLOCK_2_CONTENT',
  PERSONALIZED_QUOTE_MY_FINANCE_QUOTES_BLOCK_3_CONTENT = 'PERSONALIZED_QUOTE_MY_FINANCE_QUOTES_BLOCK_3_CONTENT',
  PERSONALIZED_QUOTE_MY_FINANCE_QUOTES_INFORMATION = 'PERSONALIZED_QUOTE_MY_FINANCE_QUOTES_INFORMATION',
  PERSONALIZED_QUOTE_FINANCE_CALCULATOR_CTA = 'PERSONALIZED_QUOTE_FINANCE_CALCULATOR_CTA',
  PERSONALIZED_QUOTE_FINANCE_CALCULATOR_CTA_LABEL = 'PERSONALIZED_QUOTE_FINANCE_CALCULATOR_CTA_LABEL',
  PERSONALIZED_QUOTE_NO_FINANCE_QUOTES_TITLE = 'PERSONALIZED_QUOTE_NO_FINANCE_QUOTES_TITLE',
  PERSONALIZED_QUOTE_NO_FINANCE_QUOTES_DESCRIPTION = 'PERSONALIZED_QUOTE_NO_FINANCE_QUOTES_DESCRIPTION',
  PERSONALIZED_QUOTE_NO_FINANCE_QUOTES_CTA = 'PERSONALIZED_QUOTE_NO_FINANCE_QUOTES_CTA',
}
