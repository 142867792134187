import { PageProductListingFilters } from 'src/data/Contentful/ContentfulGetPageProductListing.types';
import { SavedSearch } from 'src/data/SavedSearchApi/SavedSearchApi';
import { FilterListPayload, FiltersPayloadRow } from 'src/types/CarFilters.types';
import { FilterValues } from 'src/types/CataloguePage.types';

const singleValuesData: [FilterValues, KeysMatching<PageProductListingFilters, string>][] = [
  [FilterValues.Keyword, 'search'],
  [FilterValues.CashMin, 'priceCashMin'],
  [FilterValues.CashMax, 'priceCashMax'],
  [FilterValues.FinanceMin, 'priceFinMin'],
  [FilterValues.FinanceMax, 'priceFinMax'],
  [FilterValues.YearMin, 'yearMin'],
  [FilterValues.YearMax, 'yearMax'],
  [FilterValues.KMMin, 'kmMin'],
  [FilterValues.KMMax, 'kmMax'],
  [FilterValues.FuelConsumptionMin, 'fuelConsumptionMin'],
  [FilterValues.FuelConsumptionMax, 'fuelConsumptionMax'],
  [FilterValues.EnginePowerMin, 'enginePowerMin'],
  [FilterValues.EnginePowerMax, 'enginePowerMax'],
  [FilterValues.Co2emissions, 'co2Emissions'],
  [FilterValues.Ancap, 'ancapSafetyRating'],
  [FilterValues.State, 'state'],
  [FilterValues.promotionTags, 'promotionTags'],
];
const singleValuesMap = new Map(singleValuesData);

const arrayValuesData: [FilterValues, KeysMatching<PageProductListingFilters, string[]>][] = [
  [FilterValues.HighlightedFeatures, 'highlightedFeatures'],
  [FilterValues.Transmission, 'transmission'],
  [FilterValues.DriveType, 'driveType'],
  [FilterValues.FuelType, 'fuelType'],
  [FilterValues.BodyType, 'bodyType'],
  [FilterValues.Seats, 'seats'],
  [FilterValues.Doors, 'doors'],
  [FilterValues.Colour, 'colour'],
];
const arrayValuesMap = new Map(arrayValuesData);

export const filtersPayloadRowToPlpStoreFilters = (
  filterData: FilterListPayload,
  filters: FiltersPayloadRow[],
): PageProductListingFilters => {
  const result: PageProductListingFilters = {};

  filters.forEach((item) => {
    if (singleValuesMap.has(item.filter)) {
      // Process the single values
      result[singleValuesMap.get(item.filter)!] = `${item.value}`;
    } else if (arrayValuesMap.has(item.filter)) {
      // Process the array values
      ensureArrayExists(result, arrayValuesMap.get(item.filter)!);
      result[arrayValuesMap.get(item.filter)!]?.push(`${item.value}`);
    } else if (item.filter === FilterValues.Make) {
      // Process the make
      ensureArrayExists(result, 'makeModel');
      handleMake(result, filterData, `${item.value}`);
    } else if (item.filter === FilterValues.Model) {
      // Process the model
      ensureArrayExists(result, 'makeModel');
      handleModel(result, filterData, `${item.value}`);
    } else if (item.filter === FilterValues.BodySize) {
      // Process the body size
      ensureArrayExists(result, 'bodyType');
      handleBodySize(result, filterData, `${item.value}`);
    }
  });
  return result;
};

export const filtersPayloadRowToPlpStoreSimilarTo = (datas: SavedSearch[], isSimilarTo: string) => {
  return datas.find((data) => data.is_similar_to === isSimilarTo);
};

const ensureArrayExists = (
  result: PageProductListingFilters,
  key: KeysMatching<PageProductListingFilters, string[]>,
) => {
  if (!Array.isArray(result[key])) {
    result[key] = [];
  }
};

const handleMake = (result: PageProductListingFilters, filterData: FilterListPayload, value: string) => {
  // Look for the make
  const make = filterData.makeAndModel.find((item) => item.key === value);
  // In case the make is not present in filter data (e.g. no SKU available), use the value
  result.makeModel?.push(make ? make.label.toLowerCase() : value);
};

const handleModel = (result: PageProductListingFilters, filterData: FilterListPayload, value: string) => {
  let makeModel: string | null = null;
  // Loop through all the make to find the model
  filterData.makeAndModel.forEach((itemMake) => {
    const modelFound = itemMake.models?.find((itemModel) => itemModel.key === value);
    if (modelFound) {
      makeModel = `${itemMake.label.toLowerCase()}>${modelFound.label.toLowerCase()}`;
    }
  });

  // In case the model is not present in filter data (e.g. no SKU available), use the value
  result.makeModel?.push(makeModel ?? value);
};

const handleBodySize = (result: PageProductListingFilters, filterData: FilterListPayload, value: string) => {
  let bodySize: string | null = null;
  // Loop through all the make to find the model
  filterData.bodyType.forEach((itemBody) => {
    const sizeFound = itemBody.sizes?.find((itemSize) => itemSize.key === value);
    if (sizeFound) {
      bodySize = `${itemBody.label.toLocaleLowerCase()}>${sizeFound.label}`;
    }
  });

  // In case the body size is not present in filter data (e.g. no SKU available), use the value
  result.bodyType?.push(bodySize ?? value);
};
