'use client';

import { useLayoutEffect } from 'react';
import { create, StoreApi } from 'zustand';
import { createContext } from 'zustand-utils';
import { persist } from 'zustand/middleware';

export const storeFactory = <StoreStateData, StoreStateFunctions, StoreState = StoreStateData & StoreStateFunctions>(
  getDefaultInitialState: () => StoreStateData,
  getStoreFunctions: (
    set: StoreApi<StoreState>['setState'],
    get: StoreApi<StoreState>['getState'],
  ) => StoreStateFunctions,
  persistStoreName?: string,
) => {
  let store: StoreApi<StoreState> | undefined;
  const storeContext = createContext<StoreApi<StoreState>>();

  const initialiseStore = (preloadedState: Partial<StoreStateData>) =>
    create<StoreState>()(
      persistStoreName && typeof window !== 'undefined'
        ? persist(
            (set, get) =>
              ({
                ...getDefaultInitialState(),
                ...preloadedState,
                ...getStoreFunctions(set, get),
              }) as StoreState,
            { name: persistStoreName },
          )
        : (set, get) =>
            ({
              ...getDefaultInitialState(),
              ...preloadedState,
              ...getStoreFunctions(set, get),
            }) as StoreState,
    );

  const useCreateStore = (serverInitialState: Partial<StoreStateData>) => {
    // Server side code: For SSR & SSG, always use a new store.
    if (typeof window === 'undefined') {
      return () => initialiseStore(serverInitialState);
    }
    // End of server side code

    // Client side code:
    // Next.js always re-uses same store regardless of whether page is a SSR or SSG or CSR type.
    const isReusingStore = Boolean(store);
    store = store ?? initialiseStore(serverInitialState);
    // When next.js re-renders _app while re-using an older store, then replace current state with
    // the new state (in the next render cycle).
    // (Why next render cycle? Because react cannot re-render while a render is already in progress.
    // i.e. we cannot do a setState() as that will initiate a re-render)
    //
    // eslint complaining "React Hooks must be called in the exact same order in every component render"
    // is ignorable as this code runs in same order in a given environment (i.e. client or server)
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useLayoutEffect(() => {
      // serverInitialState is undefined for CSR pages. It is up to you if you want to reset
      // states on CSR page navigation or not. I have chosen not to, but if you choose to,
      // then add `serverInitialState = getDefaultInitialState()` here.
      if (serverInitialState && isReusingStore) {
        store!.setState(
          {
            // re-use functions from existing store
            ...store!.getState(),
            // but reset all other properties.
            ...serverInitialState,
          },
          true, // replace states, rather than shallow merging
        );
      }
    });

    return () => store!;
  };

  return {
    StoreProvider: storeContext.Provider,
    store: storeContext.useStore,
    useCreateStore,
  };
};
