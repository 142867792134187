import { carmaClientLegacy } from 'src/services/instance/carmaClientLegacy';
import { FiltersPayloadRow } from 'src/types/CarFilters.types';

export interface SavedSearch {
  id: string;
  name: string;
  filters: FiltersPayloadRow[] | [];
  // eslint-disable-next-line camelcase
  is_similar_to?: string | null;
  subscribed_at: string | null;
}

export const SAVED_SEARCH_DEFAULT_NAME = 'Saved search';

export const getSavedSearches = () =>
  carmaClientLegacy.get<{ searches: SavedSearch[] }>(`me/save-searches`).then((body) => body.data);

export const createSavedSearch = (search: Prettify<Pick<SavedSearch, 'name' | 'filters' | 'is_similar_to'>>) =>
  carmaClientLegacy.put<SavedSearch>(`me/save-searches`, search).then((body) => body.data);

export const updateSavedSearch = (
  id: string,
  search: Prettify<Partial<Pick<SavedSearch, 'name' | 'filters' | 'subscribed_at' | 'is_similar_to'>>>,
) => carmaClientLegacy.patch<SavedSearch>(`me/save-searches/${id}`, search).then((body) => body.data);
