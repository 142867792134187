import { produce } from 'immer';

import { checkSkuSelected, checkTooManySkus, removeSkuFromSkus } from './carComparisonStore.helpers';
import { storeFactory } from './storeFactory';

export interface CarComparisonStoreStateData {
  skus: string[];
}

interface CarComparisonStoreStateFunctions {
  setSkus: (skus: string[]) => Promise<void>;
  addSku: (sku: string) => Promise<void>;
  removeSku: (sku: string) => Promise<void>;
  removeAllSkus: () => Promise<void>;
}

const {
  store: carComparisonStore,
  StoreProvider: CarComparisonStoreProvider,
  useCreateStore: useCreateCarComparisonStore,
} = storeFactory<CarComparisonStoreStateData, CarComparisonStoreStateFunctions>(
  () => ({
    skus: [],
  }),
  (set) => ({
    setSkus: async (skus: string[]) => {
      checkTooManySkus(skus.length);
      set((state) =>
        produce(state, (draft) => {
          draft.skus = skus;
        }),
      );
    },
    addSku: async (sku: string) => {
      set((state) => {
        checkTooManySkus(state.skus.length + 1);
        return produce(state, (draft) => {
          const skusSet = new Set([...state.skus, sku]);
          draft.skus = [...skusSet];
        });
      });
    },
    removeSku: async (sku: string) => {
      set((state) => {
        checkSkuSelected(state.skus, sku);
        return produce(state, (draft) => {
          draft.skus = removeSkuFromSkus(state.skus, sku);
        });
      });
    },
    removeAllSkus: async () => {
      set((state) =>
        produce(state, (draft) => {
          draft.skus = [];
        }),
      );
    },
  }),
  'carComparisonStore',
);

export { carComparisonStore, CarComparisonStoreProvider, useCreateCarComparisonStore };
