'use client';

import dynamic from 'next/dynamic';
import { FC } from 'react';

const Chat = dynamic(() => import('src/general/components/Chat/Chat'), { ssr: false });
const Recaptcha = dynamic(() => import('src/general/components/Recaptcha/Recaptcha'), {
  ssr: false,
});
const ToasterContainer = dynamic(() => import('src/general/components/ToasterContainer/ToasterContainer'), {
  ssr: false,
});

/**
 * Load third party libraries such as LiveChat or Recaptcha
 */
export const ThirdPartyLibraries: FC = () => (
  <>
    <Chat />
    <Recaptcha />
    <ToasterContainer />
  </>
);
