import { FC, useEffect } from 'react';

import { Button } from '../Button/Button';
import { ToasterService } from '../Toaster/ToasterService';
import ToasterContainer from '../ToasterContainer/ToasterContainer';

const ActionButton = () => (
  <Button
    href="/api/exit-preview"
    variant="secondary"
    size="small"
    onClick={() => (window.location.href = '/api/exit-preview')}
  >
    Exit preview
  </Button>
);

export const PreviewSnackbar: FC = () => {
  useEffect(() => {
    ToasterService.warning({
      message: 'Currently in preview mode',
      action: {
        type: 'custom',
        actionComponent: ActionButton,
      },
    });
  }, []);
  return <ToasterContainer />;
};
