export enum pdpMicrocopy {
  PDP_PURCHASE_INPROGRESS_RESERVED_MODAL_TITLE = 'PDP_PURCHASE_INPROGRESS_RESERVED_MODAL_TITLE',
  PDP_PURCHASE_INPROGRESS_DRAFT_ORDER_MODAL_TITLE = 'PDP_PURCHASE_INPROGRESS_DRAFT_ORDER_MODAL_TITLE',
  PDP_PURCHASE_INPROGRESS_RESERVED_MODAL_CONTENT = 'PDP_PURCHASE_INPROGRESS_RESERVED_MODAL_CONTENT',
  PDP_PURCHASE_INPROGRESS_DRAFT_ORDER_MODAL_CONTENT = 'PDP_PURCHASE_INPROGRESS_DRAFT_ORDER_MODAL_CONTENT',
  PDP_PURCHASE_INPROGRESS_MODAL_CONFIRM_BUTTON_LABEL = 'PDP_PURCHASE_INPROGRESS_MODAL_CONFIRM_BUTTON_LABEL',
  PDP_PURCHASE_INPROGRESS_MODAL_ADD_TO_WATCHLIST = 'PDP_PURCHASE_INPROGRESS_MODAL_ADD_TO_WATCHLIST',
  PDP_PURCHASE_INPROGRESS_MODAL_ADDED_TO_WATCHLIST_LABEL = 'PDP_PURCHASE_INPROGRESS_MODAL_ADDED_TO_WATCHLIST_LABEL',
  PDP_SET_LOCATION_FOR_DELIVERY_ADD_LABEL = 'PDP_SET_LOCATION_FOR_DELIVERY_ADD_LABEL',
  PDP_SET_LOCATION_FOR_DELIVERY_EDIT_LABEL = 'PDP_SET_LOCATION_FOR_DELIVERY_EDIT_LABEL',
  PDP_DELIVERY_LOCATION_POPUP_TITLE = 'PDP_DELIVERY_LOCATION_POPUP_TITLE',
  PDP_DELIVERY_LOCATION_POPUP_DESCRIPTION = 'PDP_DELIVERY_LOCATION_POPUP_DESCRIPTION',
  PDP_DELIVERY_LOCATION_POPUP_BUTTON_LABEL = 'PDP_DELIVERY_LOCATION_POPUP_BUTTON_LABEL',
  PDP_DELIVERY_LOCATION_POPUP_SEARCH_LABEL = 'PDP_DELIVERY_LOCATION_POPUP_SEARCH_LABEL',
  PDP_DELIVERY_LOCATION_POPUP_CANCEL_LABEL = 'PDP_DELIVERY_LOCATION_POPUP_CANCEL_LABEL',
  PDP_PRICE_CARD_CONTINUE_CHECKOUT_BUTTON_LABEL = 'PDP_PRICE_CARD_CONTINUE_CHECKOUT_BUTTON_LABEL',
  PDP_PRICE_CARD_BUY_OR_RESERVE_CAR_BUTTON_LABEL = 'PDP_PRICE_CARD_BUY_OR_RESERVE_CAR_BUTTON_LABEL',
  PDP_PRICE_CARD_ON_HOLD_CAR_NOTE = 'PDP_PRICE_CARD_ON_HOLD_CAR_NOTE',
  PDP_PRICE_CARD_ON_HOLD_CAR_LEARN_MORE = 'PDP_PRICE_CARD_ON_HOLD_CAR_LEARN_MORE',
  PDP_PRICE_CARD_CAR_PURCHASED_LABEL = 'PDP_PRICE_CARD_CAR_PURCHASED_LABEL',
  PDP_CAR_SUMMARY_NO_ANCAP_RATING_LABEL = 'PDP_CAR_SUMMARY_NO_ANCAP_RATING_LABEL',
  PDP_CAR_SUMMARY_SERVICE_NOT_REQUIRED_LABEL = 'PDP_CAR_SUMMARY_SERVICE_NOT_REQUIRED_LABEL',
  PDP_CAR_SUMMARY_HEADER = 'PDP_CAR_SUMMARY_HEADER',
  PDP_CAR_SUMMARY_SECTION_1_HEADER = 'PDP_CAR_SUMMARY_SECTION_1_HEADER',
  PDP_CAR_SUMMARY_SECTION_1_TITLE_1 = 'PDP_CAR_SUMMARY_SECTION_1_TITLE_1',
  PDP_CAR_SUMMARY_SECTION_1_TITLE_2 = 'PDP_CAR_SUMMARY_SECTION_1_TITLE_2',
  PDP_CAR_SUMMARY_SECTION_1_TITLE_3 = 'PDP_CAR_SUMMARY_SECTION_1_TITLE_3',
  PDP_CAR_SUMMARY_SECTION_1_TITLE_4 = 'PDP_CAR_SUMMARY_SECTION_1_TITLE_4',
  PDP_CAR_SUMMARY_SECTION_1_TITLE_5 = 'PDP_CAR_SUMMARY_SECTION_1_TITLE_5',
  PDP_CAR_SUMMARY_SECTION_1_TITLE_6 = 'PDP_CAR_SUMMARY_SECTION_1_TITLE_6',
  PDP_CAR_SUMMARY_SECTION_1_TITLE_7 = 'PDP_CAR_SUMMARY_SECTION_1_TITLE_7',
  PDP_CAR_SUMMARY_SECTION_2_HEADER = 'PDP_CAR_SUMMARY_SECTION_2_HEADER',
  PDP_CAR_SUMMARY_SECTION_2_FOOTER = 'PDP_CAR_SUMMARY_SECTION_2_FOOTER',
  PDP_CAR_SUMMARY_SECTION_2_TITLE_1 = 'PDP_CAR_SUMMARY_SECTION_2_TITLE_1',
  PDP_CAR_SUMMARY_SECTION_2_TITLE_2 = 'PDP_CAR_SUMMARY_SECTION_2_TITLE_2',
  PDP_CAR_SUMMARY_SECTION_2_TITLE_3 = 'PDP_CAR_SUMMARY_SECTION_2_TITLE_3',
  PDP_CAR_SUMMARY_SECTION_2_TITLE_4 = 'PDP_CAR_SUMMARY_SECTION_2_TITLE_4',
  PDP_CAR_SUMMARY_SECTION_2_TITLE_5 = 'PDP_CAR_SUMMARY_SECTION_2_TITLE_5',
  PDP_CAR_SUMMARY_SECTION_2_TITLE_6 = 'PDP_CAR_SUMMARY_SECTION_2_TITLE_6',
  PDP_CAR_SUMMARY_SECTION_3_HEADER = 'PDP_CAR_SUMMARY_SECTION_3_HEADER',
  PDP_CAR_SUMMARY_SECTION_3_TITLE_1 = 'PDP_CAR_SUMMARY_SECTION_3_TITLE_1',
  PDP_CAR_SUMMARY_SECTION_4_HEADER = 'PDP_CAR_SUMMARY_SECTION_4_HEADER',
  PDP_CAR_SUMMARY_SECTION_4_TITLE_1 = 'PDP_CAR_SUMMARY_SECTION_4_TITLE_1',
  PDP_CAR_SUMMARY_SECTION_4_TITLE_2 = 'PDP_CAR_SUMMARY_SECTION_4_TITLE_2',
  PDP_CAR_SUMMARY_SECTION_4_TITLE_3 = 'PDP_CAR_SUMMARY_SECTION_4_TITLE_3',
  PDP_CAR_SUMMARY_SECTION_4_TITLE_4 = 'PDP_CAR_SUMMARY_SECTION_4_TITLE_4',
  PDP_CAR_SUMMARY_SECTION_4_TITLE_5 = 'PDP_CAR_SUMMARY_SECTION_4_TITLE_5',
  PDP_CAR_SUMMARY_SECTION_4_TITLE_6 = 'PDP_CAR_SUMMARY_SECTION_4_TITLE_6',
  PDP_CAR_SUMMARY_TOW_CAP_BRAKED = 'PDP_CAR_SUMMARY_TOW_CAPACITY_BRAKED',
  PDP_CAR_SUMMARY_SECTION_5_HEADER = 'PDP_CAR_SUMMARY_SECTION_5_HEADER',
  PDP_CAR_SUMMARY_SECTION_5_TITLE_1 = 'PDP_CAR_SUMMARY_SECTION_5_TITLE_1',
  PDP_CAR_SUMMARY_SECTION_5_TITLE_2 = 'PDP_CAR_SUMMARY_SECTION_5_TITLE_2',
  PDP_CAR_SUMMARY_SECTION_5_TITLE_3 = 'PDP_CAR_SUMMARY_SECTION_5_TITLE_3',
  PDP_CAR_SUMMARY_ITEM_EXTERIOR_HEADER = 'PDP_CAR_SUMMARY_ITEM_EXTERIOR_HEADER',
  PDP_CAR_SUMMARY_ITEM_EXTERIOR_TITLE_1 = 'PDP_CAR_SUMMARY_ITEM_EXTERIOR_TITLE_1',
  PDP_CAR_SUMMARY_ITEM_EXTERIOR_TITLE_2 = 'PDP_CAR_SUMMARY_ITEM_EXTERIOR_TITLE_2',
  PDP_CAR_SUMMARY_ITEM_EXTERIOR_TITLE_3 = 'PDP_CAR_SUMMARY_ITEM_EXTERIOR_TITLE_3',
  PDP_CAR_SUMMARY_ITEM_MECHANICAL_HEADER = 'PDP_CAR_SUMMARY_ITEM_MECHANICAL_HEADER',
  PDP_CAR_SUMMARY_ITEM_MECHANICAL_TITLE_1 = 'PDP_CAR_SUMMARY_ITEM_MECHANICAL_TITLE_1',
  PDP_CAR_SUMMARY_ITEM_MECHANICAL_TITLE_2 = 'PDP_CAR_SUMMARY_ITEM_MECHANICAL_TITLE_2',
  PDP_CAR_SUMMARY_ITEM_MECHANICAL_TITLE_3 = 'PDP_CAR_SUMMARY_ITEM_MECHANICAL_TITLE_3',
  PDP_CAR_SUMMARY_ITEM_FUEL_HEADER = 'PDP_CAR_SUMMARY_ITEM_FUEL_HEADER',
  PDP_CAR_SUMMARY_ITEM_FUEL_TITLE_1 = 'PDP_CAR_SUMMARY_ITEM_FUEL_TITLE_1',
  PDP_CAR_SUMMARY_ITEM_FUEL_TITLE_2 = 'PDP_CAR_SUMMARY_ITEM_FUEL_TITLE_2',
  PDP_CAR_SUMMARY_ITEM_FUEL_TITLE_3 = 'PDP_CAR_SUMMARY_ITEM_FUEL_TITLE_3',
  PDP_CAR_SUMMARY_ITEM_INTERIOR_HEADER = 'PDP_CAR_SUMMARY_ITEM_INTERIOR_HEADER',
  PDP_CAR_SUMMARY_ITEM_INTERIOR_TITLE_1 = 'PDP_CAR_SUMMARY_ITEM_INTERIOR_TITLE_1',
  PDP_CAR_SUMMARY_ITEM_INTERIOR_TITLE_2 = 'PDP_CAR_SUMMARY_ITEM_INTERIOR_TITLE_2',
  PDP_CAR_SUMMARY_ITEM_INTERIOR_TITLE_3 = 'PDP_CAR_SUMMARY_ITEM_INTERIOR_TITLE_3',
  PDP_CAR_SUMMARY_ITEM_SIZES_HEADER = 'PDP_CAR_SUMMARY_ITEM_SIZES_HEADER',
  PDP_CAR_SUMMARY_ITEM_SIZES_TITLE_1 = 'PDP_CAR_SUMMARY_ITEM_SIZES_TITLE_1',
  PDP_CAR_SUMMARY_ITEM_SIZES_TITLE_2 = 'PDP_CAR_SUMMARY_ITEM_SIZES_TITLE_2',
  PDP_CAR_SUMMARY_ITEM_SIZES_TITLE_3 = 'PDP_CAR_SUMMARY_ITEM_SIZES_TITLE_3',
  PDP_CAR_FEATURES_HEADER = 'PDP_CAR_FEATURES_HEADER',
  PDP_CAR_FEATURES_ITEM_1_HEADER = 'PDP_CAR_FEATURES_ITEM_1_HEADER',
  PDP_CAR_FEATURES_ITEM_2_HEADER = 'PDP_CAR_FEATURES_ITEM_2_HEADER',
  PDP_CAR_FEATURES_ITEM_3_HEADER = 'PDP_CAR_FEATURES_ITEM_3_HEADER',
  PDP_CAR_FEATURES_BUTTON_SHOW_ALL_LABEL = 'PDP_CAR_FEATURES_BUTTON_SHOW_ALL_LABEL',
  PDP_PRICE_CARD_FINANCE = 'PDP_PRICE_CARD_FINANCE',
  PDP_PRICE_CARD_FINANCE_SHORT = 'PDP_PRICE_CARD_FINANCE_SHORT',
  PDP_PRICE_CARD_FINANCE_FOOTNOTE = 'PDP_PRICE_CARD_FINANCE_FOOTNOTE',
  PDP_PRICE_CARD_FINANCE_PRICE_PER_WEEK_MOBILE = 'PDP_PRICE_CARD_FINANCE_PRICE_PER_WEEK_MOBILE',
  PDP_PRICE_CARD_VIEW_PRICE_GUIDE = 'PDP_PRICE_CARD_VIEW_PRICE_GUIDE',
  PDP_PRICE_CARD_VIEW_PRICE_TEXT = 'PDP_PRICE_CARD_VIEW_PRICE_TEXT',
  PDP_PRICE_CARD_IN_PROGRESS_MOBILE_LABEL = 'PDP_PRICE_CARD_IN_PROGRESS_MOBILE_LABEL',
  PDP_PRICE_CARD_IN_PROGRESS_TABLET_LABEL = 'PDP_PRICE_CARD_IN_PROGRESS_TABLET_LABEL',
  PDP_PRICE_CARD_USE_FINANCE_DESCRIPTION = 'PDP_PRICE_CARD_USE_FINANCE_DESCRIPTION',
  PDP_PRICE_CARD_USE_FINANCE_HEADING = 'PDP_PRICE_CARD_USE_FINANCE_HEADING',
  PDP_PRICE_CARD_FINANCE_PRICE_PER_WEEK = 'PDP_PRICE_CARD_FINANCE_PRICE_PER_WEEK',
  PDP_PRICE_CARD_USE_FINANCE_REPAYMENT_DESCRIPTION = 'PDP_PRICE_CARD_USE_FINANCE_REPAYMENT_DESCRIPTION',
  PDP_PRICE_CARD_USE_FINANCE_TOOPTIP_HEADLINE = 'PDP_PRICE_CARD_USE_FINANCE_TOOPTIP_HEADLINE',
  PDP_PRICE_CARD_USE_FINANCE_TOOPTIP_DESCRIPTION = 'PDP_PRICE_CARD_USE_FINANCE_TOOPTIP_DESCRIPTION',
  PDP_PRICE_CARD_PAY_IN_FULL_CARD_TITLE = 'PDP_PRICE_CARD_CARD_TITLE',
  PDP_PRICE_CARD_PAY_IN_FULL_CARD_DESCRIPTION = 'PDP_PRICE_CARD_PAY_IN_FULL_CARD_DESCRIPTION',
  PDP_PRICE_CARD_PAY_IN_FULL_GOVT_CHARGES_TEXT = 'PDP_PRICE_CARD_PAY_IN_FULL_GOVT_CHARGES_TEXT',
  PDP_PRICE_CARD_PAY_IN_FULL_LABEL_BUTTON = 'PDP_PRICE_CARD_PAY_IN_FULL_LABEL_BUTTON',
  PDP_PRICE_CARD_PAY_IN_FULL_CARDS_LIST_MASTER_CARD_TEXT = 'PDP_PRICE_CARD_PAY_IN_FULL_CARDS_LIST_MASTER_CARD_TEXT',
  PDP_PRICE_CARD_PAY_IN_FULL_CARDS_LIST_VISA_TEXT = 'PDP_PRICE_CARD_PAY_IN_FULL_CARDS_LIST_VISA_TEXT',
  PDP_PRICE_CARD_PAY_IN_FULL_CARDS_LIST_AMERICAN_EXPRESS_TEXT = 'PDP_PRICE_CARD_PAY_IN_FULL_CARDS_LIST_AMERICAN_EXPRESS_TEXT',
  PDP_PRICE_CARD_CAR_PURCHASED_VIEW_PURCHASE_INFO = 'PDP_PRICE_CARD_CAR_PURCHASED_VIEW_PURCHASE_INFO',
  PDP_PRICE_CARD_CAR_PURCHASED_VIEW_PURCHASE_INFORMATION = 'PDP_PRICE_CARD_CAR_PURCHASED_VIEW_PURCHASE_INFORMATION',
  PDP_IMAGES_TAKE_IT_FOR_A_SPIN = 'PDP_IMAGES_TAKE_IT_FOR_A_SPIN',
  PDP_PRICE_CARD_USP_ITEM_1 = 'PDP_PRICE_CARD_USP_ITEM_1',
  PDP_PRICE_CARD_USP_ITEM_2 = 'PDP_PRICE_CARD_USP_ITEM_2',
  PDP_PRICE_CARD_USP_ITEM_3 = 'PDP_PRICE_CARD_USP_ITEM_3',
  PLP_FILTER_MAKE_MODEL_ALL = 'PLP_FILTER_MAKE_MODEL_ALL',
  PLP_FILTER_MAKE_MODEL_SELECTED = 'PLP_FILTER_MAKE_MODEL_SELECTED',
  PLP_FILTER_MAKE_MODEL_SEARCH_LABEL = 'PLP_FILTER_MAKE_MODEL_SEARCH_LABEL',
  PLP_FILTER_MAKE_MODEL_SHOW_ALL = 'PLP_FILTER_MAKE_MODEL_SHOW_ALL',
  PLP_FILTER_MAKE_MODEL_SHOW_POPULAR = 'PLP_FILTER_MAKE_MODEL_SHOW_POPULAR',
  PDP_GALLERY_MODAL_QUALITY = 'PDP_GALLERY_MODAL_QUALITY',
  PDP_GALLERY_TITLE = 'PDP_GALLERY_TITLE',
  PDP_GALLERY_BUTTON_GALLERY = 'PDP_GALLERY_BUTTON_GALLERY',
  PDP_GALLERY_BUTTON_IMPERFECTION = 'PDP_GALLERY_BUTTON_IMPERFECTION',
  PDP_GALLERY_BUTTON_INTERIOR = 'PDP_GALLERY_BUTTON_INTERIOR',
  PDP_GALLERY_NO_IMPERFECTIONS = 'PDP_GALLERY_NO_IMPERFECTIONS',
  PDP_GALLERY_NO_IMPERFECTIONS_MOBILE = 'PDP_GALLERY_NO_IMPERFECTIONS_MOBILE',
  PDP_GALLERY_BUTTON_EXTERIOR = 'PDP_GALLERY_BUTTON_EXTERIOR',
  PDP_GALLERY_BUTTON_VIEW_360 = 'PDP_GALLERY_VIEW_360',
  PDP_CAR_HERO_BUTTON_OPEN_DOORS = 'PDP_CAR_HERO_OPEN_DOORS',
  PDP_CAR_HERO_BUTTON_CLOSE_DOORS = 'PDP_CAR_HERO_CLOSE_DOORS',
  PDP_IMPERFECTIONS_BUTTON_NEXT = 'PDP_IMPERFECTIONS_NEXT',
  PDP_IMPERFECTIONS_BUTTON_PREVIOUS = 'PDP_IMPERFECTIONS_PREVIOUS',
  PDP_WARRANTY_TITLE = 'PDP_WARRANTY_TITLE',
  PDP_WARRANTY_OTHER_BRANDS_TITLE = 'PDP_WARRANTY_OTHER_BRANDS_TITLE',
  PDP_WARRANTY_INFORMATION = 'PDP_WARRANTY_INFORMATION',
  PDP_WARRANTY_INFORMATION_UNLIMITED_KILOMETRES = 'PDP_WARRANTY_INFORMATION_UNLIMITED_KILOMETRES',
  PDP_WARRANTY_EXTENDED_MANUFACTURER = 'PDP_WARRANTY_EXTENDED_MANUFACTURER',
  PDP_REMAINING_WARRANTY_ELIGIBLE_CONTENT = 'PDP_REMAINING_WARRANTY_ELIGIBLE_CONTENT',
  PDP_ONLY_ELIGIBLE_CONTENT = 'PDP_ONLY_ELIGIBLE_CONTENT',
  PDP_WARRANTY_MODAL_LINK_LABEL = 'PDP_WARRANTY_MODAL_LINK_LABEL',
  PDP_WARRANTY_MODAL_LINK = 'PDP_WARRANTY_MODAL_LINK',
  PDP_CAR_ENQUIRY_CONTENT_BLOCK_TITLE = 'PDP_CAR_ENQUIRY_CONTENT_BLOCK_TITLE',
  PDP_CAR_ENQUIRY_CONTENT_BLOCK_DESCRIPTION = 'PDP_CAR_ENQUIRY_CONTENT_BLOCK_DESCRIPTION',
  PDP_CAR_ENQUIRY_FORM_HEADER = 'PDP_CAR_ENQUIRY_FORM_HEADER',
  PDP_CAR_ENQUIRY_SUCCESS_FORM_HEADER = 'PDP_CAR_ENQUIRY_SUCCESS_FORM_HEADER',
  PDP_CAR_ENQUIRY_FORM_MESSAGE_FIELD_LABEL = 'PDP_CAR_ENQUIRY_FORM_MESSAGE_FIELD_LABEL',
  PDP_CAR_ENQUIRY_FORM_FIRST_NAME_LABEL = 'PDP_CAR_ENQUIRY_FORM_FIRST_NAME_LABEL',
  PDP_CAR_ENQUIRY_FORM_LAST_NAME_LABEL = 'PDP_CAR_ENQUIRY_FORM_LAST_NAME_LABEL',
  PDP_CAR_ENQUIRY_FORM_EMAIL_ADDRESS_LABEL = 'PDP_CAR_ENQUIRY_FORM_EMAIL_ADDRESS_LABEL',
  PDP_CAR_ENQUIRY_FORM_PHONE_NUMBER_LABEL = 'PDP_CAR_ENQUIRY_FORM_PHONE_NUMBER_LABEL',
  PDP_CAR_ENQUIRY_FORM_TRADEIN_INTENT_LABEL_LABEL = 'PDP_CAR_ENQUIRY_FORM_TRADEIN_INTENT_LABEL_LABEL',
  PDP_CAR_ENQUIRY_FORM_IS_FINANCE_INTEREST_LABEL = 'PDP_CAR_ENQUIRY_FORM_IS_FINANCE_INTEREST_LABEL',
  PDP_CAR_ENQUIRY_FORM_SUBMIT_BUTTON_LABEL = 'PDP_CAR_ENQUIRY_FORM_SUBMIT_BUTTON_LABEL',
  PDP_CAR_ENQUIRY_FORM_SUCCESS_MESSAGE = 'PDP_CAR_ENQUIRY_FORM_SUCCESS_MESSAGE',
  PDP_CAR_ENQUIRY_FORM_SUCCESS_MESSAGE_BUTTON_LABEL = 'PDP_CAR_ENQUIRY_FORM_SUCCESS_MESSAGE_BUTTON_LABEL',
  PDP_CAR_ENQUIRY_FORM_DEFAULT_MESSAGE = 'PDP_CAR_ENQUIRY_FORM_DEFAULT_MESSAGE',
  PDP_CAR_ENQUIRY_FORM_ERROR_MESSAGE = 'PDP_CAR_ENQUIRY_FORM_ERROR_MESSAGE',
  PDP_FINANCE_ENQUIRY_FORM_HEADER = 'PDP_FINANCE_ENQUIRY_FORM_HEADER',
  PDP_FINANCE_ENQUIRY_FORM_MESSAGE_FIELD_LABEL = 'PDP_FINANCE_ENQUIRY_FORM_MESSAGE_FIELD_LABEL',
  PDP_FINANCE_ENQUIRY_FORM_DETAIL_SECTION_TITLE = 'PDP_FINANCE_ENQUIRY_FORM_DETAIL_SECTION_TITLE ',
  PDP_FINANCE_ENQUIRY_FORM_FIRST_NAME_LABEL = 'PDP_FINANCE_ENQUIRY_FORM_FIRST_NAME_LABEL',
  PDP_FINANCE_ENQUIRY_FORM_LAST_NAME_LABEL = 'PDP_FINANCE_ENQUIRY_FORM_LAST_NAME_LABEL',
  PDP_FINANCE_ENQUIRY_FORM_EMAIL_ADDRESS_LABEL = 'PDP_FINANCE_ENQUIRY_FORM_EMAIL_ADDRESS_LABEL',
  PDP_FINANCE_ENQUIRY_FORM_PHONE_NUMBER_LABEL = 'PDP_FINANCE_ENQUIRY_FORM_PHONE_NUMBER_LABEL',
  PDP_FINANCE_ENQUIRY_FORM_SUBMIT_BUTTON_LABEL = 'PDP_FINANCE_ENQUIRY_FORM_SUBMIT_BUTTON_LABEL',
  PDP_FINANCE_ENQUIRY_FORM_ERROR_MESSAGE = 'PDP_FINANCE_ENQUIRY_FORM_ERROR_MESSAGE',
  PDP_HEADER_SHARE_BUTTON_LABEL = 'PDP_HEADER_SHARE_BUTTON_LABEL',
  PDP_INSPECTION_LIST_TITLE = 'PDP_INSPECTION_LIST_TITLE',
  PDP_INSPECTION_LIST_DESCRIPTION = 'PDP_INSPECTION_LIST_DESCRIPTION',
  PDP_INSPECTION_LIST_ITEM_1 = 'PDP_INSPECTION_LIST_ITEM_1',
  PDP_INSPECTION_LIST_ITEM_2 = 'PDP_INSPECTION_LIST_ITEM_2',
  PDP_INSPECTION_LIST_ITEM_3 = 'PDP_INSPECTION_LIST_ITEM_3',
  PDP_INSPECTION_LIST_ITEM_4 = 'PDP_INSPECTION_LIST_ITEM_4',
  PDP_INSPECTION_LIST_ITEM_5 = 'PDP_INSPECTION_LIST_ITEM_5',
  PDP_INSPECTION_LIST_ITEM_6 = 'PDP_INSPECTION_LIST_ITEM_6',
  PDP_INSPECTION_LIST_ITEM_7 = 'PDP_INSPECTION_LIST_ITEM_7',
  PDP_INSPECTION_LIST_ITEM_8 = 'PDP_INSPECTION_LIST_ITEM_8',
  PDP_INSPECTION_LIST_CTA = 'PDP_INSPECTION_LIST_CTA',
  PDP_GET_STARTED_TITLE = 'PDP_GET_STARTED_TITLE',
  PDP_GET_STARTED_DESCRIPTION = 'PDP_GET_STARTED_DESCRIPTION',
  PDP_GET_STARTED_ITEM_1 = 'PDP_GET_STARTED_ITEM_1',
  PDP_GET_STARTED_ITEM_2 = 'PDP_GET_STARTED_ITEM_2',
  PDP_GET_STARTED_ITEM_3 = 'PDP_GET_STARTED_ITEM_3',
  PDP_CAR_OVERVIEW_TITLE = 'PDP_CAR_OVERVIEW_TITLE',
  PDP_KEY_INFORMATION_TITLE = 'PDP_KEY_INFORMATION_TITLE',
  PDP_KEY_INFORMATION_KILOMETERS = 'PDP_KEY_INFORMATION_KILOMETERS',
  PDP_KEY_INFORMATION_COMPLIANCE_YEAR = 'PDP_KEY_INFORMATION_COMPLIANCE_YEAR',
  PDP_KEY_INFORMATION_BUILD_YEAR = 'PDP_KEY_INFORMATION_BUILD_YEAR',
  PDP_KEY_INFORMATION_ENGINE = 'PDP_KEY_INFORMATION_ENGINE',
  PDP_KEY_INFORMATION_FUEL_TYPE = 'PDP_KEY_INFORMATION_FUEL_TYPE',
  PDP_KEY_INFORMATION_TRANSMISSION = 'PDP_KEY_INFORMATION_TRANSMISSION',
  PDP_KEY_INFORMATION_SEATS = 'PDP_KEY_INFORMATION_SEATS',
  PDP_KEY_INFORMATION_LAST_SERVICE_DATE = 'PDP_KEY_INFORMATION_LAST_SERVICE_DATE',
  PDP_KEY_INFORMATION_VIN_NUMBER = 'PDP_KEY_INFORMATION_VIN_NUMBER',
  PDP_CART_SWITCH_TITLE = 'PDP_CART_SWITCH_TITLE',
  PDP_CART_SWITCH_DESCRIPTION = 'PDP_CART_SWITCH_DESCRIPTION',
  PDP_CART_SWITCH_NEW_PURCHASE = 'PDP_CART_SWITCH_NEW_PURCHASE',
  PDP_CART_SWITCH_EXISTING_PURCHASE = 'PDP_CART_SWITCH_EXISTING_PURCHASE',
  PDP_SIMILAR_CARS_TITLE = 'PDP_SIMILAR_CARS_TITLE',
  PDP_CARMA_CORE_VALUE_PROPS_TITLE_1 = 'PDP_CARMA_CORE_VALUE_PROPS_TITLE_1',
  PDP_CARMA_CORE_VALUE_PROPS_TITLE_2 = 'PDP_CARMA_CORE_VALUE_PROPS_TITLE_2',
  PDP_CARMA_CORE_VALUE_PROPS_TITLE_3 = 'PDP_CARMA_CORE_VALUE_PROPS_TITLE_3',
  PDP_CARMA_CORE_VALUE_PROPS_TITLE_4 = 'PDP_CARMA_CORE_VALUE_PROPS_TITLE_4',
  PDP_CAR_OVERVIEW_REGO = 'PDP_CAR_OVERVIEW_REGO',
  PDP_CAR_OVERVIEW_LOW_KM_YEAR = 'PDP_CAR_OVERVIEW_LOW_KM_YEAR',
  PDP_CAR_OVERVIEW_LOW_KM = 'PDP_CAR_OVERVIEW_LOW_KM',
  PDP_CAR_OVERVIEW_LAST_SERVICED = 'PDP_CAR_OVERVIEW_LAST_SERVICED',
  PDP_CAR_OVERVIEW_SINGLE_OWNER = 'PDP_CAR_OVERVIEW_SINGLE_OWNER',
  PDP_CAR_OVERVIEW_TWO_NEW_TYRES = 'PDP_CAR_OVERVIEW_TWO_NEW_TYRES',
  PDP_CAR_OVERVIEW_FOUR_NEW_TYRES = 'PDP_CAR_OVERVIEW_FOUR_NEW_TYRES',
  PDP_CARMA_COLLECT_LOCATIONS_TITLE = 'PDP_CARMA_COLLECT_LOCATIONS_TITLE',
  PDP_CARMA_COLLECT_LOCATIONS_RADIOS_TITLE = 'PDP_CARMA_COLLECT_LOCATIONS_RADIOS_TITLE',
  PDP_CAR_OVERVIEW_FULL_SPARE_TYRE = 'PDP_CAR_OVERVIEW_FULL_SPARE_TYRE',
  PDP_SPECS_AND_FEATURES_HEADER = 'PDP_SPECS_AND_FEATURES_HEADER',
  PDP_SPECS_AND_FEATURES_CTA = 'PDP_SPECS_AND_FEATURES_CTA',
  PDP_FEATURES_HEADER = 'PDP_FEATURES_HEADER',
  PDP_SPECS_HEADER = 'PDP_SPECS_HEADER',
  PDP_FEATURES_CTA = 'PDP_FEATURES_CTA',
  PDP_SPECS_CTA = 'PDP_SPECS_CTA',
  PDP_SERVICE_HISTORY_HEADER = 'PDP_SERVICE_HISTORY_HEADER',
  PDP_SERVICE_HISTORY_SECTION_HEADER = 'PDP_SERVICE_HISTORY_SECTION_HEADER',
  PDP_SERVICE_HISTORY_ONE_SERVICE_HEADER = 'PDP_SERVICE_HISTORY_ONE_SERVICE_HEADER',
  PDP_SERVICE_HISTORY_LIKE_NEW_HEADER = 'PDP_SERVICE_HISTORY_LIKE_NEW_HEADER',
  PDP_SERVICE_HISTORY_BUTTON_SHOW_LABEL = 'PDP_SERVICE_HISTORY_BUTTON_SHOW_LABEL',
  PDP_SERVICE_HISTORY_BUTTON_HIDE_LABEL = 'PDP_SERVICE_HISTORY_BUTTON_HIDE_LABEL',
  PDP_SERVICE_HISTORY_NO_SERVICE_DATE_MESSAGE = 'PDP_SERVICE_HISTORY_NO_SERVICE_DATE_MESSAGE',
  PDP_SERVICE_HISTORY_NO_KILOMETRE_MESSAGE = 'PDP_SERVICE_HISTORY_NO_KILOMETRE_MESSAGE',
  PDP_TRADE_IN_HEADER = 'PDP_TRADE_IN_HEADER',
  PDP_TRADE_IN_DESCRIPTION = 'PDP_TRADE_IN_DESCRIPTION',
  PDP_TRADE_IN_BUTTON_SUBMIT_LABEL = 'PDP_TRADE_IN_BUTTON_SUBMIT_LABEL',
}
