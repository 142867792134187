export enum generalMicrocopy {
  GENERAL_FOOTER_SUPPORT_TITLE = 'GENERAL_FOOTER_SUPPORT_TITLE',
  GENERAL_FOOTER_SUPPORT_CONTENT = 'GENERAL_FOOTER_SUPPORT_CONTENT',
  GENERAL_FOOTER_SUPPORT_EMAIL_BUTTON_LABEL = 'GENERAL_FOOTER_SUPPORT_EMAIL_BUTTON_LABEL',
  GENERAL_FOOTER_SUPPORT_EMAIL_BUTTON_VALUE = 'GENERAL_FOOTER_SUPPORT_EMAIL_BUTTON_VALUE',
  GENERAL_FOOTER_SUPPORT_PHONE_BUTTON_LABEL = 'GENERAL_FOOTER_SUPPORT_PHONE_BUTTON_LABEL',
  GENERAL_FOOTER_SUPPORT_PHONE_BUTTON_VALUE = 'GENERAL_FOOTER_SUPPORT_PHONE_BUTTON_VALUE',
  GENERAL_FOOTER_SUPPORT_MESSAGE_BUTTON_LABEL = 'GENERAL_FOOTER_SUPPORT_MESSAGE_BUTTON_LABEL',
  GENERAL_FOOTER_SUPPORT_MESSAGE_BUTTON_VALUE = 'GENERAL_FOOTER_SUPPORT_MESSAGE_BUTTON_VALUE',
  GENERAL_FOOTER_COPYRIGHT = 'GENERAL_FOOTER_COPYRIGHT',
  GENERAL_FOOTER_FORM_LEGALS = 'GENERAL_FOOTER_FORM_LEGALS',
  GENERAL_MODAL_HELP_TITLE = 'GENERAL_MODAL_HELP_TITLE',
  GENERAL_MODAL_HELP_CONTENT = 'GENERAL_MODAL_HELP_CONTENT',
  GENERAL_COPY_TO_CLIPBOARD_COPIED = 'GENERAL_COPY_TO_CLIPBOARD_COPIED',
  GENERAL_CTA_START_CHECKOUT = 'GENERAL_CTA_START_CHECKOUT',
  GENERAL_CTA_NOT_AVAILABLE = 'GENERAL_CTA_NOT_AVAILABLE',
  GENERAL_PAYMENT_ERROR = 'GENERAL_PAYMENT_ERROR',
  GENERAL_CTA_MESSAGE = 'GENERAL_CTA_MESSAGE',
  GENERAL_PRIVACY_COLLECTION_NOTICE = 'GENERAL_PRIVACY_COLLECTION_NOTICE',
  GENERAL_EXCLUDE_GOVERNMENT_CHARGES = 'GENERAL_EXCLUDE_GOVERNMENT_CHARGES',
  GENERAL_GOVERNMENT_CHARGES_MODAL_TITLE = 'GENERAL_GOVERNMENT_CHARGES_MODAL_TITLE',
  GENERAL_GOVERNMENT_CHARGES_MODAL_NOTE = 'GENERAL_GOVERNMENT_CHARGES_MODAL_NOTE',
  GENERAL_ERROR_500_TITLE = 'GENERAL_ERROR_500_TITLE',
  GENERAL_ERROR_500_BUTTON_LABEL = 'GENERAL_ERROR_500_BUTTON_LABEL',
  GENERAL_ERROR_400_TITLE = 'GENERAL_ERROR_400_TITLE',
  GENERAL_ERROR_400_BUTTON_LABEL = 'GENERAL_ERROR_400_BUTTON_LABEL',
  GENERAL_ERROR_PROCESS_PAYMENT_BUTTON_LABEL = 'GENERAL_ERROR_PROCESS_PAYMENT_BUTTON_LABEL',
  GENERAL_ERROR_PROCESS_PAYMENT_TITLE = 'GENERAL_ERROR_PROCESS_PAYMENT_TITLE',
  GENERAL_ERROR_PROCESS_PAYMENT_MESSAGE = 'GENERAL_ERROR_PROCESS_PAYMENT_MESSAGE',
  GENERAL_HEADER_PURCHASE_PROGRESS_BUTTON = 'GENERAL_HEADER_PURCHASE_PROGRESS_BUTTON',
  GENERAL_HEADER_TIME_PURCHASE_UNAVAILABLE = 'GENERAL_HEADER_TIME_PURCHASE_UNAVAILABLE',
  GENERAL_HEADER_TIME_REMAINING = 'GENERAL_HEADER_TIME_REMAINING',
  GENERAL_HEADER_BUTTON_SIGN_OUT = 'GENERAL_HEADER_BUTTON_SIGN_OUT',
  GENERAL_HEADER_SEARCH_PLACEHOLDER = 'GENERAL_HEADER_SEARCH_PLACEHOLDER',
  GENERAL_HEADER_SEARCH_PLACEHOLDER_MOBILE = 'GENERAL_HEADER_SEARCH_PLACEHOLDER_MOBILE',
  GENERAL_HEADER_SEARCH_DROPDOWN_VIEW_ALL_CTA = 'GENERAL_HEADER_SEARCH_DROPDOWN_VIEW_ALL_CTA',
  GENERAL_HEADER_SEARCH_DROPDOWN_VIEW_RESULT_CTA = 'GENERAL_HEADER_SEARCH_DROPDOWN_VIEW_RESULT_CTA',
  GENERAL_HEADER_SEARCH_DROPDOWN_CAR_SUGGESTIONS_LABEL = 'GENERAL_HEADER_SEARCH_DROPDOWN_CAR_SUGGESTIONS_LABEL',
  GENERAL_HEADER_SEARCH_DROPDOWN_CAR_JUST_ADDED_LABEL = 'GENERAL_HEADER_SEARCH_DROPDOWN_CAR_JUST_ADDED_LABEL',
  GENERAL_HEADER_SEARCH_DROPDOWN_FILTER_SUGGESTIONS_LABEL = 'GENERAL_HEADER_SEARCH_DROPDOWN_FILTER_SUGGESTIONS_LABEL',
  GENERAL_HEADER_SEARCH_DROPDOWN_RECENT_SEARCH_LABEL = 'GENERAL_HEADER_SEARCH_DROPDOWN_RECENT_SEARCH_LABEL',
  GENERAL_HEADER_LOGIN_BUTTON_LABEL = 'GENERAL_HEADER_LOGIN_BUTTON_LABEL',
}
