import { CAR_COMPARISON_MAX } from 'src/constants';
import { CarComparisonStoreError } from './carComparisonStore.types';

/**
 * Check to ensure not more than the maximum of SKUs have been added
 * @throws CarComparisonStoreError
 */
export const checkTooManySkus = (skusCount: number) => {
  if (skusCount > CAR_COMPARISON_MAX) {
    throw new CarComparisonStoreError({
      name: 'TOO_MANY_SKUS',
      message: `You've already selected ${CAR_COMPARISON_MAX} cars to compare.`,
    });
  }
};

/**
 * Check to ensure a specific SKU is selected
 * @throws CarComparisonStoreError
 */
export const checkSkuSelected = (skus: string[], sku: string) => {
  if (skus.indexOf(sku) < 0) {
    throw new CarComparisonStoreError({
      name: 'SKU_NOT_SELECTED',
      message: `This car is not in your cars to compare selection.`,
    });
  }
};

export const removeSkuFromSkus = (skus: string[], sku: string) => {
  return skus.filter((value) => value !== sku);
};
