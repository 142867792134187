export enum checkoutMicrocopy {
  CHECKOUT_EXPIRED_MODAL_TITLE = 'CHECKOUT_EXPIRED_MODAL_TITLE',
  CHECKOUT_EXPIRED_MODAL_DESCRIPTION = 'CHECKOUT_EXPIRED_MODAL_DESCRIPTION',
  CHECKOUT_EXPIRED_MODAL_BUTTON_1_LABEL = 'CHECKOUT_EXPIRED_MODAL_BUTTON_1_LABEL',
  CHECKOUT_EXPIRED_MODAL_BUTTON_2_LABEL = 'CHECKOUT_EXPIRED_MODAL_BUTTON_2_LABEL',
  CHECKOUT_TRADE_IN_HELP = 'CHECKOUT_TRADE_IN_HELP',
  CHECKOUT_FINANCE_HAS_TRADE_IN_CARMA = 'CHECKOUT_FINANCE_HAS_TRADE_IN_CARMA',
  CHECKOUT_FINANCE_HAS_TRADE_IN_PAY_WITH_FUNDS = 'CHECKOUT_FINANCE_HAS_TRADE_IN_PAY_WITH_FUNDS',
  CHECKOUT_TRADE_IN_TITLE = 'CHECKOUT_TRADE_IN_TITLE',
  CHECKOUT_TRADE_IN_HEADER = 'CHECKOUT_TRADE_IN_HEADER',
  CHECKOUT_TRADE_IN_CONTENT = 'CHECKOUT_TRADE_IN_CONTENT',
  CHECKOUT_TRADE_IN_BANNER_ITEM_1 = 'CHECKOUT_TRADE_IN_BANNER_ITEM_1',
  CHECKOUT_TRADE_IN_BANNER_ITEM_2 = 'CHECKOUT_TRADE_IN_BANNER_ITEM_2',
  CHECKOUT_TRADE_IN_RADIO_LABEL = 'CHECKOUT_TRADE_IN_RADIO_LABEL',
  CHECKOUT_TRADE_IN_RADIO_ITEM_1_LABEL = 'CHECKOUT_TRADE_IN_RADIO_ITEM_1_LABEL',
  CHECKOUT_TRADE_IN_RADIO_ITEM_2_LABEL = 'CHECKOUT_TRADE_IN_RADIO_ITEM_2_LABEL',
  CHECKOUT_TRADE_IN_BUTTON_LABEL = 'CHECKOUT_TRADE_IN_BUTTON_LABEL',
  CHECKOUT_TRADE_IN_DESCRIPTION = 'CHECKOUT_TRADE_IN_DESCRIPTION',
  CHECKOUT_STEP_1_LABEL_CONTENT = 'CHECKOUT_STEP_1_LABEL_CONTENT',
  CHECKOUT_STEP_1_CAPTION_CONTENT = 'CHECKOUT_STEP_1_CAPTION_CONTENT',
  CHECKOUT_STEP_2_LABEL_CONTENT = 'CHECKOUT_STEP_2_LABEL_CONTENT',
  CHECKOUT_STEP_2_CAPTION_CONTENT = 'CHECKOUT_STEP_2_CAPTION_CONTENT',
  CHECKOUT_STEP_3_LABEL_CONTENT = 'CHECKOUT_STEP_3_LABEL_CONTENT',
  CHECKOUT_STEP_3_CAPTION_CONTENT = 'CHECKOUT_STEP_3_CAPTION_CONTENT',
  CHECKOUT_STEP_4_LABEL_CONTENT = 'CHECKOUT_STEP_4_LABEL_CONTENT',
  CHECKOUT_STEP_4_CAPTION_CONTENT = 'CHECKOUT_STEP_4_CAPTION_CONTENT',
  CHECKOUT_STEP_5_LABEL_CONTENT = 'CHECKOUT_STEP_5_LABEL_CONTENT',
  CHECKOUT_STEP_5_CAPTION_CONTENT = 'CHECKOUT_STEP_5_CAPTION_CONTENT',
  CHECKOUT_STEP_6_LABEL_CONTENT = 'CHECKOUT_STEP_6_LABEL_CONTENT',
  CHECKOUT_STEP_6_CAPTION_CONTENT = 'CHECKOUT_STEP_6_CAPTION_CONTENT',
  CHECKOUT_CONFIRMATION_TRADE_IN_CARD = 'CHECKOUT_CONFIRMATION_TRADE_IN_CARD',
  CHECKOUT_CONFIRMATION_DELIVERY_DOCUMENT_TITLE = 'CHECKOUT_CONFIRMATION_DELIVERY_DOCUMENT_TITLE',
  CHECKOUT_CONFIRMATION_DELIVERY_DOCUMENT_LIST = 'CHECKOUT_CONFIRMATION_DELIVERY_DOCUMENT_LIST',
  CHECKOUT_CONFIRMATION_DELIVERY_DOCUMENT_DESCRIPTION = 'CHECKOUT_CONFIRMATION_DELIVERY_DOCUMENT_DESCRIPTION',
  CHECKOUT_CONFIRMATION_PAID_FULL_TITLE = 'CHECKOUT_CONFIRMATION_PAID_FULL_TITLE',
  CHECKOUT_CONFIRMATION_RESERVATION_TITLE = 'CHECKOUT_CONFIRMATION_RESERVATION_TITLE',
  CHECKOUT_CONFIRMATION_PAID_FULL_DESCRIPTION = 'CHECKOUT_CONFIRMATION_PAID_FULL_DESCRIPTION',
  CHECKOUT_CONFIRMATION_RESERVATION_DESCRIPTION_1 = 'CHECKOUT_CONFIRMATION_RESERVATION_DESCRIPTION_1',
  CHECKOUT_CONFIRMATION_RESERVATION_DESCRIPTION_2 = 'CHECKOUT_CONFIRMATION_RESERVATION_DESCRIPTION_2',
  CHECKOUT_CONFIRMATION_RESERVATION_DESCRIPTION_3 = 'CHECKOUT_CONFIRMATION_RESERVATION_DESCRIPTION_3',
  CHECKOUT_CONFIRMATION_RESERVATION_DESCRIPTION_4 = 'CHECKOUT_CONFIRMATION_RESERVATION_DESCRIPTION_4',
  CHECKOUT_CONFIRMATION_DELIVERY_TIME_DESCRIPTION = 'CHECKOUT_CONFIRMATION_DELIVERY_TIME_DESCRIPTION',
  CHECKOUT_CONFIRMATION_COLLECTION_TIME_DESCRIPTION = 'CHECKOUT_CONFIRMATION_COLLECTION_TIME_DESCRIPTION',
  CHECKOUT_CONFIRMATION_DELIVERY_ADDRESS_DESCRIPTION = 'CHECKOUT_CONFIRMATION_DELIVERY_ADDRESS_DESCRIPTION',
  CHECKOUT_CONFIRMATION_COLLECTION_DESCRIPTION = 'CHECKOUT_CONFIRMATION_COLLECTION_DESCRIPTION',
  CHECKOUT_CONFIRMATION_NEXT_ACTION_OWN_FUNDS_DELIVERY = 'CHECKOUT_CONFIRMATION_NEXT_ACTION_OWN_FUNDS_DELIVERY',
  CHECKOUT_CONFIRMATION_NEXT_ACTION_OWN_FUNDS_COLLECTION = 'CHECKOUT_CONFIRMATION_NEXT_ACTION_OWN_FUNDS_COLLECTION',
  CHECKOUT_CONFIRMATION_NEXT_ACTION_OWN_FUNDS_TRADE_IN_DELIVERY = 'CHECKOUT_CONFIRMATION_NEXT_ACTION_OWN_FUNDS_TRADE_IN_DELIVERY',
  CHECKOUT_CONFIRMATION_NEXT_ACTION_OWN_FUNDS_TRADE_IN_COLLECTION = 'CHECKOUT_CONFIRMATION_NEXT_ACTION_OWN_FUNDS_TRADE_IN_COLLECTION',
  CHECKOUT_CONFIRMATION_NEXT_ACTION_CARMA_FINANCE_DELIVERY = 'CHECKOUT_CONFIRMATION_NEXT_ACTION_CARMA_FINANCE_DELIVERY',
  CHECKOUT_CONFIRMATION_NEXT_ACTION_CARMA_FINANCE_COLLECTION = 'CHECKOUT_CONFIRMATION_NEXT_ACTION_CARMA_FINANCE_COLLECTION',
  CHECKOUT_CONFIRMATION_NEXT_ACTION_CARMA_FINANCE_TRADE_IN_DELIVERY = 'CHECKOUT_CONFIRMATION_NEXT_ACTION_CARMA_FINANCE_TRADE_IN_DELIVERY',
  CHECKOUT_CONFIRMATION_NEXT_ACTION_CARMA_FINANCE_TRADE_IN_COLLECTION = 'CHECKOUT_CONFIRMATION_NEXT_ACTION_CARMA_FINANCE_TRADE_IN_COLLECTION',
  CHECKOUT_CONFIRMATION_DELIVERY_NOTICE = 'CHECKOUT_CONFIRMATION_DELIVERY_CONTACT_NOTICE',
  CHECKOUT_CONFIRMATION_COLLECTION_NOTICE = 'CHECKOUT_CONFIRMATION_COLLECTION_NOTICE',
  CHECKOUT_CONFIRMATION_CARMA_DETAILS = 'CHECKOUT_CONFIRMATION_CARMA_DETAILS',
  CHECKOUT_CONFIRMATION_BYOF_NEXT_STEP_TITLE = 'CHECKOUT_CONFIRMATION_BYOF_NEXT_STEP_TITLE',
  CHECKOUT_CONFIRMATION_BYOF_NEXT_STEP_ITEM_1 = 'CHECKOUT_CONFIRMATION_BYOF_NEXT_STEP_ITEM_1',
  CHECKOUT_CONFIRMATION_BYOF_NEXT_STEP_ITEM_2 = 'CHECKOUT_CONFIRMATION_BYOF_NEXT_STEP_ITEM_2',
  CHECKOUT_CONFIRMATION_BYOF_NEXT_STEP_ITEM_3 = 'CHECKOUT_CONFIRMATION_BYOF_NEXT_STEP_ITEM_3',
  CHECKOUT_CONFIRMATION_BYOF_NEXT_STEP_ITEM_4 = 'CHECKOUT_CONFIRMATION_BYOF_NEXT_STEP_ITEM_4',
  CHECKOUT_CONFIRMATION_BYOF_TRADE_IN_NEXT_STEP_ITEM_1 = 'CHECKOUT_CONFIRMATION_BYOF_TRADE_IN_NEXT_STEP_ITEM_1',
  CHECKOUT_CONFIRMATION_BYOF_TRADE_IN_NEXT_STEP_ITEM_2 = 'CHECKOUT_CONFIRMATION_BYOF_TRADE_IN_NEXT_STEP_ITEM_2',
  CHECKOUT_CONFIRMATION_BYOF_TRADE_IN_NEXT_STEP_ITEM_3 = 'CHECKOUT_CONFIRMATION_BYOF_TRADE_IN_NEXT_STEP_ITEM_3',
  CHECKOUT_CONFIRMATION_BYOF_TRADE_IN_NEXT_STEP_ITEM_4 = 'CHECKOUT_CONFIRMATION_BYOF_TRADE_IN_NEXT_STEP_ITEM_4',
  CHECKOUT_CONFIRMATION_BYOF_TRADE_IN_NEXT_STEP_ITEM_5 = 'CHECKOUT_CONFIRMATION_BYOF_TRADE_IN_NEXT_STEP_ITEM_5',
  CHECKOUT_CONFIRMATION_BYOF_TRADE_IN_NEXT_STEP_ITEM_6 = 'CHECKOUT_CONFIRMATION_BYOF_TRADE_IN_NEXT_STEP_ITEM_6',
  CHECKOUT_CONFIRMATION_CTA = 'CHECKOUT_CONFIRMATION_CTA',
  CHECKOUT_DESCRIPTION_TERM_POLICY_DESCRIPTION = 'CHECKOUT_DESCRIPTION_TERM_POLICY_DESCRIPTION',
  CHECKOUT_STEP4_PERSONAL_RATE_ERROR_NO_ALLICATION_TYPE = 'CHECKOUT_STEP4_PERSONAL_RATE_ERROR_NO_ALLICATION_TYPE',
  CHECKOUT_STEP4_PERSONAL_RATE_ERROR_NO_RESIDENTIAL_STATUS = 'CHECKOUT_STEP4_PERSONAL_RATE_ERROR_NO_RESIDENTIAL_STATUS',
  CHECKOUT_STEP4_PERSONAL_RATE_ERROR_NO_FINANCE_DEPOSIT_AMOUNT = 'CHECKOUT_STEP4_PERSONAL_RATE_ERROR_NO_FINANCE_DEPOSIT_AMOUNT',
  CHECKOUT_STEP4_PERSONAL_RATE_ERROR_FINANCE_AMOUNT_LESS_THAN_3000 = 'CHECKOUT_STEP4_PERSONAL_RATE_ERROR_FINANCE_AMOUNT_LESS_THAN_3000',
  CHECKOUT_STEP4_PERSONAL_RATE_ERROR_FINANCE_AMOUNT_MORE_THAN_200000 = 'CHECKOUT_STEP4_PERSONAL_RATE_ERROR_FINANCE_AMOUNT_LESS_THAN_200000',
  CHECKOUT_STEP4_PERSONAL_RATE_ERROR_NO_TERM_OF_LOAN = 'CHECKOUT_STEP4_PERSONAL_RATE_ERROR_NO_TERM_OF_LOAN',
  CHECKOUT_STEP4_PERSONAL_RATE_ERROR_NO_REPAYMENT_FREQUENCY = 'CHECKOUT_STEP4_PERSONAL_RATE_ERROR_NO_REPAYMENT_FREQUENCY',
  CHECKOUT_STEP4_PERSONAL_RATE_ERROR_MISSING_YEAR = 'CHECKOUT_STEP4_PERSONAL_RATE_ERROR_MISSING_YEAR',
  CHECKOUT_STEP4_PERSONAL_RATE_ERROR_MISSING_MONTH = 'CHECKOUT_STEP4_PERSONAL_RATE_ERROR_MISSING_MONTH',
  CHECKOUT_STEP4_PERSONAL_RATE_ERROR_NO_DATE_PICKER = 'CHECKOUT_STEP4_PERSONAL_RATE_ERROR_NO_DATE_PICKER',
  CHECKOUT_STEP4_PERSONAL_RATE_ERROR_LESS_THAN_18_YEAR_OLD = 'CHECKOUT_STEP4_PERSONAL_RATE_ERROR_LESS_THAN_18_YEAR_OLD',
  CHECKOUT_HEADER_TITLE = 'CHECKOUT_HEADER_TITLE',
  CHECKOUT_HEADER_VIEW_DETAILS = 'CHECKOUT_HEADER_VIEW_DETAILS',
  CHECKOUT_HEADER_HIDE_DETAILS = 'CHECKOUT_HEADER_HIDE_DETAILS',
  CHECKOUT_CHECKOUT_CAR_CARD_PRICE_LABEL = 'CHECKOUT_CHECKOUT_CAR_CARD_PRICE_LABEL',
  CHECKOUT_CHECKOUT_CAR_CARD_PRICE_GST_LABEL = 'CHECKOUT_CHECKOUT_CAR_CARD_PRICE_GST_LABEL',
  CHECKOUT_FINANCE_LOAN_CALCULATOR_TITLE = 'CHECKOUT_FINANCE_LOAN_CALCULATOR_TITLE',
  CHECKOUT_FINANCE_LOAN_CALCULATOR_DESCRIPTION = 'CHECKOUT_FINANCE_LOAN_CALCULATOR_DESCRIPTION',
  CHECKOUT_FOOTER_LINK_1 = 'CHECKOUT_FOOTER_LINK_1',
  CHECKOUT_FOOTER_LINK_2 = 'CHECKOUT_FOOTER_LINK_2',
  CHECKOUT_FOOTER_LINK_3 = 'CHECKOUT_FOOTER_LINK_3',
  CHECKOUT_STEP3_PAYMENT_OPTIONS_CHOOSE_FINANCIER = 'CHECKOUT_STEP3_PAYMENT_OPTIONS_CHOOSE_FINANCIER',
  CHECKOUT_STEP3_PAYMENT_OPTIONS_SKIP_FINANCIER = 'CHECKOUT_STEP3_PAYMENT_OPTIONS_SKIP_FINANCIER',
  CHECKOUT_STEP3_PAYMENT_OPTIONS_NEXT_BUTTON = 'CHECKOUT_STEP3_PAYMENT_OPTIONS_NEXT_BUTTON',
  CHECKOUT_PAYMENT_OPTIONS_HEADER = 'CHECKOUT_PAYMENT_OPTIONS_HEADER',
  CHECKOUT_PAYMENT_OPTIONS_CONTENT = 'CHECKOUT_PAYMENT_OPTIONS_CONTENT',
  CHECKOUT_PAYMENT_OPTIONS_RADIO_LABEL = 'CHECKOUT_PAYMENT_OPTIONS_RADIO_LABEL',
  CHECKOUT_PAYMENT_OPTIONS_RADIO_ITEM_1_LABEL = 'CHECKOUT_PAYMENT_OPTIONS_RADIO_ITEM_1_LABEL',
  CHECKOUT_PAYMENT_OPTIONS_RADIO_ITEM_2_LABEL = 'CHECKOUT_PAYMENT_OPTIONS_RADIO_ITEM_2_LABEL',
  CHECKOUT_PAYMENT_OPTIONS_RADIO_ITEM_3_LABEL = 'CHECKOUT_PAYMENT_OPTIONS_RADIO_ITEM_3_LABEL',
  CHECKOUT_PAYMENT_OPTIONS_BUTTON_LABEL = 'CHECKOUT_PAYMENT_OPTIONS_BUTTON_LABEL',
  CHECKOUT_PAYMENT_OPTIONS_BUTTON_LABEL_QUOTE = 'CHECKOUT_PAYMENT_OPTIONS_BUTTON_LABEL_QUOTE',
  CHECKOUT_PAYMENT_OPTIONS_BUTTON_LABEL_FINANCE = 'CHECKOUT_PAYMENT_OPTIONS_BUTTON_LABEL_FINANCE',
  CHECKOUT_PAYMENT_OPTIONS_BUTTON_LABEL_SUMMARY = 'CHECKOUT_PAYMENT_OPTIONS_BUTTON_LABEL_SUMMARY',
  CHECKOUT_PAYMENT_SELECT_BANK_WESTPAC_CTA_LABEL = 'CHECKOUT_PAYMENT_SELECT_BANK_WESTPAC_CTA_LABEL',
  CHECKOUT_PAYMENT_SELECT_BANK_WESTPAC_CTA_LINK = 'CHECKOUT_PAYMENT_SELECT_BANK_WESTPAC_CTA_LINK',
  CHECKOUT_PAYMENT_SELECT_BANK_NAB_CTA_LABEL = 'CHECKOUT_PAYMENT_SELECT_BANK_NAB_CTA_LABEL',
  CHECKOUT_PAYMENT_SELECT_BANK_NAB_CTA_LINK = 'CHECKOUT_PAYMENT_SELECT_BANK_NAB_CTA_LINK',
  CHECKOUT_PAYMENT_SELECT_BANK_ANZ_CTA_LABEL = 'CHECKOUT_PAYMENT_SELECT_BANK_ANZ_CTA_LABEL',
  CHECKOUT_PAYMENT_SELECT_BANK_ANZ_CTA_LINK = 'CHECKOUT_PAYMENT_SELECT_BANK_ANZ_CTA_LINK',
  CHECKOUT_PAYMENT_SELECT_BANK_COMMBANK_CTA_LABEL = 'CHECKOUT_PAYMENT_SELECT_BANK_COMMBANK_CTA_LABEL',
  CHECKOUT_PAYMENT_SELECT_BANK_COMMBANK_CTA_LINK = 'CHECKOUT_PAYMENT_SELECT_BANK_COMMBANK_CTA_LINK',
  CHECKOUT_PAYMENT_SELECT_BANK_DETAIL_BSB = 'CHECKOUT_PAYMENT_SELECT_BANK_DETAIL_BSB',
  CHECKOUT_PAYMENT_SELECT_BANK_DETAIL_TITLE = 'CHECKOUT_PAYMENT_SELECT_BANK_DETAIL_TITLE',
  CHECKOUT_PAYMENT_SELECT_BANK_DETAIL_CAPTION = 'CHECKOUT_PAYMENT_SELECT_BANK_DETAIL_CAPTION',
  CHECKOUT_PAYMENT_SELECT_BANK_DETAIL_HELP_TEXT = 'CHECKOUT_PAYMENT_SELECT_BANK_DETAIL_HELP_TEXT',
  CHECKOUT_PAYMENT_SELECT_BANK_DETAIL_SUBTITLE = 'CHECKOUT_PAYMENT_SELECT_BANK_DETAIL_SUBTITLE',
  CHECKOUT_PAYMENT_SELECT_BANK_DETAIL_ACC_NUMBER = 'CHECKOUT_PAYMENT_SELECT_BANK_DETAIL_ACC_NUMBER',
  CHECKOUT_PAYMENT_SELECT_BANK_DETAIL_PAID_BUTTON_LABEL = 'CHECKOUT_PAYMENT_SELECT_BANK_DETAIL_PAID_BUTTON_LABEL',
  CHECKOUT_PAYMENT_SELECT_BANK_DETAIL_CLOSE_BUTTON_LABEL = 'CHECKOUT_PAYMENT_SELECT_BANK_DETAIL_CLOSE_BUTTON_LABEL',
  CHECKOUT_PAYMENT_SUMMARY_FORM_MODAL_PROCESSING_FEE_TITLE = 'CHECKOUT_PAYMENT_SUMMARY_FORM_MODAL_PROCESSING_FEE_TITLE',
  CHECKOUT_PAYMENT_SUMMARY_FORM_BANK_TRANSFER = 'CHECKOUT_PAYMENT_SUMMARY_FORM_BANK_TRANSFER',
  CHECKOUT_PAYMENT_SUMMARY_FORM_FREE = 'CHECKOUT_PAYMENT_SUMMARY_FORM_FREE',
  CHECKOUT_STEP_BUY_TYPE_HEADER = 'CHECKOUT_STEP_BUY_TYPE_HEADER',
  CHECKOUT_STEP_BUY_TYPE_DESCRIPTION = 'CHECKOUT_STEP_BUY_TYPE_DESCRIPTION',
  CHECKOUT_STEP_BUY_TYPE_LABEL = 'CHECKOUT_STEP_BUY_TYPE_LABEL',
  CHECKOUT_STEP_BUY_TYPE_OPTION_PERSONAL = 'CHECKOUT_STEP_BUY_TYPE_OPTION_PERSONAL',
  CHECKOUT_STEP_BUY_TYPE_OPTION_BUSINESS = 'CHECKOUT_STEP_BUY_TYPE_OPTION_BUSINESS',
  CHECKOUT_STEP_BUY_TYPE_BUTTON_PERSONAL = 'CHECKOUT_STEP_BUY_TYPE_BUTTON_PERSONAL',
  CHECKOUT_STEP_BUY_TYPE_BUTTON_BUSINESS = 'CHECKOUT_STEP_BUY_TYPE_BUTTON_BUSINESS',
  CHECKOUT_STEP_BUSINESS_INFO_HEADER = 'CHECKOUT_STEP_BUSINESS_INFO_HEADER',
  CHECKOUT_STEP_BUSINESS_INFO_DESCRIPTION = 'CHECKOUT_STEP_BUSINESS_INFO_DESCRIPTION',
  CHECKOUT_STEP_BUSINESS_INFO_ABN_LABEL = 'CHECKOUT_STEP_BUSINESS_INFO_ABN_LABEL',
  CHECKOUT_STEP_BUSINESS_INFO_ABN_HELP = 'CHECKOUT_STEP_BUSINESS_INFO_ABN_HELP',
  CHECKOUT_FINANCE_QUOTE_FORM_RESIDENT_TYPE_TITLE = 'CHECKOUT_FINANCE_QUOTE_FORM_RESIDENT_TYPE_TITLE',
  CHECKOUT_FINANCE_QUOTE_FORM_FAMILY_STATUS_HEADER = 'CHECKOUT_FINANCE_QUOTE_FORM_FAMILY_STATUS_HEADER',
  CHECKOUT_FINANCE_QUOTE_FORM_RESIDENTIAL_STATUS_HEADER = 'CHECKOUT_FINANCE_QUOTE_FORM_RESIDENTIAL_STATUS_HEADER',
  CHECKOUT_FINANCE_QUOTE_FORM_EMPLOYMENT_STATUS_HEADER = 'CHECKOUT_FINANCE_QUOTE_FORM_EMPLOYMENT_STATUS_HEADER',
  CHECKOUT_FINANCE_QUOTE_FORM_BUTTON_LABEL = 'CHECKOUT_FINANCE_QUOTE_FORM_BUTTON_LABEL',
  CHECKOUT_FINANCE_QUOTE_FORM_TERM_CONDITIONS = 'CHECKOUT_FINANCE_QUOTE_FORM_TERM_CONDITIONS',
  CHECKOUT_FINANCE_QUOTE_FORM_HEADER = 'CHECKOUT_FINANCE_QUOTE_FORM_HEADER',
  CHECKOUT_FINANCE_QUOTE_FORM_CONTENT = 'CHECKOUT_FINANCE_QUOTE_FORM_CONTENT',
  CHECKOUT_FINANCE_QUOTE_CTA = 'CHECKOUT_FINANCE_QUOTE_CTA',
  CHECKOUT_FINANCE_QUOTE_NOTE = 'CHECKOUT_FINANCE_QUOTE_NOTE',
  CHECKOUT_FINANCE_QUOTE_NOTE_PAYMENT_SUMMARY = 'CHECKOUT_FINANCE_QUOTE_NOTE_PAYMENT_SUMMARY',
  CHECKOUT_FINANCE_QUOTE_UPDATE_FINANCE_INFO_BUTTON_LABEL = 'CHECKOUT_FINANCE_QUOTE_UPDATE_FINANCE_INFO_BUTTON_LABEL',
  CHECKOUT_FINANCE_QUOTE_FEE_LABEL = 'CHECKOUT_FINANCE_QUOTE_FEE_LABEL',
  CHECKOUT_FINANCE_QUOTE_DETAILED_SETUP_FEES = 'CHECKOUT_FINANCE_QUOTE_DETAILED_SETUP_FEES',
  CHECKOUT_FINANCE_QUOTE_DETAILED_MONTHLY_FEES = 'CHECKOUT_FINANCE_QUOTE_DETAILED_MONTHLY_FEES',
  CHECKOUT_FINANCE_QUOTE_DETAILED_EARLY_TERMINATION_FEES = 'CHECKOUT_FINANCE_QUOTE_DETAILED_EARLY_TERMINATION_FEES',
  CHECKOUT_FINANCE_QUOTE_PROVIDER_CARMA = 'CHECKOUT_FINANCE_QUOTE_PROVIDER_CARMA',
  CHECKOUT_FINANCE_QUOTE_LOADING_LABEL = 'CHECKOUT_FINANCE_QUOTE_LOADING_LABEL',
  CHECKOUT_FINANCE_QUOTE_INFO_FORM_IMPORTANT_INFORMATION = 'CHECKOUT_FINANCE_QUOTE_INFO_FORM_IMPORTANT_INFORMATION',
  CHECKOUT_FINANCE_QUOTE_FEE_BREAKDOWN_CTA = 'CHECKOUT_FINANCE_QUOTE_FEE_BREAKDOWN_CTA',
  CHECKOUT_FINANCE_QUOTE_FREQUENCY_SWITCH_HEADER = 'CHECKOUT_FINANCE_QUOTE_FREQUENCY_SWITCH_HEADER',
  CHECKOUT_FINANCE_QUOTE_FREQUENCY_WEEKLY = 'CHECKOUT_FINANCE_QUOTE_FREQUENCY_WEEKLY',
  CHECKOUT_FINANCE_QUOTE_FREQUENCY_MONTHLY = 'CHECKOUT_FINANCE_QUOTE_FREQUENCY_MONTHLY',
  CHECKOUT_FINANCE_QUOTE_LOAN_TERM_HEADER = 'CHECKOUT_FINANCE_QUOTE_LOAN_TERM_HEADER',
  CHECKOUT_FINANCE_QUOTE_LOAN_TERM_NO_QUOTE_AVAILABLE_HEADER = 'CHECKOUT_FINANCE_QUOTE_LOAN_TERM_NO_QUOTE_AVAILABLE_HEADER',
  CHECKOUT_FINANCE_QUOTES_ALLIED_UNAVAILABLE = 'CHECKOUT_FINANCE_QUOTES_ALLIED_UNAVAILABLE',
  CHECKOUT_FINANCE_QUOTES_PLENTI_BUSINESS_UNAVAILABLE = 'CHECKOUT_FINANCE_QUOTES_PLENTI_BUSINESS_UNAVAILABLE',
  CHECKOUT_FINANCE_QUOTES_PLENTI_PERSONAL_UNAVAILABLE = 'CHECKOUT_FINANCE_QUOTES_PLENTI_PERSONAL_UNAVAILABLE',
  CHECKOUT_FINANCE_QUOTES_WISR_BUSINESS_UNAVAILABLE = 'CHECKOUT_FINANCE_QUOTES_WISR_BUSINESS_UNAVAILABLE',
  CHECKOUT_FINANCE_QUOTES_WISR_PERSONAL_UNAVAILABLE = 'CHECKOUT_FINANCE_QUOTES_WISR_PERSONAL_UNAVAILABLE',
  CHECKOUT_FINANCE_QUOTES_ALLIED_PERSONAL_HAS_EARLY_TERMINATION_FEE = 'CHECKOUT_FINANCE_QUOTES_ALLIED_PERSONAL_HAS_EARLY_TERMINATION_FEE',
  CHECKOUT_FINANCE_QUOTES_ALLIED_BUSINESS_HAS_EARLY_TERMINATION_FEE = 'CHECKOUT_FINANCE_QUOTES_ALLIED_BUSINESS_HAS_EARLY_TERMINATION_FEE',
  CHECKOUT_FINANCE_QUOTES_PLENTI_PERSONAL_HAS_EARLY_TERMINATION_FEE = 'CHECKOUT_FINANCE_QUOTES_PLENTI_PERSONAL_HAS_EARLY_TERMINATION_FEE',
  CHECKOUT_FINANCE_QUOTES_PLENTI_BUSINESS_HAS_EARLY_TERMINATION_FEE = 'CHECKOUT_FINANCE_QUOTES_PLENTI_BUSINESS_HAS_EARLY_TERMINATION_FEE',
  CHECKOUT_FINANCE_QUOTES_WISR_PERSONAL_HAS_EARLY_TERMINATION_FEE = 'CHECKOUT_FINANCE_QUOTES_WISR_PERSONAL_HAS_EARLY_TERMINATION_FEE',
  CHECKOUT_FINANCE_QUOTES_WISR_BUSINESS_HAS_EARLY_TERMINATION_FEE = 'CHECKOUT_FINANCE_QUOTES_WISR_BUSINESS_HAS_EARLY_TERMINATION_FEE',
  CHECKOUT_FINANCE_QUOTE_LOAN_TERM_DESCRIPTION = 'CHECKOUT_FINANCE_QUOTE_LOAN_TERM_DESCRIPTION',
  CHECKOUT_FINANCE_QUOTE_UNAVAILABLE_LABEL = 'CHECKOUT_FINANCE_QUOTE_UNAVAILABLE_LABEL',
  CHECKOUT_FINANCE_QUOTE_VISA_ISSUE_ALLIED_LABEL = 'CHECKOUT_FINANCE_QUOTE_VISA_ISSUE_ALLIED_LABEL',
  CHECKOUT_FINANCE_QUOTE_VISA_ISSUE_PLENTI_LABEL = 'CHECKOUT_FINANCE_QUOTE_VISA_ISSUE_PLENTI_LABEL',
  CHECKOUT_FINANCE_QUOTE_VISA_ISSUE_WISR_LABEL = 'CHECKOUT_FINANCE_QUOTE_VISA_ISSUE_WISR_LABEL',
  CHECKOUT_FINANCE_QUOTE_VISA_EXPIRY_ALLIED_LABEL = 'CHECKOUT_FINANCE_QUOTE_VISA_EXPIRY_ALLIED_LABEL',
  CHECKOUT_FINANCE_QUOTE_VISA_EXPIRY_PLENTI_LABEL = 'CHECKOUT_FINANCE_QUOTE_VISA_EXPIRY_PLENTI_LABEL',
  CHECKOUT_FINANCE_QUOTE_VISA_EXPIRY_WISR_LABEL = 'CHECKOUT_FINANCE_QUOTE_VISA_EXPIRY_WISR_LABEL',
  CHECKOUT_FINANCE_QUOTE_INVALID_VISA_CODE_ALLIED_LABEL = 'CHECKOUT_FINANCE_QUOTE_INVALID_VISA_CODE_ALLIED_LABEL',
  CHECKOUT_FINANCE_QUOTE_INVALID_VISA_CODE_PLENTI_LABEL = 'CHECKOUT_FINANCE_QUOTE_INVALID_VISA_CODE_PLENTI_LABEL',
  CHECKOUT_FINANCE_QUOTE_INVALID_VISA_CODE_WISR_LABEL = 'CHECKOUT_FINANCE_QUOTE_INVALID_VISA_CODE_WISR_LABEL',
  CHECKOUT_FINANCE_QUOTE_FOREIGN_NOT_SUPPORT_ALLIED_LABEL = 'CHECKOUT_FINANCE_QUOTE_FOREIGN_NOT_SUPPORT_ALLIED_LABEL',
  CHECKOUT_FINANCE_QUOTE_FOREIGN_NOT_SUPPORT_PLENTI_LABEL = 'CHECKOUT_FINANCE_QUOTE_FOREIGN_NOT_SUPPORT_PLENTI_LABEL',
  CHECKOUT_FINANCE_QUOTE_FOREIGN_NOT_SUPPORT_WISR_LABEL = 'CHECKOUT_FINANCE_QUOTE_FOREIGN_NOT_SUPPORT_WISR_LABEL',
  CHECKOUT_FINANCE_QUOTE_BUSINESS_NOT_SUPPORT_ALLIED_LABEL = 'CHECKOUT_FINANCE_QUOTE_BUSINESS_NOT_SUPPORT_ALLIED_LABEL',
  CHECKOUT_FINANCE_QUOTE_BUSINESS_NOT_SUPPORT_PLENTI_LABEL = 'CHECKOUT_FINANCE_QUOTE_BUSINESS_NOT_SUPPORT_PLENTI_LABEL',
  CHECKOUT_FINANCE_QUOTE_BUSINESS_NOT_SUPPORT_WISR_LABEL = 'CHECKOUT_FINANCE_QUOTE_BUSINESS_NOT_SUPPORT_WISR_LABEL',
  CHECKOUT_FINANCE_QUOTE_MODAL_HEADER = 'CHECKOUT_FINANCE_QUOTE_MODAL_HEADER',
  CHECKOUT_FINANCE_QUOTE_MODAL_DETAIL_LINE_1 = 'CHECKOUT_FINANCE_QUOTE_MODAL_DETAIL_LINE_1',
  CHECKOUT_FINANCE_QUOTE_MODAL_DETAIL_LINE_2 = 'CHECKOUT_FINANCE_QUOTE_MODAL_DETAIL_LINE_2',
  CHECKOUT_FINANCE_QUOTE_MODAL_DETAIL_LINE_3 = 'CHECKOUT_FINANCE_QUOTE_MODAL_DETAIL_LINE_3',
  CHECKOUT_FINANCE_QUOTE_MODAL_DETAIL_LINE_4 = 'CHECKOUT_FINANCE_QUOTE_MODAL_DETAIL_LINE_4',
  CHECKOUT_FINANCE_QUOTE_MODAL_DETAIL_LINE_5 = 'CHECKOUT_FINANCE_QUOTE_MODAL_DETAIL_LINE_5',
  CHECKOUT_FINANCE_QUOTE_MODAL_DETAIL_LINE_6 = 'CHECKOUT_FINANCE_QUOTE_MODAL_DETAIL_LINE_6',
  CHECKOUT_FINANCE_QUOTE_MODAL_DETAIL_LINE_7 = 'CHECKOUT_FINANCE_QUOTE_MODAL_DETAIL_LINE_7',
  CHECKOUT_FINANCE_QUOTE_MODAL_DETAIL_LINE_8 = 'CHECKOUT_FINANCE_QUOTE_MODAL_DETAIL_LINE_8',
  CHECKOUT_FINANCE_QUOTE_MODAL_DETAIL_LINE_9 = 'CHECKOUT_FINANCE_QUOTE_MODAL_DETAIL_LINE_9',
  CHECKOUT_FINANCE_QUOTE_MODAL_DETAIL_LINE_10 = 'CHECKOUT_FINANCE_QUOTE_MODAL_DETAIL_LINE_10',
  CHECKOUT_FINANCE_QUOTE_MODAL_DETAIL_LINE_11 = 'CHECKOUT_FINANCE_QUOTE_MODAL_DETAIL_LINE_11',
  CHECKOUT_FINANCE_QUOTE_MODAL_DETAIL_LINE_12 = 'CHECKOUT_FINANCE_QUOTE_MODAL_DETAIL_LINE_12',
  CHECKOUT_FINANCE_QUOTE_MODAL_DETAIL_LINE_13 = 'CHECKOUT_FINANCE_QUOTE_MODAL_DETAIL_LINE_13',
  CHECKOUT_FINANCE_QUOTE_MODAL_DETAIL_LINE_14 = 'CHECKOUT_FINANCE_QUOTE_MODAL_DETAIL_LINE_14',
  CHECKOUT_FINANCE_QUOTE_MODAL_DISCOUNTED_LABEL = 'CHECKOUT_FINANCE_QUOTE_MODAL_DISCOUNTED_LABEL',
  CHECKOUT_FINANCE_INFORMATION_MODAL_TITLE = 'CHECKOUT_FINANCE_INFORMATION_MODAL_TITLE',
  CHECKOUT_FINANCE_INFORMATION_MODAL_DESCRIPTION = 'CHECKOUT_FINANCE_INFORMATION_MODAL_DESCRIPTION',
  CHECKOUT_FINANCE_INFORMATION_MODAL_BACK_BUTTON = 'CHECKOUT_FINANCE_INFORMATION_MODAL_BACK_BUTTON',
  CHECKOUT_FINANCE_INFORMATION_MODAL_CONTINUE_BUTTON = 'CHECKOUT_FINANCE_INFORMATION_MODAL_CONTINUE_BUTTON',
  CHECKOUT_FINANCE_QUOTE_MODAL_ALLIED_PERSONAL_EARLY_TERMINATION_FEE = 'CHECKOUT_FINANCE_QUOTE_MODAL_ALLIED_PERSONAL_EARLY_TERMINATION_FEE',
  CHECKOUT_FINANCE_QUOTE_MODAL_ALLIED_BUSINESS_EARLY_TERMINATION_FEE = 'CHECKOUT_FINANCE_QUOTE_MODAL_ALLIED_BUSINESS_EARLY_TERMINATION_FEE',
  CHECKOUT_FINANCE_QUOTE_MODAL_PLENTI_PERSONAL_EARLY_TERMINATION_FEE = 'CHECKOUT_FINANCE_QUOTE_MODAL_PLENTI_PERSONAL_EARLY_TERMINATION_FEE',
  CHECKOUT_FINANCE_QUOTE_MODAL_PLENTI_BUSINESS_EARLY_TERMINATION_FEE = 'CHECKOUT_FINANCE_QUOTE_MODAL_PLENTI_BUSINESS_EARLY_TERMINATION_FEE',
  CHECKOUT_FINANCE_QUOTE_MODAL_WISR_PERSONAL_EARLY_TERMINATION_FEE = 'CHECKOUT_FINANCE_QUOTE_MODAL_WISR_PERSONAL_EARLY_TERMINATION_FEE',
  CHECKOUT_FINANCE_QUOTE_MODAL_WISR_BUSINESS_EARLY_TERMINATION_FEE = 'CHECKOUT_FINANCE_QUOTE_MODAL_WISR_BUSINESS_EARLY_TERMINATION_FEE',
  CHECKOUT_FINANCE_QUOTE_VISA_ISSUES_TITLE = 'CHECKOUT_FINANCE_QUOTE_VISA_ISSUES_TITLE',
  CHECKOUT_FINANCE_QUOTE_VISA_ISSUES_PERSONALIZED_DESCRIPTION = 'CHECKOUT_FINANCE_QUOTE_VISA_ISSUES_PERSONALIZED_DESCRIPTION',
  CHECKOUT_FINANCE_QUOTE_VISA_ISSUES_CHECKOUT_DESCRIPTION = 'CHECKOUT_FINANCE_QUOTE_VISA_ISSUES_CHECKOUT_DESCRIPTION',
  CHECKOUT_FINANCE_QUOTE_VISA_ISSUES_BUTTON_CHANGE_LABEL = 'CHECKOUT_FINANCE_QUOTE_VISA_ISSUES_BUTTON_CHANGE_LABEL',
  CHECKOUT_FINANCE_QUOTE_VISA_ISSUES_REQUEST_CALLBACK_LABEL = 'CHECKOUT_FINANCE_QUOTE_VISA_ISSUES_REQUEST_CALLBACK_LABEL',
  CHECKOUT_FINANCE_QUOTE_VISA_ISSUES_CONTINUE_WITH_FINANCE_LABEL = 'CHECKOUT_FINANCE_QUOTE_VISA_ISSUES_CONTINUE_WITH_FINANCE_LABEL',
  CHECKOUT_FINANCE_QUOTE_VISA_ISSUES_BACK_TO_HOME_LABEL = 'CHECKOUT_FINANCE_QUOTE_VISA_ISSUES_BACK_TO_HOME_LABEL',
  CHECKOUT_FINANCE_QUOTE_MODAL_VISA_ISSUES_TITLE = 'CHECKOUT_FINANCE_QUOTE_MODAL_VISA_ISSUES_TITLE',
  CHECKOUT_FINANCE_QUOTE_MODAL_VISA_ISSUES_CONTENT = 'CHECKOUT_FINANCE_QUOTE_MODAL_VISA_ISSUES_CONTENT',
  CHECKOUT_FINANCE_QUOTE_MODAL_VISA_ISSUES_BUTTON_LABEL = 'CHECKOUT_FINANCE_QUOTE_MODAL_VISA_ISSUES_BUTTON_LABEL',
  CHECKOUT_BYOF_CARMA_FINANCE_FORM_HEADER = 'CHECKOUT_BYOF_CARMA_FINANCE_FORM_HEADER',
  CHECKOUT_BYOF_CARMA_FINANCE_FORM_DESCRIPTION = 'CHECKOUT_BYOF_CARMA_FINANCE_FORM_DESCRIPTION',
  CHECKOUT_BYOF_CARMA_FINANCE_FORM_LENDER_NAME_LABEL = 'CHECKOUT_BYOF_CARMA_FINANCE_FORM_LENDER_NAME_LABEL',
  CHECKOUT_BYOF_CARMA_FINANCE_FORM_CONTACT_NAME_LABEL = 'CHECKOUT_BYOF_CARMA_FINANCE_FORM_CONTACT_NAME_LABEL',
  CHECKOUT_BYOF_CARMA_FINANCE_FORM_CONTACT_EMAIL_LABEL = 'CHECKOUT_BYOF_CARMA_FINANCE_FORM_CONTACT_EMAIL_LABEL',
  CHECKOUT_BYOF_CARMA_FINANCE_FORM_CONTACT_MOBILE_LABEL = 'CHECKOUT_BYOF_CARMA_FINANCE_FORM_CONTACT_MOBILE_LABEL',
  CHECKOUT_BYOF_CARMA_FINANCE_FORM_OTHER_LABEL = 'CHECKOUT_BYOF_CARMA_FINANCE_FORM_OTHER_LABEL',
  CHECKOUT_BYOF_CARMA_FINANCE_FORM_CARMA_FINANCE_OPTION = 'CHECKOUT_BYOF_CARMA_FINANCE_FORM_CARMA_FINANCE_OPTION',
  CHECKOUT_BYOF_CARMA_FINANCE_FORM_LENDER_NAME_OPTION_1 = 'CHECKOUT_BYOF_CARMA_FINANCE_FORM_LENDER_NAME_OPTION_1',
  CHECKOUT_BYOF_CARMA_FINANCE_FORM_LENDER_NAME_OPTION_2 = 'CHECKOUT_BYOF_CARMA_FINANCE_FORM_LENDER_NAME_OPTION_2',
  CHECKOUT_BYOF_CARMA_FINANCE_FORM_LENDER_NAME_OPTION_3 = 'CHECKOUT_BYOF_CARMA_FINANCE_FORM_LENDER_NAME_OPTION_3',
  CHECKOUT_BYOF_CARMA_FINANCE_FORM_LENDER_NAME_OPTION_4 = 'CHECKOUT_BYOF_CARMA_FINANCE_FORM_LENDER_NAME_OPTION_4',
  CHECKOUT_BYOF_CARMA_FINANCE_FORM_LENDER_NAME_OPTION_5 = 'CHECKOUT_BYOF_CARMA_FINANCE_FORM_LENDER_NAME_OPTION_5',
  CHECKOUT_BYOF_CARMA_FINANCE_FORM_LENDER_NAME_OPTION_6 = 'CHECKOUT_BYOF_CARMA_FINANCE_FORM_LENDER_NAME_OPTION_6',
  CHECKOUT_BYOF_CARMA_FINANCE_FORM_SUBMIT_BUTTON_LABEL = 'CHECKOUT_BYOF_CARMA_FINANCE_FORM_SUBMIT_BUTTON_LABEL',
  CHECKOUT_BUYER_INFORMATION_HEADER = 'CHECKOUT_BUYER_INFORMATION_HEADER',
  CHECKOUT_BUYER_INFORMATION_DESCRIPTION = 'CHECKOUT_BUYER_INFORMATION_DESCRIPTION',
  CHECKOUT_BUYER_INFORMATION_BUSINESS_ADDITION_INFO = 'CHECKOUT_BUYER_INFORMATION_BUSINESS_ADDITION_INFO',
  CHECKOUT_BUYER_INFORMATION_BUTTON_LABEL = 'CHECKOUT_BUYER_INFORMATION_BUTTON_LABEL',
  CHECKOUT_BUYER_INFORMATION_FORM_HEADER = 'CHECKOUT_BUYER_INFORMATION_FORM_HEADER',
  CHECKOUT_BUYER_INFORMATION_INPUT_FIRST_NAME_LABEL = 'CHECKOUT_BUYER_INFORMATION_INPUT_FIRST_NAME_LABEL',
  CHECKOUT_BUYER_INFORMATION_INPUT_MIDDLE_NAME_LABEL = 'CHECKOUT_BUYER_INFORMATION_INPUT_MIDDLE_NAME_LABEL',
  CHECKOUT_BUYER_INFORMATION_INPUT_LAST_NAME_LABEL = 'CHECKOUT_BUYER_INFORMATION_INPUT_LAST_NAME_LABEL',
  CHECKOUT_BUYER_INFORMATION_INPUT_DATE_OF_BIRTH_LABEL = 'CHECKOUT_BUYER_INFORMATION_INPUT_DATE_OF_BIRTH_LABEL',
  CHECKOUT_BUYER_INFORMATION_INPUT_STREET_ADDRESS_LABEL = 'CHECKOUT_BUYER_INFORMATION_INPUT_STREET_ADDRESS_LABEL',
  CHECKOUT_BUYER_INFORMATION_INPUT_APARTMENT_LABEL = 'CHECKOUT_BUYER_INFORMATION_INPUT_APARTMENT_LABEL',
  CHECKOUT_BUYER_INFORMATION_INPUT_SUBURB_LABEL = 'CHECKOUT_BUYER_INFORMATION_INPUT_SUBURB_LABEL',
  CHECKOUT_BUYER_INFORMATION_INPUT_POSTCODE_LABEL = 'CHECKOUT_BUYER_INFORMATION_INPUT_POSTCODE_LABEL',
  CHECKOUT_BUYER_INFORMATION_INPUT_STATE_LABEL = 'CHECKOUT_BUYER_INFORMATION_INPUT_STATE_LABEL',
  CHECKOUT_BUYER_INFORMATION_INPUT_LICENCE_NUMBER_LABEL = 'CHECKOUT_BUYER_INFORMATION_INPUT_LICENCE_NUMBER_LABEL',
  CHECKOUT_BUYER_INFORMATION_INPUT_LICENCE_NUMBER_HELPER = 'CHECKOUT_BUYER_INFORMATION_INPUT_LICENCE_NUMBER_HELPER',
  CHECKOUT_FINANCE_QUOTE_INFO_FORM_HEADER = 'CHECKOUT_FINANCE_QUOTE_INFO_FORM_HEADER',
  CHECKOUT_FINANCE_QUOTE_INFO_FORM_DESCRIPTION = 'CHECKOUT_FINANCE_QUOTE_INFO_FORM_DESCRIPTION',
  CHECKOUT_FINANCE_QUOTE_INFO_FORM_LOAN_CALCULATOR_HEADER = 'CHECKOUT_FINANCE_QUOTE_INFO_FORM_LOAN_CALCULATOR_HEADER',
  CHECKOUT_FINANCE_QUOTE_INFO_FORM_LOAN_CALCULATOR_SUB_HEADER = 'CHECKOUT_FINANCE_QUOTE_INFO_FORM_LOAN_CALCULATOR_SUB_HEADER',
  CHECKOUT_FINANCE_QUOTE_INFO_FORM_LOAN_CALCULATOR_DESCRIPTION = 'CHECKOUT_FINANCE_QUOTE_INFO_FORM_LOAN_CALCULATOR_DESCRIPTION',
  CHECKOUT_FINANCE_QUOTE_INFO_FORM_BUSINESS_NOTIFICATION = 'CHECKOUT_FINANCE_QUOTE_INFO_FORM_BUSINESS_NOTIFICATION',
  CHECKOUT_FINANCE_QUOTE_INFO_FORM_FAMILY_STATUS_HEADER = 'CHECKOUT_FINANCE_QUOTE_INFO_FORM_FAMILY_STATUS_HEADER',
  CHECKOUT_FINANCE_QUOTE_INFO_FORM_FAMILY_STATUS_OPTION_SINGLE = 'CHECKOUT_FINANCE_QUOTE_INFO_FORM_FAMILY_STATUS_OPTION_SINGLE',
  CHECKOUT_FINANCE_QUOTE_INFO_FORM_FAMILY_STATUS_OPTION_MARRIED = 'CHECKOUT_FINANCE_QUOTE_INFO_FORM_FAMILY_STATUS_OPTION_MARRIED',
  CHECKOUT_FINANCE_QUOTE_INFO_FORM_FAMILY_STATUS_OPTION_WIDOWED = 'CHECKOUT_FINANCE_QUOTE_INFO_FORM_FAMILY_STATUS_OPTION_WIDOWED',
  CHECKOUT_FINANCE_QUOTE_INFO_FORM_FAMILY_STATUS_OPTION_SEPARATED = 'CHECKOUT_FINANCE_QUOTE_INFO_FORM_FAMILY_STATUS_OPTION_SEPARATED',
  CHECKOUT_FINANCE_QUOTE_INFO_FORM_FAMILY_STATUS_OPTION_DIVORCED = 'CHECKOUT_FINANCE_QUOTE_INFO_FORM_FAMILY_STATUS_OPTION_DIVORCED',
  CHECKOUT_FINANCE_QUOTE_INFO_FORM_FAMILY_STATUS_OPTION_DE_FACTO = 'CHECKOUT_FINANCE_QUOTE_INFO_FORM_FAMILY_STATUS_OPTION_DE_FACTO',
  CHECKOUT_FINANCE_QUOTE_INFO_FORM_FAMILY_STATUS_OPTION_OTHER = 'CHECKOUT_FINANCE_QUOTE_INFO_FORM_FAMILY_STATUS_OPTION_OTHER',
  CHECKOUT_FINANCE_QUOTE_INFO_FORM_RESIDENTIAL_ADDRESS_HEADER = 'CHECKOUT_FINANCE_QUOTE_INFO_FORM_RESIDENTIAL_ADDRESS_HEADER',
  CHECKOUT_FINANCE_QUOTE_INFO_FORM_PREVIOUS_RESIDENTIAL_ADDRESS_HEADER = 'CHECKOUT_FINANCE_QUOTE_INFO_FORM_PREVIOUS_RESIDENTIAL_ADDRESS_HEADER',
  CHECKOUT_FINANCE_QUOTE_INFO_FORM_RESIDENTIAL_STATUS_OPTION_1 = 'CHECKOUT_FINANCE_QUOTE_INFO_FORM_RESIDENTIAL_STATUS_OPTION_1',
  CHECKOUT_FINANCE_QUOTE_INFO_FORM_RESIDENTIAL_STATUS_OPTION_2 = 'CHECKOUT_FINANCE_QUOTE_INFO_FORM_RESIDENTIAL_STATUS_OPTION_2',
  CHECKOUT_FINANCE_QUOTE_INFO_FORM_RESIDENTIAL_STATUS_OPTION_3 = 'CHECKOUT_FINANCE_QUOTE_INFO_FORM_RESIDENTIAL_STATUS_OPTION_3',
  CHECKOUT_FINANCE_QUOTE_INFO_FORM_RESIDENTIAL_STATUS_OPTION_4 = 'CHECKOUT_FINANCE_QUOTE_INFO_FORM_RESIDENTIAL_STATUS_OPTION_4',
  CHECKOUT_FINANCE_QUOTE_INFO_FORM_RESIDENTIAL_STATUS_OPTION_5 = 'CHECKOUT_FINANCE_QUOTE_INFO_FORM_RESIDENTIAL_STATUS_OPTION_5',
  CHECKOUT_FINANCE_QUOTE_INFO_FORM_TENANCY_AGREEMENT_HEADER = 'CHECKOUT_FINANCE_QUOTE_INFO_FORM_TENANCY_AGREEMENT_HEADER',
  CHECKOUT_FINANCE_QUOTE_INFO_FORM_OWN_INVESTMENT_PROPERTY_HEADER = 'CHECKOUT_FINANCE_QUOTE_INFO_FORM_OWN_INVESTMENT_PROPERTY_HEADER',
  CHECKOUT_FINANCE_QUOTE_INFO_FORM_ADDRESS_PROPERTY_HEADER = 'CHECKOUT_FINANCE_QUOTE_INFO_FORM_ADDRESS_PROPERTY_HEADER',
  CHECKOUT_FINANCE_QUOTE_INFO_FORM_IS_MORTGAGED_PROPERTY_HEADER = 'CHECKOUT_FINANCE_QUOTE_INFO_FORM_IS_MORTGAGED_PROPERTY_HEADER',
  CHECKOUT_FINANCE_QUOTE_INFO_FORM_MORTGAGE_HEADER = 'CHECKOUT_FINANCE_QUOTE_INFO_FORM_MORTGAGE_HEADER',
  CHECKOUT_FINANCE_QUOTE_INFO_FORM_OWN_INVESTMENT_PROPERTY_MORTGAGE_HEADER = 'CHECKOUT_FINANCE_QUOTE_INFO_FORM_OWN_INVESTMENT_PROPERTY_MORTGAGE_HEADER',
  CHECKOUT_FINANCE_QUOTE_INFO_FORM_IS_INVESTMENT_PROPERTY_HEADER = 'CHECKOUT_FINANCE_QUOTE_INFO_FORM_IS_INVESTMENT_PROPERTY_HEADER',
  CHECKOUT_FINANCE_QUOTE_INFO_FORM_EMPLOYMENT_STATUS_HEADER = 'CHECKOUT_FINANCE_QUOTE_INFO_FORM_EMPLOYMENT_STATUS_HEADER',
  CHECKOUT_FINANCE_QUOTE_INFO_FORM_EMPLOYMENT_STATUS_OPTION_FULL_TIME = 'CHECKOUT_FINANCE_QUOTE_INFO_FORM_EMPLOYMENT_STATUS_OPTION_FULL_TIME',
  CHECKOUT_FINANCE_QUOTE_INFO_FORM_EMPLOYMENT_STATUS_OPTION_PART_TIME = 'CHECKOUT_FINANCE_QUOTE_INFO_FORM_EMPLOYMENT_STATUS_OPTION_PART_TIME',
  CHECKOUT_FINANCE_QUOTE_INFO_FORM_EMPLOYMENT_STATUS_OPTION_CASUAL = 'CHECKOUT_FINANCE_QUOTE_INFO_FORM_EMPLOYMENT_STATUS_OPTION_CASUAL',
  CHECKOUT_FINANCE_QUOTE_INFO_FORM_EMPLOYMENT_STATUS_OPTION_CONTRACT = 'CHECKOUT_FINANCE_QUOTE_INFO_FORM_EMPLOYMENT_STATUS_OPTION_CONTRACT',
  CHECKOUT_FINANCE_QUOTE_INFO_FORM_EMPLOYMENT_STATUS_OPTION_SELF_EMPLOYED = 'CHECKOUT_FINANCE_QUOTE_INFO_FORM_EMPLOYMENT_STATUS_OPTION_SELF_EMPLOYED',
  CHECKOUT_FINANCE_QUOTE_INFO_FORM_EMPLOYMENT_STATUS_OPTION_RETIRED = 'CHECKOUT_FINANCE_QUOTE_INFO_FORM_EMPLOYMENT_STATUS_OPTION_RETIRED',
  CHECKOUT_FINANCE_QUOTE_INFO_FORM_EMPLOYMENT_STATUS_OPTION_UNEMPLOYED = 'CHECKOUT_FINANCE_QUOTE_INFO_FORM_EMPLOYMENT_STATUS_OPTION_UNEMPLOYED',
  CHECKOUT_FINANCE_QUOTE_INFO_FORM_RESIDENCY_STATUS_HEADER = 'CHECKOUT_FINANCE_QUOTE_INFO_FORM_RESIDENCY_STATUS_HEADER',
  CHECKOUT_FINANCE_QUOTE_INFO_FORM_VISA_INFORMATION_HEADER = 'CHECKOUT_FINANCE_QUOTE_INFO_FORM_VISA_INFORMATION_HEADER',
  CHECKOUT_FINANCE_QUOTE_INFO_FORM_VISA_INFORMATION_DESCRIPTION = 'CHECKOUT_FINANCE_QUOTE_INFO_FORM_VISA_INFORMATION_DESCRIPTION',
  CHECKOUT_FINANCE_QUOTE_INFO_FORM_VISA_INFORMATION_DISCLAIMER = 'CHECKOUT_FINANCE_QUOTE_INFO_FORM_VISA_INFORMATION_DISCLAIMER',
  CHECKOUT_FINANCE_QUOTE_INFO_FORM_RESIDENCY_STATUS_OPTION_1 = 'CHECKOUT_FINANCE_QUOTE_INFO_FORM_RESIDENCY_STATUS_OPTION_1',
  CHECKOUT_FINANCE_QUOTE_INFO_FORM_RESIDENCY_STATUS_OPTION_2 = 'CHECKOUT_FINANCE_QUOTE_INFO_FORM_RESIDENCY_STATUS_OPTION_2',
  CHECKOUT_FINANCE_QUOTE_INFO_FORM_RESIDENCY_STATUS_OPTION_3 = 'CHECKOUT_FINANCE_QUOTE_INFO_FORM_RESIDENCY_STATUS_OPTION_3',
  CHECKOUT_FINANCE_QUOTE_INFO_FORM_MORTGAGE_OPTION_LABEL = 'CHECKOUT_FINANCE_QUOTE_INFO_FORM_MORTGAGE_OPTION_LABEL',
  CHECKOUT_FINANCE_QUOTE_INFO_FORM_NO_MORTGAGE_OPTION_LABEL = 'CHECKOUT_FINANCE_QUOTE_INFO_FORM_NO_MORTGAGE_OPTION_LABEL',
  CHECKOUT_FINANCE_QUOTE_INFO_FORM_BUTTON_LABEL = 'CHECKOUT_FINANCE_QUOTE_INFO_FORM_BUTTON_LABEL',
  CHECKOUT_FINANCE_QUOTE_INFO_FORM_TERM_CONDITIONS = 'CHECKOUT_FINANCE_QUOTE_INFO_FORM_TERM_CONDITIONS',
  CHECKOUT_FINANCE_QUOTE_INFO_FORM_TERM_CONDITIONS_FOR_BUSINESS = 'CHECKOUT_FINANCE_QUOTE_INFO_FORM_TERM_CONDITIONS_FOR_BUSINESS',
  CHECKOUT_FINANCE_QUOTE_INFO_FORM_TERM_CONDITIONS_LABEL = 'CHECKOUT_FINANCE_QUOTE_INFO_FORM_TERM_CONDITIONS_LABEL',
  CHECKOUT_FINANCE_QUOTE_INFO_FORM_NOTIFICATION = 'CHECKOUT_FINANCE_QUOTE_INFO_FORM_NOTIFICATION',
  CHECKOUT_BUYER_INFORMATION_PERSONAL_FORM_HEADER = 'CHECKOUT_BUYER_INFORMATION_PERSONAL_FORM_HEADER',
  CHECKOUT_BUYER_INFORMATION_PERSONAL_FORM_DESCRIPTION = 'CHECKOUT_BUYER_INFORMATION_PERSONAL_FORM_DESCRIPTION',
  CHECKOUT_BUYER_INFORMATION_PERSONAL_FORM_BUTTON = 'CHECKOUT_BUYER_INFORMATION_PERSONAL_FORM_BUTTON',
  CHECKOUT_BUYER_INFORMATION_PERSONAL_FORM_TITLE_LABEL = 'CHECKOUT_BUYER_INFORMATION_PERSONAL_FORM_TITLE_LABEL',
  CHECKOUT_BUYER_INFORMATION_PERSONAL_FORM_FIRST_NAME_LABEL = 'CHECKOUT_BUYER_INFORMATION_PERSONAL_FORM_FIRST_NAME_LABEL',
  CHECKOUT_BUYER_INFORMATION_PERSONAL_FORM_MIDDLE_NAME_LABEL = 'CHECKOUT_BUYER_INFORMATION_PERSONAL_FORM_MIDDLE_NAME_LABEL',
  CHECKOUT_BUYER_INFORMATION_PERSONAL_FORM_LAST_NAME_LABEL = 'CHECKOUT_BUYER_INFORMATION_PERSONAL_FORM_LAST_NAME_LABEL',
  CHECKOUT_BUYER_INFORMATION_PERSONAL_FORM_DATE_OF_BIRTH_LABEL = 'CHECKOUT_BUYER_INFORMATION_PERSONAL_FORM_DATE_OF_BIRTH_LABEL',
  CHECKOUT_BUYER_INFORMATION_PERSONAL_FORM_LICENCE_ADDRESS_LABEL = 'CHECKOUT_BUYER_INFORMATION_PERSONAL_FORM_LICENCE_ADDRESS_LABEL',
  CHECKOUT_BUYER_INFORMATION_PERSONAL_FORM_STATE_OF_ISSUE_LABEL = 'CHECKOUT_BUYER_INFORMATION_PERSONAL_FORM_STATE_OF_ISSUE_LABEL',
  CHECKOUT_BUYER_INFORMATION_PERSONAL_FORM_LICENCE_NUMBER_LABEL = 'CHECKOUT_BUYER_INFORMATION_PERSONAL_FORM_LICENCE_NUMBER_LABEL',
  CHECKOUT_BUYER_INFORMATION_PERSONAL_FORM_LICENCE_EXPIRY_LABEL = 'CHECKOUT_BUYER_INFORMATION_PERSONAL_FORM_LICENCE_EXPIRY_LABEL',
  CHECKOUT_BUYER_INFORMATION_PERSONAL_FORM_LOCATION_DESCRIPTION = 'CHECKOUT_BUYER_INFORMATION_PERSONAL_FORM_LOCATION_DESCRIPTION',
  CHECKOUT_BUYER_INFORMATION_BUSINESS_FORM_BUSINESS_INFORMATION_HEADER = 'CHECKOUT_BUYER_INFORMATION_BUSINESS_FORM_BUSINESS_INFORMATION_HEADER',
  CHECKOUT_BUYER_INFORMATION_BUSINESS_FORM_LICENCE_INFORMATION_HEADER = 'CHECKOUT_BUYER_INFORMATION_BUSINESS_FORM_LICENCE_INFORMATION_HEADER',
  CHECKOUT_BUYER_INFORMATION_BUSINESS_FORM_DESCRIPTION = 'CHECKOUT_BUYER_INFORMATION_BUSINESS_FORM_DESCRIPTION',
  CHECKOUT_BUYER_INFORMATION_BUSINESS_FORM_BUTTON = 'CHECKOUT_BUYER_INFORMATION_BUSINESS_FORM_BUTTON',
  CHECKOUT_BUYER_INFORMATION_BUSINESS_FORM_COMPANY_NAME_LABEL = 'CHECKOUT_BUYER_INFORMATION_BUSINESS_FORM_COMPANY_NAME_LABEL',
  CHECKOUT_BUYER_INFORMATION_BUSINESS_FORM_ABN_LABEL = 'CHECKOUT_BUYER_INFORMATION_BUSINESS_FORM_ABN_LABEL',
  CHECKOUT_BUYER_INFORMATION_BUSINESS_FORM_TITLE_LABEL = 'CHECKOUT_BUYER_INFORMATION_BUSINESS_FORM_TITLE_LABEL',
  CHECKOUT_BUYER_INFORMATION_BUSINESS_FORM_FIRST_NAME_LABEL = 'CHECKOUT_BUYER_INFORMATION_BUSINESS_FORM_FIRST_NAME_LABEL',
  CHECKOUT_BUYER_INFORMATION_BUSINESS_FORM_MIDDLE_NAME_LABEL = 'CHECKOUT_BUYER_INFORMATION_BUSINESS_FORM_MIDDLE_NAME_LABEL',
  CHECKOUT_BUYER_INFORMATION_BUSINESS_FORM_LAST_NAME_LABEL = 'CHECKOUT_BUYER_INFORMATION_BUSINESS_FORM_LAST_NAME_LABEL',
  CHECKOUT_BUYER_INFORMATION_BUSINESS_FORM_DATE_OF_BIRTH_LABEL = 'CHECKOUT_BUYER_INFORMATION_BUSINESS_FORM_DATE_OF_BIRTH_LABEL',
  CHECKOUT_BUYER_INFORMATION_BUSINESS_FORM_LICENCE_ADDRESS_LABEL = 'CHECKOUT_BUYER_INFORMATION_BUSINESS_FORM_LICENCE_ADDRESS_LABEL',
  CHECKOUT_BUYER_INFORMATION_BUSINESS_FORM_STATE_OF_ISSUE_LABEL = 'CHECKOUT_BUYER_INFORMATION_BUSINESS_FORM_STATE_OF_ISSUE_LABEL',
  CHECKOUT_BUYER_INFORMATION_BUSINESS_FORM_LICENCE_NUMBER_LABEL = 'CHECKOUT_BUYER_INFORMATION_BUSINESS_FORM_LICENCE_NUMBER_LABEL',
  CHECKOUT_BUYER_INFORMATION_BUSINESS_FORM_LICENCE_EXPIRY_LABEL = 'CHECKOUT_BUYER_INFORMATION_BUSINESS_FORM_LICENCE_EXPIRY_LABEL',
  CHECKOUT_BUYER_INFORMATION_BUSINESS_FORM_LOCATION_DESCRIPTION = 'CHECKOUT_BUYER_INFORMATION_BUSINESS_FORM_LOCATION_DESCRIPTION',
  CHECKOUT_DELIVERY_OPTION_FORM_DESCRIPTION = 'CHECKOUT_DELIVERY_OPTION_FORM_DESCRIPTION',
  CHECKOUT_DELIVERY_OPTION_FORM_CTA = 'CHECKOUT_DELIVERY_OPTION_FORM_CTA',
  CHECKOUT_DELIVERY_OPTION_FORM_ADDRESS_TITLE = 'CHECKOUT_DELIVERY_OPTION_FORM_ADDRESS_TITLE',
  CHECKOUT_DELIVERY_OPTION_FORM_DELIVERY_LABEL = 'CHECKOUT_DELIVERY_OPTION_FORM_DELIVERY_LABEL',
  CHECKOUT_DELIVERY_OPTION_FORM_COLLECTION_LABEL = 'CHECKOUT_DELIVERY_OPTION_FORM_COLLECTION_LABEL',
  CHECKOUT_DELIVERY_OPTION_FORM_ADDITIONAL_INFORMATION_DELIVERY_TITLE = 'CHECKOUT_DELIVERY_OPTION_FORM_ADDITIONAL_INFORMATION_DELIVERY_TITLE',
  CHECKOUT_DELIVERY_OPTION_FORM_ADDITIONAL_INFORMATION_COLLECTION_TITLE = 'CHECKOUT_DELIVERY_OPTION_FORM_ADDITIONAL_INFORMATION_COLLECTION_TITLE',
  CHECKOUT_DELIVERY_OPTION_FORM_ADDITIONAL_INFORMATION_DELIVERY_DESCRIPTION = 'CHECKOUT_DELIVERY_OPTION_FORM_ADDITIONAL_INFORMATION_DELIVERY_DESCRIPTION',
  CHECKOUT_DELIVERY_OPTION_FORM_ADDITIONAL_INFORMATION_COLLECTION_DESCRIPTION = 'CHECKOUT_DELIVERY_OPTION_FORM_ADDITIONAL_INFORMATION_COLLECTION_DESCRIPTION',
  CHECKOUT_DELIVERY_LOADING_MESSAGE = 'CHECKOUT_DELIVERY_LOADING_MESSAGE',
  CHECKOUT_DELIVERY_OPTION_FORM_HELP = 'CHECKOUT_DELIVERY_OPTION_FORM_HELP',
  CHECKOUT_DELIVERY_OPTION_FORM_DELIVERABLE = 'CHECKOUT_DELIVERY_OPTION_FORM_DELIVERABLE',
  CHECKOUT_DELIVERY_OPTION_FORM_NOT_DELIVERABLE = 'CHECKOUT_DELIVERY_OPTION_FORM_NOT_DELIVERABLE',
  CHECKOUT_DELIVERY_OPTION_DELIVERY_ADDITIONAL_INFORMATION = 'CHECKOUT_DELIVERY_OPTION_DELIVERY_ADDITIONAL_INFORMATION',
  CHECKOUT_DELIVERY_OPTION_COLLECTION_ADDITIONAL_INFORMATION = 'CHECKOUT_DELIVERY_OPTION_COLLECTION_ADDITIONAL_INFORMATION',
  CHECKOUT_DELIVERY_OPTION_CARMA_COLLECT = 'CHECKOUT_DELIVERY_OPTION_CARMA_COLLECT',
  CHECKOUT_BUYER_INFORMATION_BUSINESS_FORM_LOCATION_CONTACT_US = 'CHECKOUT_BUYER_INFORMATION_BUSINESS_FORM_LOCATION_CONTACT_US',
  CHECKOUT_BUYER_INFORMATION_BUSINESS_FORM_LICENCE_NUMBER_SEE_EXAMPLE = 'CHECKOUT_BUYER_INFORMATION_BUSINESS_FORM_LICENCE_NUMBER_SEE_EXAMPLE',
  CHECKOUT_PAYMENT_SUMMARY_FORM_HEADER = 'CHECKOUT_PAYMENT_SUMMARY_FORM_HEADER',
  CHECKOUT_PAYMENT_SUMMARY_DUE_HEADER = 'CHECKOUT_PAYMENT_SUMMARY_DUE_HEADER',
  CHECKOUT_PAYMENT_SUMMARY_FORM_BUYER_INFORMATION_HEADER = 'CHECKOUT_PAYMENT_SUMMARY_FORM_BUYER_INFORMATION_HEADER',
  CHECKOUT_PAYMENT_SUMMARY_FORM_BUYER_INFORMATION_TITLE_1 = 'CHECKOUT_PAYMENT_SUMMARY_FORM_BUYER_INFORMATION_TITLE_1',
  CHECKOUT_PAYMENT_SUMMARY_FORM_BUYER_INFORMATION_TITLE_2 = 'CHECKOUT_PAYMENT_SUMMARY_FORM_BUYER_INFORMATION_TITLE_2',
  CHECKOUT_PAYMENT_SUMMARY_FORM_BUYER_INFORMATION_TITLE_3 = 'CHECKOUT_PAYMENT_SUMMARY_FORM_BUYER_INFORMATION_TITLE_3',
  CHECKOUT_PAYMENT_SUMMARY_FORM_BUYER_INFORMATION_TITLE_4 = 'CHECKOUT_PAYMENT_SUMMARY_FORM_BUYER_INFORMATION_TITLE_4',
  CHECKOUT_PAYMENT_SUMMARY_FORM_DELIVERY_COLLECT_HEADER = 'CHECKOUT_PAYMENT_SUMMARY_FORM_DELIVERY_COLLECT_HEADER',
  CHECKOUT_PAYMENT_SUMMARY_FORM_DELIVERY_TITLE_1 = 'CHECKOUT_PAYMENT_SUMMARY_FORM_DELIVERY_TITLE_1',
  CHECKOUT_PAYMENT_SUMMARY_FORM_COLLECT_TITLE_1 = 'CHECKOUT_PAYMENT_SUMMARY_FORM_COLLECT_TITLE_1',
  CHECKOUT_PAYMENT_SUMMARY_FORM_DELIVERY_COLLECT_TITLE_1 = 'CHECKOUT_PAYMENT_SUMMARY_FORM_DELIVERY_COLLECT_TITLE_1',
  CHECKOUT_PAYMENT_SUMMARY_FORM_DELIVERY_COLLECT_TITLE_2 = 'CHECKOUT_PAYMENT_SUMMARY_FORM_DELIVERY_COLLECT_TITLE_2',
  CHECKOUT_PAYMENT_SUMMARY_FORM_TRADE_IN_HEADER = 'CHECKOUT_PAYMENT_SUMMARY_FORM_TRADE_IN_HEADER',
  CHECKOUT_PAYMENT_SUMMARY_FORM_TRADE_IN_TITLE_1 = 'CHECKOUT_PAYMENT_SUMMARY_FORM_TRADE_IN_TITLE_1',
  CHECKOUT_PAYMENT_SUMMARY_FORM_YES_TRADE_IN = 'CHECKOUT_PAYMENT_SUMMARY_FORM_YES_TRADE_IN',
  CHECKOUT_PAYMENT_SUMMARY_FORM_NO_TRADE_IN = 'CHECKOUT_PAYMENT_SUMMARY_FORM_NO_TRADE_IN ',
  CHECKOUT_PAYMENT_SUMMARY_FORM_PAYMENT_OPTION_HEADER = 'CHECKOUT_PAYMENT_SUMMARY_FORM_PAYMENT_OPTION_HEADER',
  CHECKOUT_PAYMENT_SUMMARY_FORM_PAYMENT_OPTION_TITLE_1 = 'CHECKOUT_PAYMENT_SUMMARY_FORM_PAYMENT_OPTION_TITLE_1',
  CHECKOUT_PAYMENT_SUMMARY_FORM_PAYMENT_OPTION_VALUE_1 = 'CHECKOUT_PAYMENT_SUMMARY_FORM_PAYMENT_OPTION_VALUE_1',
  CHECKOUT_PAYMENT_SUMMARY_FORM_PAYMENT_OPTION_PAYING_WITHOUT_FINANCE_TITLE_1 = 'CHECKOUT_PAYMENT_SUMMARY_FORM_PAYMENT_OPTION_PAYING_WITHOUT_FINANCE_TITLE_1',
  CHECKOUT_PAYMENT_SUMMARY_FORM_PAYMENT_OPTION_PAYING_WITHOUT_FINANCE_VALUE_1 = 'CHECKOUT_PAYMENT_SUMMARY_FORM_PAYMENT_OPTION_PAYING_WITHOUT_FINANCE_VALUE_1',
  CHECKOUT_PAYMENT_SUMMARY_FORM_INSTANT_FINANCE_QUOTE_TITLE_2 = 'CHECKOUT_PAYMENT_SUMMARY_FORM_INSTANT_FINANCE_QUOTE_TITLE_2',
  CHECKOUT_PAYMENT_SUMMARY_FORM_INSTANT_FINANCE_QUOTE_REPAYMENT = 'CHECKOUT_PAYMENT_SUMMARY_FORM_INSTANT_FINANCE_QUOTE_REPAYMENT',
  CHECKOUT_PAYMENT_SUMMARY_FORM_BYO_FINANCE_PAYMENT_OPTION_TITLE_2 = 'CHECKOUT_PAYMENT_SUMMARY_FORM_BYO_FINANCE_PAYMENT_OPTION_TITLE_2',
  CHECKOUT_PAYMENT_SUMMARY_FORM_BYO_FINANCE_PAYMENT_OPTION_VALUE_2 = 'CHECKOUT_PAYMENT_SUMMARY_FORM_BYO_FINANCE_PAYMENT_OPTION_VALUE_2',
  CHECKOUT_PAYMENT_SUMMARY_FORM_BYO_FINANCE_PAYMENT_OPTION_TITLE_3 = 'CHECKOUT_PAYMENT_SUMMARY_FORM_BYO_FINANCE_PAYMENT_OPTION_TITLE_3',
  CHECKOUT_PAYMENT_SUMMARY_FORM_BYO_FINANCE_PAYMENT_OPTION_TITLE_4 = 'CHECKOUT_PAYMENT_SUMMARY_FORM_BYO_FINANCE_PAYMENT_OPTION_TITLE_4',
  CHECKOUT_PAYMENT_SUMMARY_FORM_BYO_FINANCE_PAYMENT_OPTION_TITLE_5 = 'CHECKOUT_PAYMENT_SUMMARY_FORM_BYO_FINANCE_PAYMENT_OPTION_TITLE_5',
  CHECKOUT_PAYMENT_SUMMARY_FORM_INSTANT_FINANCE_QUOTE_TITLE_3 = 'CHECKOUT_PAYMENT_SUMMARY_FORM_INSTANT_FINANCE_QUOTE_TITLE_3',
  CHECKOUT_PAYMENT_SUMMARY_FORM_INSTANT_FINANCE_QUOTE_COULD_NOT_DECIDE = 'CHECKOUT_PAYMENT_SUMMARY_FORM_INSTANT_FINANCE_QUOTE_COULD_NOT_DECIDE',
  CHECKOUT_PAYMENT_SUMMARY_FORM_BILLING_ADDRESS_HEADER = 'CHECKOUT_PAYMENT_SUMMARY_FORM_BILLING_ADDRESS_HEADER',
  CHECKOUT_PAYMENT_SUMMARY_FORM_BILLING_ADDRESS_SWITCH_LABEL = 'CHECKOUT_PAYMENT_SUMMARY_FORM_BILLING_ADDRESS_SWITCH_LABEL',
  CHECKOUT_PAYMENT_SUMMARY_FORM_RESERVATION_PAYMENT_TITLE_DEPOSIT = 'CHECKOUT_PAYMENT_SUMMARY_FORM_RESERVATION_PAYMENT_TITLE_DEPOSIT',
  CHECKOUT_PAYMENT_SUMMARY_FORM_PAYMENT_PRICE_LABEL = 'CHECKOUT_PAYMENT_SUMMARY_FORM_PAYMENT_PRICE_LABEL',
  CHECKOUT_PAYMENT_SUMMARY_FORM_TRANSACTION_FEE_TITLE = 'CHECKOUT_PAYMENT_SUMMARY_FORM_TRANSACTION_FEE_TITLE',
  CHECKOUT_PAYMENT_SUMMARY_FORM_RESERVATION_PAYMENT_TITLE_2 = 'CHECKOUT_PAYMENT_SUMMARY_FORM_RESERVATION_PAYMENT_TITLE_2',
  CHECKOUT_PAYMENT_SUMMARY_FORM_PAYMENT_METHOD_RESERVATION = 'CHECKOUT_PAYMENT_SUMMARY_FORM_PAYMENT_METHOD_RESERVATION',
  CHECKOUT_PAYMENT_SUMMARY_FORM_PAYMENT_METHOD_PAY_FULL_AMOUNT = 'CHECKOUT_PAYMENT_SUMMARY_FORM_PAYMENT_METHOD_PAY_FULL_AMOUNT',
  CHECKOUT_PAYMENT_SUMMARY_FORM_EDIT_BUTTON = 'CHECKOUT_PAYMENT_SUMMARY_FORM_EDIT_BUTTON',
  CHECKOUT_PAYMENT_SUMMARY_PAYMENT_OPTION_BUTTON = 'CHECKOUT_PAYMENT_SUMMARY_PAYMENT_OPTION_BUTTON',
  CHECKOUT_PAYMENT_SUMMARY_PAYMENT_OPTION_MOBILE_BUTTON = 'CHECKOUT_PAYMENT_SUMMARY_PAYMENT_OPTION_MOBILE_BUTTON',
  CHECKOUT_PAYMENT_SUMMARY_FORM_VIEW_FULL_SUMMARY_BUTTON = 'CHECKOUT_PAYMENT_SUMMARY_FORM_VIEW_FULL_SUMMARY_BUTTON',
  CHECKOUT_PAYMENT_SUMMARY_FORM_SUBMIT_BUTTON = 'CHECKOUT_PAYMENT_SUMMARY_FORM_SUBMIT_BUTTON',
  CHECKOUT_PAYMENT_SUMMARY_FORM_PAYING_WITHOUT_FINANCE_FULL_AMOUNT_SUBMIT_BUTTON = 'CHECKOUT_PAYMENT_SUMMARY_FORM_PAYING_WITHOUT_FINANCE_FULL_AMOUNT_SUBMIT_BUTTON',
  CHECKOUT_PAYMENT_SUMMARY_FORM_MODAL_SUBMIT_LABEL = 'CHECKOUT_PAYMENT_SUMMARY_FORM_MODAL_SUBMIT_LABEL',
  CHECKOUT_PAYMENT_SUMMARY_FORM_MODAL_INSTANT_FINANCE_QUOTE_HEADER = 'CHECKOUT_PAYMENT_SUMMARY_FORM_MODAL_INSTANT_FINANCE_QUOTE_HEADER',
  CHECKOUT_PAYMENT_SUMMARY_FINANCE_QUOTES_NOTE = 'CHECKOUT_PAYMENT_SUMMARY_FINANCE_QUOTES_NOTE',
  CHECKOUT_DOC_MANAGEMENT_HEADER = 'CHECKOUT_DOC_MANAGEMENT_HEADER',
  CHECKOUT_DOC_MANAGEMENT_DESCRIPTION = 'CHECKOUT_DOC_MANAGEMENT_DESCRIPTION',
  CHECKOUT_DOC_MANAGEMENT_TAX_INVOICE_HEADER = 'CHECKOUT_DOC_MANAGEMENT_TAX_INVOICE_HEADER',
  CHECKOUT_DOC_MANAGEMENT_FORM_5_HEADER = 'CHECKOUT_DOC_MANAGEMENT_FORM_5_HEADER',
  CHECKOUT_DOC_MANAGEMENT_TAX_INVOICE_CONTENT = 'CHECKOUT_DOC_MANAGEMENT_TAX_INVOICE_CONTENT',
  CHECKOUT_DOC_MANAGEMENT_FORM_5_CONTENT = 'CHECKOUT_DOC_MANAGEMENT_FORM_5_CONTENT',
  CHECKOUT_DOC_MANAGEMENT_ABLE_DOWNLOAD_CONTENT = 'CHECKOUT_DOC_MANAGEMENT_ABLE_DOWNLOAD_CONTENT',
  CHECKOUT_DOC_MANAGEMENT_ABLE_DOWNLOAD_BUTTON_LABEL = 'CHECKOUT_DOC_MANAGEMENT_ABLE_DOWNLOAD_BUTTON_LABEL',
  CHECKOUT_QUOTE_CARD_SUBMIT_QUOTE_LABEL = 'CHECKOUT_QUOTE_CARD_SUBMIT_QUOTE_LABEL',
  CHECKOUT_QUOTE_CARD_QUOTE_DETAILS_TITLE = 'CHECKOUT_QUOTE_CARD_QUOTE_DETAILS_TITLE',
  CHECKOUT_QUOTE_CARD_REPAYMENT_AMOUNT_TITLE = 'CHECKOUT_QUOTE_CARD_REPAYMENT_AMOUNT_TITLE',
  CHECKOUT_QUOTE_CARD_LOAN_AMOUNT_LABEL = 'CHECKOUT_QUOTE_CARD_LOAN_AMOUNT_LABEL',
  CHECKOUT_QUOTE_CARD_DEPOSIT_LABEL = 'CHECKOUT_QUOTE_CARD_DEPOSIT_LABEL',
  CHECKOUT_QUOTE_CARD_TERM_LABEL = 'CHECKOUT_QUOTE_CARD_TERM_LABEL',
  CHECKOUT_QUOTE_CARD_WEEKLY_LABEL = 'CHECKOUT_QUOTE_CARD_WEEKLY_LABEL',
  CHECKOUT_QUOTE_CARD_INTEREST_RATE_LABEL = 'CHECKOUT_QUOTE_CARD_INTEREST_RATE_LABEL',
  CHECKOUT_QUOTE_CARD_FEE_BREAKDOWN_LABEL = 'CHECKOUT_QUOTE_CARD_FEE_BREAKDOWN_LABEL',
  CHECKOUT_QUOTE_CARD_CHECKOUT_BUTTON_LABEL = 'CHECKOUT_QUOTE_CARD_CHECKOUT_BUTTON_LABEL',
  CHECKOUT_QUOTE_CARD_BUILD_YEAR_TITLE = 'CHECKOUT_QUOTE_CARD_BUILD_YEAR_TITLE',
  CHECKOUT_QUOTE_CARD_BUILD_YEAR_TEXT = 'CHECKOUT_QUOTE_CARD_BUILD_YEAR_TEXT',
  CHECKOUT_QUOTE_CARD_GET_PRE_APPROVED_BUTTON_LABEL = 'CHECKOUT_QUOTE_CARD_GET_PRE_APPROVED_BUTTON_LABEL',
  CHECKOUT_QUOTE_CARD_BROWSE_CARS_BUTTON_LABEL = 'CHECKOUT_QUOTE_CARD_BROWSE_CARS_BUTTON_LABEL',
  CHECKOUT_QUOTE_CARD_DISCOUNT_OFFER_LABEL = 'CHECKOUT_QUOTE_CARD_DISCOUNT_OFFER_LABEL',
  CHECKOUT_QUOTE_CARD_FINANCE_PRE_APPROVAL_GET_PRE_APPROVED_BUTTON_LABEL = 'CHECKOUT_QUOTE_CARD_FINANCE_PRE_APPROVAL_GET_PRE_APPROVED_BUTTON_LABEL',
  CHECKOUT_QUOTE_CARD_FINANCE_PRE_APPROVAL_GET_PRE_APPROVED_CONFIRMATION_LABEL = 'CHECKOUT_QUOTE_CARD_FINANCE_PRE_APPROVAL_GET_PRE_APPROVED_CONFIRMATION_LABEL',
  CHECKOUT_QUOTE_CARD_FINANCE_PRE_APPROVAL_MODAL_TITLE = 'CHECKOUT_QUOTE_CARD_FINANCE_PRE_APPROVAL_MODAL_TITLE',
  CHECKOUT_QUOTE_CARD_FINANCE_PRE_APPROVAL_MODAL_DESCRIPTION = 'CHECKOUT_QUOTE_CARD_FINANCE_PRE_APPROVAL_MODAL_DESCRIPTION',
  CHECKOUT_QUOTE_CARD_FINANCE_PRE_APPROVAL_MODAL_CTA_LABEL = 'CHECKOUT_QUOTE_CARD_FINANCE_PRE_APPROVAL_MODAL_CTA_LABEL',
  CHECKOUT_VIEW_PURCHASE_SUMMARY_BUTTON_LABEL = 'CHECKOUT_VIEW_PURCHASE_SUMMARY_BUTTON_LABEL',
  CHECKOUT_GET_STARTED_HEADER = 'CHECKOUT_GET_STARTED_HEADER',
  CHECKOUT_GET_STARTED_DESCRIPTION = 'CHECKOUT_GET_STARTED_DESCRIPTION',
  CHECKOUT_GET_STARTED_OPTION_1_TITLE = 'CHECKOUT_GET_STARTED_OPTION_1_TITLE',
  CHECKOUT_GET_STARTED_OPTION_1_DESCRIPTION = 'CHECKOUT_GET_STARTED_OPTION_1_DESCRIPTION',
  CHECKOUT_GET_STARTED_OPTION_2_TITLE = 'CHECKOUT_GET_STARTED_OPTION_2_TITLE',
  CHECKOUT_GET_STARTED_OPTION_2_DESCRIPTION = 'CHECKOUT_GET_STARTED_OPTION_2_DESCRIPTION',
  CHECKOUT_GET_STARTED_INCLUDED_TEXT = 'CHECKOUT_GET_STARTED_INCLUDED_TEXT',
  CHECKOUT_GET_STARTED_FREE_TEXT = 'CHECKOUT_GET_STARTED_FREE_TEXT',
  CHECKOUT_GET_STARTED_GUARANTEE_TEXT = 'CHECKOUT_GET_STARTED_GUARANTEE_TEXT',
  CHECKOUT_GET_STARTED_WARRANTY_TEXT = 'CHECKOUT_GET_STARTED_WARRANTY_TEXT',
  CHECKOUT_GET_STARTED_NRMA_TEXT = 'CHECKOUT_GET_STARTED_NRMA_TEXT',
  CHECKOUT_GET_STARTED_DELIVERY_TEXT = 'CHECKOUT_GET_STARTED_DELIVERY_TEXT',
  CHECKOUT_GET_STARTED_DELIVERY_LINK = 'CHECKOUT_GET_STARTED_DELIVERY_LINK',
  CHECKOUT_GET_STARTED_SUBMIT_BUTTON_LABEL = 'CHECKOUT_GET_STARTED_SUBMIT_BUTTON_LABEL',
  CHECKOUT_GET_STARTED_HOLD_CAR_TEXT = 'CHECKOUT_GET_STARTED_HOLD_CAR_TEXT',
  CHECKOUT_GET_STARTED_HOLD_CAR_TIME = 'CHECKOUT_GET_STARTED_HOLD_CAR_TIME',
  CHECKOUT_GET_STARTED_VIEW_TERMS = 'CHECKOUT_GET_STARTED_VIEW_TERMS',
  CHECKOUT_GET_STARTED_TERM_MODAL_TITLE = 'CHECKOUT_GET_STARTED_TERM_MODAL_TITLE',
  CHECKOUT_GET_STARTED_TERM_MODAL_CONTENT = 'CHECKOUT_GET_STARTED_TERM_MODAL_CONTENT',
  CHECKOUT_GET_STARTED_CHAT_WIDGET_LABEL = 'CHECKOUT_GET_STARTED_CHAT_WIDGET_LABEL',
  CHECKOUT_CHANGE_PAYMENT_TITLE = 'CHECKOUT_CHANGE_PAYMENT_TITLE',
  CHECKOUT_CHANGE_PAYMENT_DESCRIPTION = 'CHECKOUT_CHANGE_PAYMENT_DESCRIPTION',
  CHECKOUT_CHANGE_PAYMENT_BACK_BUTTON_LABEL = 'CHECKOUT_CHANGE_PAYMENT_BACK_BUTTON_LABEL',
  CHECKOUT_CHANGE_PAYMENT_CONTINUE_BUTTON_LABEL = 'CHECKOUT_CHANGE_PAYMENT_CONTINUE_BUTTON_LABEL',
  CHECKOUT_CHANGE_PAYMENT_OPTION_LABEL = 'CHECKOUT_CHANGE_PAYMENT_OPTION_LABEL',
}
