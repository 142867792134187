export enum authenticationMicrocopy {
  AUTHENTICATION_SIGNUP_TITLE = 'AUTHENTICATION_SIGNUP_TITLE',
  AUTHENTICATION_SIGNUP_CAPTION = 'AUTHENTICATION_SIGNUP_CAPTION',
  AUTHENTICATION_SIGNUP_WATCHLIST_TITLE = 'AUTHENTICATION_SIGNUP_WATCHLIST_TITLE',
  AUTHENTICATION_SIGNUP_WATCHLIST_CAPTION = 'AUTHENTICATION_SIGNUP_WATCHLIST_CAPTION',
  AUTHENTICATION_SIGNUP_CAR_ALERTS_TITLE = 'AUTHENTICATION_SIGNUP_CAR_ALERTS_TITLE',
  AUTHENTICATION_SIGNUP_CAR_ALERTS_CAPTION = 'AUTHENTICATION_SIGNUP_CAR_ALERTS_CAPTION',
  AUTHENTICATION_SIGNUP_CHECKOUT_TITLE = 'AUTHENTICATION_SIGNUP_CHECKOUT_TITLE',
  AUTHENTICATION_SIGNUP_CHECKOUT_CAPTION = 'AUTHENTICATION_SIGNUP_CHECKOUT_CAPTION',
  AUTHENTICATION_SIGNUP_CARMATCH_TITLE = 'AUTHENTICATION_SIGNUP_CARMATCH_TITLE',
  AUTHENTICATION_SIGNUP_CARMATCH_CAPTION = 'AUTHENTICATION_SIGNUP_CARMATCH_CAPTION',
  AUTHENTICATION_SIGNUP_CHECKOUT_SHORT_CAPTION = 'AUTHENTICATION_SIGNUP_CHECKOUT_SHORT_CAPTION',
  AUTHENTICATION_SIGNUP_LOGIN_FACEBOOK_LABEL = 'AUTHENTICATION_SIGNUP_LOGIN_FACEBOOK_LABEL',
  AUTHENTICATION_SIGNUP_LOGIN_GOOGLE_LABEL = 'AUTHENTICATION_SIGNUP_LOGIN_GOOGLE_LABEL',
  AUTHENTICATION_SIGNUP_CAR_ALERTS_DESCRIPTION = 'AUTHENTICATION_SIGNUP_CAR_ALERTS_DESCRIPTION',
  AUTHENTICATION_RESEND_PASSWORD_FORM_TITLE = 'AUTHENTICATION_RESEND_PASSWORD_FORM_TITLE',
  AUTHENTICATION_RESEND_PASSWORD_FORM_CAPTION = 'AUTHENTICATION_RESEND_PASSWORD_FORM_CAPTION',
  AUTHENTICATION_RESET_PASSWORD_FORM_TITLE = 'AUTHENTICATION_RESET_PASSWORD_FORM_TITLE',
  AUTHENTICATION_RESET_PASSWORD_FORM_CAPTION = 'AUTHENTICATION_RESET_PASSWORD_FORM_CAPTION',
  AUTHENTICATION_NEW_PASSWORD_FORM_TITLE = 'AUTHENTICATION_NEW_PASSWORD_FORM_TITLE',
  AUTHENTICATION_SIGNIN_FORM_TITLE = 'AUTHENTICATION_SIGNIN_FORM_TITLE',
  AUTHENTICATION_SIGNIN_FORM_CAPTION = 'AUTHENTICATION_SIGNIN_FORM_CAPTION',
  AUTHENTICATION_SIGNUP_PERSONAL_DETAILS_FORM_TITLE = 'AUTHENTICATION_SIGNUP_PERSONAL_DETAILS_FORM_TITLE',
  AUTHENTICATION_SIGNUP_PERSONAL_DETAILS_FORM_CAPTION = 'AUTHENTICATION_SIGNUP_PERSONAL_DETAILS_FORM_CAPTION',
  AUTHENTICATION_VERIFICATION_FORM_TITLE = 'AUTHENTICATION_VERIFICATION_FORM_TITLE',
  AUTHENTICATION_VERIFICATION_FORM_CAPTION = 'AUTHENTICATION_VERIFICATION_FORM_CAPTION',
  AUTHENTICATION_SIGNIN_FOOTER_DEFAULT_1 = 'AUTHENTICATION_SIGNIN_FOOTER_DEFAULT_1',
  AUTHENTICATION_SIGNIN_FOOTER_DEFAULT_2 = 'AUTHENTICATION_SIGNIN_FOOTER_DEFAULT_2',
  AUTHENTICATION_SIGNIN_FOOTER_DEFAULT_3 = 'AUTHENTICATION_SIGNIN_FOOTER_DEFAULT_3',
  AUTHENTICATION_SIGNIN_FOOTER_NAVBAR_1 = 'AUTHENTICATION_SIGNIN_FOOTER_NAVBAR_1',
  AUTHENTICATION_SIGNIN_FOOTER_NAVBAR_2 = 'AUTHENTICATION_SIGNIN_FOOTER_NAVBAR_2',
  AUTHENTICATION_SIGNIN_FOOTER_NAVBAR_3 = 'AUTHENTICATION_SIGNIN_FOOTER_NAVBAR_3',
  AUTHENTICATION_SIGNIN_FOOTER_PDP_CTA_1 = 'AUTHENTICATION_SIGNIN_FOOTER_PDP_CTA_1',
  AUTHENTICATION_SIGNIN_FOOTER_PDP_CTA_2 = 'AUTHENTICATION_SIGNIN_FOOTER_PDP_CTA_2',
  AUTHENTICATION_SIGNIN_FOOTER_PDP_CTA_3 = 'AUTHENTICATION_SIGNIN_FOOTER_PDP_CTA_3',
  AUTHENTICATION_SIGNIN_FOOTER_WATCHLIST_1 = 'AUTHENTICATION_SIGNIN_FOOTER_WATCHLIST_1',
  AUTHENTICATION_SIGNIN_FOOTER_WATCHLIST_2 = 'AUTHENTICATION_SIGNIN_FOOTER_WATCHLIST_2',
  AUTHENTICATION_SIGNIN_FOOTER_WATCHLIST_3 = 'AUTHENTICATION_SIGNIN_FOOTER_WATCHLIST_3',
  AUTHENTICATION_SIGNIN_FOOTER_CAR_ALERTS_1 = 'AUTHENTICATION_SIGNIN_FOOTER_CAR_ALERTS_1',
  AUTHENTICATION_SIGNIN_FOOTER_CAR_ALERTS_2 = 'AUTHENTICATION_SIGNIN_FOOTER_CAR_ALERTS_2',
  AUTHENTICATION_SIGNIN_FOOTER_CAR_ALERTS_3 = 'AUTHENTICATION_SIGNIN_FOOTER_CAR_ALERTS_3',
  AUTHENTICATION_SIGNIN_FOOTER_PERSONALISED_QUOTE_1 = 'AUTHENTICATION_SIGNIN_FOOTER_PERSONALISED_QUOTE_1',
  AUTHENTICATION_SIGNIN_FOOTER_PERSONALISED_QUOTE_2 = 'AUTHENTICATION_SIGNIN_FOOTER_PERSONALISED_QUOTE_2',
  AUTHENTICATION_SIGNIN_FOOTER_PERSONALISED_QUOTE_3 = 'AUTHENTICATION_SIGNIN_FOOTER_PERSONALISED_QUOTE_3',
  AUTHENTICATION_SIGNIN_FOOTER_CARMATCH_1 = 'AUTHENTICATION_SIGNIN_FOOTER_CARMATCH_1',
  AUTHENTICATION_SIGNIN_FOOTER_CARMATCH_2 = 'AUTHENTICATION_SIGNIN_FOOTER_CARMATCH_2',
  AUTHENTICATION_SIGNIN_FOOTER_CARMATCH_3 = 'AUTHENTICATION_SIGNIN_FOOTER_CARMATCH_3',
  AUTHENTICATION_SIGNUP_SUCCESS_CONTENT = 'AUTHENTICATION_SIGNUP_SUCCESS_CONTENT',
  AUTHENTICATION_SIGNUP_SUCCESS_DEFAULT_FOOTER = 'AUTHENTICATION_SIGNUP_SUCCESS_DEFAULT_FOOTER',
  AUTHENTICATION_SIGNUP_SUCCESS_WATCHLIST_FOOTER = 'AUTHENTICATION_SIGNUP_SUCCESS_WATCHLIST_FOOTER',
  AUTHENTICATION_SIGNUP_SUCCESS_FOOTER_BUTTON = 'AUTHENTICATION_SIGNUP_SUCCESS_FOOTER_BUTTON',
  AUTHENTICATION_SIGNUP_PRIVACY_AND_TERMS = 'AUTHENTICATION_SIGNUP_PRIVACY_AND_TERMS',
  AUTHENTICATION_SIGNUP_PERSONALISED_QUOTE_TITLE = 'AUTHENTICATION_SIGNUP_PERSONALISED_QUOTE_TITLE',
  AUTHENTICATION_SIGNUP_PERSONALISED_QUOTE_CAPTION = 'AUTHENTICATION_SIGNUP_PERSONALISED_QUOTE_CAPTION',
  AUTHENTICATION_SIGNUP_SUCCESS_CHECKOUT = 'AUTHENTICATION_SIGNUP_SUCCESS_CHECKOUT',
  AUTHENTICATION_SIGNUP_SUCCESS_NAVBAR = 'AUTHENTICATION_SIGNUP_SUCCESS_NAVBAR',
  AUTHENTICATION_SIGNUP_SUCCESS_WATCHLIST = 'AUTHENTICATION_SIGNUP_SUCCESS_WATCHLIST',
  AUTHENTICATION_SIGNUP_SUCCESS_CAR_ALERTS = 'AUTHENTICATION_SIGNUP_SUCCESS_CAR_ALERTS',
  AUTHENTICATION_SIGNUP_SUCCESS_PERSONALISED_QUOTE = 'AUTHENTICATION_SIGNUP_SUCCESS_PERSONALISED_QUOTE',
  AUTHENTICATION_SIGNUP_SUCCESS_BUTTON = 'AUTHENTICATION_SIGNUP_SUCCESS_BUTTON',
  AUTHENTICATION_SIGNUP_CONFIRM_YOUR_DETAILS_TITLE = 'AUTHENTICATION_SIGNUP_CONFIRM_YOUR_DETAILS_TITLE',
  AUTHENTICATION_SIGNUP_CONFIRM_YOUR_DETAILS_SUBTITLE = 'AUTHENTICATION_SIGNUP_CONFIRM_YOUR_DETAILS_SUBTITLE',
  VERIFY_PHONE_NUMBER_FORM_PHONE_TITLE = 'VERIFY_PHONE_NUMBER_FORM_PHONE_TITLE',
  VERIFY_PHONE_NUMBER_FORM_PHONE_SUBTITLE = 'VERIFY_PHONE_NUMBER_FORM_PHONE_SUBTITLE',
  VERIFY_PHONE_NUMBER_FORM_VERIFY_TITLE = 'VERIFY_PHONE_NUMBER_FORM_VERIFY_TITLE',
  VERIFY_PHONE_NUMBER_FORM_VERIFY_PHONE_SUBTITLE = 'VERIFY_PHONE_NUMBER_FORM_VERIFY_PHONE_SUBTITLE',
}
