import { PageProductListingFilters } from 'src/data/Contentful/ContentfulGetPageProductListing.types';
import { RecentFilterSearch } from 'src/hygiene/components/flexible-content/RecentSearch/RecentSearch.type';
import { getPlpUrlFromStoreFilters } from 'src/utils/plp/getPlpUrlFromStoreFilters';

const MAX_RECENT_FILTERS = 3;

export const removeFilterFromFilters = (filters: RecentFilterSearch[], filter: RecentFilterSearch) => {
  return filters.filter((item) => item.id !== filter.id);
};

const formatRecentSearchFilter = (filters: PageProductListingFilters): RecentFilterSearch | null => {
  if (Object.keys(filters).length === 0) {
    return null;
  }
  const partialFilters: PageProductListingFilters = {
    makeModel: filters.makeModel,
    bodyType: filters.bodyType,
    priceCashMin: filters.priceCashMin,
    priceCashMax: filters.priceCashMax,
    priceFinMin: filters.priceFinMin,
    priceFinMax: filters.priceFinMax,
  };

  const url = getPlpUrlFromStoreFilters(partialFilters);

  // Don't store the recent search if the search params have been filtered out
  if (url === '/used-cars') {
    return null;
  }

  const result: RecentFilterSearch = {
    id: url,
    url,
    filters: partialFilters,
  };

  return result;
};

export const addRecentSearchFilter = (
  filters: PageProductListingFilters,
  recentSearches: RecentFilterSearch[],
): { updated: boolean; recentSearches: RecentFilterSearch[] | null } => {
  let updated: boolean = false;
  let newRecentSearches = [...recentSearches];
  const newRecentSearch = formatRecentSearchFilter(filters);
  if (!newRecentSearch || filters.search) {
    return { updated: false, recentSearches };
  }

  if (newRecentSearches.length > 0) {
    updated = newRecentSearches[0].id !== newRecentSearch.id;
  } else {
    updated = true;
  }

  if (updated) {
    newRecentSearches = removeFilterFromFilters(newRecentSearches, newRecentSearch);
    newRecentSearches.unshift(newRecentSearch);
  }

  return {
    updated,
    recentSearches: newRecentSearches.slice(0, MAX_RECENT_FILTERS),
  };
};
