/**
 * Microcopy keys
 *
 * Convention regarding microcopy key naming:
 * - Always use snakecase in uppercase: `MY_MICROCOPY_NAME`
 * - Name should start from the most generic attribute to the least.
 *   An example of this naming structure can be: `{PILLAR}_{COMPONENT}_{ATOM}`
 *   or `{PILLAR}_{PAGE}_{COMPONENT}_{ATOM}`
 * - In case your microcopy supports markdown or dynamic tokens,
 *   please add some documentation associated with your key
 */

import { accountMicrocopy } from './account';
import { authenticationMicrocopy } from './authentication';
import { carMatchMicrocopy } from './carMatch';
import { checkoutMicrocopy } from './checkout';
import { contentHubMicrocopy } from './contentHub';
import { customerReviewMicrocopy } from './customerReview';
import { deliveryMicrocopy } from './delivery';
import { generalMicrocopy } from './general';
import { hygieneMicrocopy } from './hygiene';
import { magicMicrocopy } from './magic';
import { pdpMicrocopy } from './pdp';
import { personalizedQuotesMicrocopy } from './personalizedQuotes';
import { plpMicrocopy } from './plp';
import { promotionMicrocopy } from './promotion';
import { tradeInMicrocopy } from './tradeIn';

export type MicrocopyKey =
  | generalMicrocopy
  | accountMicrocopy
  | authenticationMicrocopy
  | carMatchMicrocopy
  | checkoutMicrocopy
  | contentHubMicrocopy
  | customerReviewMicrocopy
  | deliveryMicrocopy
  | personalizedQuotesMicrocopy
  | plpMicrocopy
  | pdpMicrocopy
  | promotionMicrocopy
  | tradeInMicrocopy
  | hygieneMicrocopy
  | magicMicrocopy;

export const MICROCOPY = {
  ...generalMicrocopy,
  ...accountMicrocopy,
  ...authenticationMicrocopy,
  ...carMatchMicrocopy,
  ...checkoutMicrocopy,
  ...contentHubMicrocopy,
  ...customerReviewMicrocopy,
  ...deliveryMicrocopy,
  ...personalizedQuotesMicrocopy,
  ...plpMicrocopy,
  ...pdpMicrocopy,
  ...promotionMicrocopy,
  ...tradeInMicrocopy,
  ...hygieneMicrocopy,
  ...magicMicrocopy,
};
