export enum tradeInMicrocopy {
  TRADE_IN_CAR_FOUND_TITLE = 'TRADE_IN_CAR_FOUND_TITLE',
  TRADE_IN_CAR_FOUND_DESCRIPTION = 'TRADE_IN_CAR_FOUND_DESCRIPTION',
  TRADE_IN_CAR_FOUND_HINT = 'TRADE_IN_CAR_DETAILS_HINT',
  TRADE_IN_CAR_FOUND_FIELD_DETAILS_CORRECT = 'HYGIENE_TRADE_IN_ENQUIRY_SHOW_CAR_DETAILS_FIELD_DETAILS_CORRECT',
  TRADE_IN_CAR_NOT_FOUND_TITLE = 'TRADE_IN_CAR_NOT_FOUND_TITLE',
  TRADE_IN_EDIT_CAR_DETAILS_TITLE = 'TRADE_IN_EDIT_CAR_DETAILS_TITLE',
  TRADE_IN_PERSONAL_INFO_TITLE = 'TRADE_IN_PERSONAL_INFO_TITLE',
  TRADE_IN_PERSONAL_INFO_DESCRIPTION = 'TRADE_IN_PERSONAL_INFO_DESCRIPTION',
  TRADE_IN_INTRO_TITLE = 'TRADE_IN_INTRO_TITLE',
  TRADE_IN_INTRO_CONTENT = 'TRADE_IN_INTRO_CONTENT',
  TRADE_IN_BASIC_INFO_TITLE = 'TRADE_IN_BASIC_INFO_TITLE',
  TRADE_IN_TIMELINE_TITLE = 'TRADE_IN_TIMELINE_TITLE',
  TRADE_IN_TYRE_WEAR_TITLE = 'TRADE_IN_TYRE_WEAR_TITLE',
  TRADE_IN_VALUATION_TITLE = 'TRADE_IN_VALUATION_TITLE',
  TRADE_IN_EXTERIOR_TITLE = 'TRADE_IN_EXTERIOR_TITLE',
  TRADE_IN_EXTERIOR_FIELD_LABEL = 'TRADE_IN_EXTERIOR_FIELD_LABEL',
  TRADE_IN_EXTERIOR_FIELD_SUBLABEL = 'TRADE_IN_EXTERIOR_FIELD_SUBLABEL',
  TRADE_IN_EXTERIOR_HAIL_TITLE = 'TRADE_IN_EXTERIOR_HAIL_TITLE',
  TRADE_IN_EXTERIOR_HAIL_LABEL = 'TRADE_IN_EXTERIOR_HAIL_LABEL',
  TRADE_IN_EXTERIOR_EXTERIOR_DAMAGE_TITLE = 'TRADE_IN_EXTERIOR_EXTERIOR_DAMAGE_TITLE',
  TRADE_IN_EXTERIOR_MINOR_DAMAGE_LABEL = 'TRADE_IN_EXTERIOR_MINOR_DAMAGE_LABEL',
  TRADE_IN_EXTERIOR_MINOR_DAMAGE_SUBLABEL = 'TRADE_IN_EXTERIOR_MINOR_DAMAGE_SUBLABEL',
  TRADE_IN_EXTERIOR_MAJOR_DAMAGE_LABEL = 'TRADE_IN_EXTERIOR_MAJOR_DAMAGE_LABEL',
  TRADE_IN_EXTERIOR_MAJOR_DAMAGE_SUBLABEL = 'TRADE_IN_EXTERIOR_MAJOR_DAMAGE_SUBLABEL',
  TRADE_IN_EXTERIOR_WHEEL_DAMAGE_TITLE = 'TRADE_IN_EXTERIOR_WHEEL_DAMAGE_TITLE',
  TRADE_IN_EXTERIOR_WHEEL_DAMAGE_LABEL = 'TRADE_IN_EXTERIOR_WHEEL_DAMAGE_LABEL',
  TRADE_IN_EXTERIOR_WINDSCREEN_TITLE = 'TRADE_IN_EXTERIOR_WINDSCREEN_TITLE',
  TRADE_IN_EXTERIOR_WINDSCREEN_CRACKS_LABEL = 'TRADE_IN_EXTERIOR_WINDSCREEN_CRACKS_LABEL',
  TRADE_IN_EXTERIOR_WINDSCREEN_CHIPS_LABEL = 'TRADE_IN_EXTERIOR_WINDSCREEN_CHIPS_LABEL',
  TRADE_IN_INTERIOR_TITLE = 'TRADE_IN_INTERIOR_TITLE',
  TRADE_IN_INTERIOR_RIPS_TEARS_TITLE = 'TRADE_IN_INTERIOR_RIPS_TEARS_TITLE',
  TRADE_IN_INTERIOR_STAINS_TITLE = 'TRADE_IN_INTERIOR_STAINS_TITLE',
  TRADE_IN_INTERIOR_FIELD_LABEL = 'TRADE_IN_INTERIOR_FIELD_LABEL',
  TRADE_IN_INTERIOR_FIELD_SUBLABEL = 'TRADE_IN_INTERIOR_FIELD_SUBLABEL',
  TRADE_IN_INTERIOR_DAMAGE_TO_PANEL_OR_DASHBOARD = 'TRADE_IN_INTERIOR_DAMAGE_TO_PANEL_OR_DASHBOARD',
  TRADE_IN_INTERIOR_DIGITAL_SCREEN_TITLE = 'TRADE_IN_INTERIOR_DIGITAL_SCREEN_TITLE',
  TRADE_IN_INTERIOR_ODOUR_TITLE = 'TRADE_IN_INTERIOR_ODOUR_TITLE',
  TRADE_IN_MISSED_ANYTHING_TITLE = 'TRADE_IN_MISSED_ANYTHING_TITLE',
  TRADE_IN_MISSED_ANYTHING_SUBTITLE = 'TRADE_IN_MISSED_ANYTHING_SUBTITLE',
  TRADE_IN_FACTORY_OPTIONS_TITLE = 'TRADE_IN_FACTORY_OPTIONS_TITLE',
  TRADE_IN_FACTORY_OPTIONS_LABEL = 'TRADE_IN_FACTORY_OPTIONS_LABEL',
  TRADE_IN_FACTORY_OPTIONS_SUBLABEL = 'TRADE_IN_FACTORY_OPTIONS_SUBLABEL',
  TRADE_IN_APPLICABLE_OPTIONS_LABEL = 'TRADE_IN_APPLICABLE_OPTIONS_LABEL',
  TRADE_IN_APPLICABLE_OPTIONS_SUBLABEL = 'TRADE_IN_APPLICABLE_OPTIONS_SUBLABEL',
  TRADE_IN_OTHER_OPTIONAL_EXTRAS_LABEL = 'TRADE_IN_OTHER_OPTIONAL_EXTRAS_LABEL',
  TRADE_IN_MODIFICATIONS_TITLE = 'TRADE_IN_MODIFICATIONS_TITLE',
  TRADE_IN_MODIFICATIONS_RADIO_FIELD_LABEL = 'TRADE_IN_MODIFICATIONS_RADIO_FIELD_LABEL',
  TRADE_IN_MODIFICATIONS_RADIO_FIELD_SUBLABEL = 'TRADE_IN_MODIFICATIONS_RADIO_FIELD_SUBLABEL',
  TRADE_IN_MODIFICATIONS_TAG_FIELD_LABEL = 'TRADE_IN_MODIFICATIONS_TAG_FIELD_LABEL',
  TRADE_IN_SERVICE_HISTORY_TITLE = 'TRADE_IN_SERVICE_HISTORY_TITLE',
  TRADE_IN_SERVICE_HISTORY_LABEL = 'TRADE_IN_SERVICE_HISTORY_LABEL',
  TRADE_IN_SERVICE_HISTORY_SUBLABEL = 'TRADE_IN_SERVICE_HISTORY_SUBLABEL',
  TRADE_IN_SERVICE_COMPLETED_FRANCHISED_LABEL = 'TRADE_IN_SERVICE_COMPLETED_FRANCHISED_LABEL',
  TRADE_IN_SERVICE_COMPLETED_FRANCHISED_SUBLABEL = 'TRADE_IN_SERVICE_COMPLETED_FRANCHISED_SUBLABEL',
  TRADE_IN_SERVICE_DONE_BY_MANUFACTURER_LABEL = 'TRADE_IN_SERVICE_DONE_BY_MANUFACTURER_LABEL',
  TRADE_IN_SERVICE_DONE_BY_MANUFACTURER_SUBLABEL = 'TRADE_IN_SERVICE_DONE_BY_MANUFACTURER_SUBLABEL',
  TRADE_IN_SERVICE_MISSING_LABEL = 'TRADE_IN_SERVICE_MISSING_LABEL',
  TRADE_IN_CAR_HISTORY_TITLE = 'TRADE_IN_CAR_HISTORY_TITLE',
  TRADE_IN_CAR_HISTORY_FIRST_OWNER_LABEL = 'TRADE_IN_CAR_HISTORY_FIRST_OWNER_LABEL',
  TRADE_IN_CAR_HISTORY_HOW_MANY_KEY_LABEL = 'TRADE_IN_CAR_HISTORY_HOW_MANY_KEY_LABEL',
  TRADE_IN_CAR_HISTORY_IN_AN_ACCIDENT_LABEL = 'TRADE_IN_CAR_HISTORY_IN_AN_ACCIDENT_LABEL',
  TRADE_IN_PHOTOS_TITLE = 'TRADE_IN_PHOTOS_TITLE',
  TRADE_IN_PHOTOS_DESCRIPTION = 'TRADE_IN_PHOTOS_DESCRIPTION',
  TRADE_IN_CAR_PHOTOS_LABEL = 'TRADE_IN_CAR_PHOTOS_LABEL',
  TRADE_IN_OPTIONS_ACCESSORIES_PHOTOS_LABEL = 'TRADE_IN_OPTIONS_ACCESSORIES_PHOTOS_LABEL',
  TRADE_IN_QR_TITLE = 'TRADE_IN_QR_TITLE',
  TRADE_IN_QR_DESCRIPTION = 'TRADE_IN_QR_DESCRIPTION',
  TRADE_IN_EMAIL_LATER_TITLE = 'TRADE_EMAIL_LATER_TITLE',
  TRADE_IN_EMAIL_LATER_DESCRIPTION = 'TRADE_IN_EMAIL_LATER_DESCRIPTION',
  TRADE_IN_EMAIL_LATER_LINK_LABEL = 'TRADE_IN_EMAIL_LATER_LINK_LABEL',
  TRADE_IN_THANK_YOU_HEADER = 'TRADE_IN_THANK_YOU_HEADER',
  TRADE_IN_THANK_YOU_DESCRIPTION_NEXT = 'TRADE_IN_THANK_YOU_DESCRIPTION_NEXT',
  TRADE_IN_THANK_YOU_DESCRIPTION_MEANWHILE = 'TRADE_IN_THANK_YOU_DESCRIPTION_MEANWHILE',
}
