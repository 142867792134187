import React from 'react';
import { Slide, ToastContainer } from 'react-toastify';
import './toastify.css';

export default function ToasterContainer() {
  return (
    <ToastContainer
      position="bottom-left"
      closeButton={false}
      icon={false}
      transition={Slide}
      autoClose={3000}
      limit={1}
    />
  );
}
