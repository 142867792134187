export enum deliveryMicrocopy {
  DELIVERY_LOCATION_POPUP_TITLE_START = 'DELIVERY_LOCATION_POPUP_TITLE_START',
  DELIVERY_LOCATION_POPUP_ENTER_LOCATION_FIELD = 'DELIVERY_LOCATION_POPUP_ENTER_LOCATION_FIELD',
  DELIVERY_LOCATION_POPUP_ENTER_LOCATION_HELPER = 'DELIVERY_LOCATION_POPUP_ENTER_LOCATION_HELPER',
  DELIVERY_LOCATION_POPUP_ENTER_LOCATION_ERROR = 'DELIVERY_LOCATION_POPUP_ENTER_LOCATION_ERROR',
  DELIVERY_LOCATION_POPUP_TITLE_DELIVERY_COLLECTION = 'DELIVERY_LOCATION_POPUP_TITLE_DELIVERY_COLLECTION',
  DELIVERY_LOCATION_POPUP_DELIVERY_AVAILABLE_TITLE = 'DELIVERY_LOCATION_POPUP_DELIVERY_AVAILABLE_TITLE',
  DELIVERY_LOCATION_POPUP_DELIVERY_AVAILABLE_DESCRIPTION = 'DELIVERY_LOCATION_POPUP_DELIVERY_AVAILABLE_DESCRIPTION',
  DELIVERY_LOCATION_POPUP_NO_DELIVERY_TITLE = 'DELIVERY_LOCATION_POPUP_NO_DELIVERY_TITLE',
  DELIVERY_LOCATION_POPUP_NO_DELIVERY_DESCRIPTION = 'DELIVERY_LOCATION_POPUP_NO_DELIVERY_DESCRIPTION',
  DELIVERY_LOCATION_POPUP_NO_DELIVERY_ADD_EMAIL_FIELD = 'DELIVERY_LOCATION_POPUP_NO_DELIVERY_ADD_EMAIL_FIELD',
  DELIVERY_LOCATION_POPUP_NO_DELIVERY_ADD_EMAIL_ERROR = 'DELIVERY_LOCATION_POPUP_NO_DELIVERY_ADD_EMAIL_ERROR',
  DELIVERY_LOCATION_POPUP_NO_DELIVERY_ADD_EMAIL_BUTTON = 'DELIVERY_LOCATION_POPUP_NO_DELIVERY_ADD_EMAIL_BUTTON',
  DELIVERY_LOCATION_POPUP_NO_DELIVERY_ADD_EMAIL_SUCCESS = 'DELIVERY_LOCATION_POPUP_NO_DELIVERY_ADD_EMAIL_SUCCESS',
  DELIVERY_LOCATION_POPUP_COLLECTION_TITLE = 'DELIVERY_LOCATION_POPUP_COLLECTION_TITLE',
  DELIVERY_LOCATION_POPUP_COLLECTION_DESCRIPTION = 'DELIVERY_LOCATION_POPUP_COLLECTION_DESCRIPTION',
  DELIVERY_LOCATION_POPUP_FOOTER = 'DELIVERY_LOCATION_POPUP_FOOTER',
  DELIVERY_LOCATION_POPUP_CONFIRM = 'DELIVERY_LOCATION_POPUP_CONFIRM',
  DELIVERY_LOCATION_POPUP_OUT_OF_AREA_SUBTITLE = 'DELIVERY_LOCATION_POPUP_OUT_OF_AREA_SUBTITLE',
  DELIVERY_LOCATION_POPUP_OUT_OF_AREA_DESCRIPTION = 'DELIVERY_LOCATION_POPUP_OUT_OF_AREA_DESCRIPTION',
  DELIVERY_LOCATION_POPUP_OUT_OF_AREA_CALL_US_DESCRIPTION = 'DELIVERY_LOCATION_POPUP_OUT_OF_AREA_CALL_US_DESCRIPTION',
  DELIVERY_LOCATION_POPUP_EDIT_LOCATION = 'DELIVERY_LOCATION_POPUP_EDIT_LOCATION',
  DELIVERY_LOCATION_POPUP_PICKUP_LOCATION = 'DELIVERY_LOCATION_POPUP_PICKUP_LOCATION',
  DELIVERY_LOCATION_DIRECTIONS_BUTTON = 'DELIVERY_LOCATION_DIRECTIONS_BUTTON',
}
