'use client';

import { FC, useEffect } from 'react';

import { liveChatStore } from 'src/stores/liveChatStore';
import { routerHistoryStore } from 'src/stores/routerHistoryStore';

export const LiveChatManager: FC = () => {
  const { previousUrl } = routerHistoryStore();
  const { minimiseLiveChat } = liveChatStore((state) => ({
    minimiseLiveChat: state.minimiseLiveChat,
  }));

  useEffect(() => {
    minimiseLiveChat();
  }, [previousUrl]);

  return null;
};
