export enum promotionMicrocopy {
  PROMO_1 = 'PROMO_1',
  PROMO_1_TAB_LABEL = 'PROMO_1_TAB_LABEL',
  PROMO_1_CTA_LABEL = 'PROMO_1_CTA_LABEL',
  PROMO_1_URL_PARAM = 'PROMO_1_URL_PARAM',
  PROMO_1_TC = 'PROMO_1_TC',
  PROMO_1_INFO_DESCRIPTION = 'PROMO_1_INFO_DESCRIPTION',
  PROMO_1_INFO_LINK = 'PROMO_1_INFO_LINK',
  PROMO_1_INFO_CTA = 'PROMO_1_INFO_CTA',
  PROMO_2 = 'PROMO_2',
  PROMO_2_TAB_LABEL = 'PROMO_2_TAB_LABEL',
  PROMO_2_CTA_LABEL = 'PROMO_2_CTA_LABEL',
  PROMO_2_URL_PARAM = 'PROMO_2_URL_PARAM',
  PROMO_2_TC = 'PROMO_2_TC',
  PROMO_2_INFO_DESCRIPTION = 'PROMO_2_INFO_DESCRIPTION',
  PROMO_2_INFO_LINK = 'PROMO_2_INFO_LINK',
  PROMO_2_INFO_CTA = 'PROMO_2_INFO_CTA',
  PROMO_3 = 'PROMO_3',
  PROMO_3_TAB_LABEL = 'PROMO_3_TAB_LABEL',
  PROMO_3_CTA_LABEL = 'PROMO_3_CTA_LABEL',
  PROMO_3_URL_PARAM = 'PROMO_3_URL_PARAM',
  PROMO_3_TC = 'PROMO_3_TC',
  PROMO_3_INFO_DESCRIPTION = 'PROMO_3_INFO_DESCRIPTION',
  PROMO_3_INFO_LINK = 'PROMO_3_INFO_LINK',
  PROMO_3_INFO_CTA = 'PROMO_3_INFO_CTA',
  PROMO_4 = 'PROMO_4',
  PROMO_4_BANNER = 'PROMO_4_BANNER',
}
