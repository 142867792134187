import { produce } from 'immer';

import { storeFactory } from './storeFactory';
import { RecentFilterSearch } from 'src/hygiene/components/flexible-content/RecentSearch/RecentSearch.type';
import { addRecentSearchFilter, removeFilterFromFilters } from './recentFilterSearch.helper';
import { PageProductListingFilters } from 'src/data/Contentful/ContentfulGetPageProductListing.types';

export interface RecentSearchFilterStoreStateData {
  recentFilterSearches: RecentFilterSearch[];
}

interface RecentSearchFilterStoreStateFunctions {
  removeFilterSearch: (search: RecentFilterSearch) => Promise<void>;
  addFilterSearch: (filters: PageProductListingFilters) => Promise<void>;
}

const {
  store: recentFilterSearchStore,
  StoreProvider: RecentFilterSearchStoreProvider,
  useCreateStore: useCreateRecentFilterSearchStore,
} = storeFactory<RecentSearchFilterStoreStateData, RecentSearchFilterStoreStateFunctions>(
  () => ({
    recentFilterSearches: [],
  }),
  (set) => ({
    removeFilterSearch: async (search: RecentFilterSearch) => {
      set((state) => {
        return produce(state, (draft) => {
          draft.recentFilterSearches = removeFilterFromFilters(state.recentFilterSearches, search);
        });
      });
    },
    addFilterSearch: async (filters: PageProductListingFilters) => {
      set((state) => {
        return produce(state, (draft) => {
          const { updated, recentSearches } = addRecentSearchFilter(filters, state.recentFilterSearches);
          if (updated && recentSearches) {
            draft.recentFilterSearches = recentSearches;
          }
        });
      });
    },
  }),
  'recentFilterSearchStore',
);

export { recentFilterSearchStore, RecentFilterSearchStoreProvider, useCreateRecentFilterSearchStore };
