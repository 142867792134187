export enum plpMicrocopy {
  PLP_PAGE_TITLE = 'PLP_PAGE_TITLE',
  PLP_PAGE_DESCRIPTION = 'PLP_PAGE_DESCRIPTION',
  PLP_PILL_PURCHASE_PROGRESS = 'PLP_PILL_PURCHASE_PROGRESS',
  PLP_PILL_MY_PURCHASE = 'PLP_PILL_MY_PURCHASE',
  PLP_PILL_DRAFT_ORDER = 'PLP_PILL_DRAFT_ORDER',
  PLP_PILL_RESERVED = 'PLP_PILL_RESERVED',
  PLP_PRODUCT_CARD_ESTIMATE_REPAYMENT = 'PLP_PRODUCT_CARD_ESTIMATE_REPAYMENT',
  PLP_PILL_REFINE_PAYMENT_HEADLINE = 'PLP_PILL_REFINE_PAYMENT_HEADLINE',
  PLP_PILL_REFINE_PAYMENT_DESCRIPTION = 'PLP_PILL_REFINE_PAYMENT_DESCRIPTION',
  PLP_NO_RESULTS_FORM = 'PLP_NO_RESULTS_FORM',
  PLP_SEARCH_DEFAULT_TITLE = 'PLP_DEFAULT_TITLE',
  PLP_SEARCH_DEFAULT_SEO_COPY = 'PLP_SEARCH_DEFAULT_SEO_COPY',
  PLP_SEARCH_MULTI_TITLE = 'PLP_SEARCH_MULTI_TITLE',
  PLP_SEARCH_MULTI_SEO_COPY = 'PLP_SEARCH_MULTI_SEO_COPY',
  PLP_SEARCH_NO_RESULT_TITLE = 'PLP_SEARCH_NO_RESULT_TITLE',
  PLP_SEARCH_NO_RESULT_SEO_COPY_TITLE = 'PLP_SEARCH_NO_RESULT_SEO_COPY_TITLE',
  PLP_SEARCH_NO_RESULT_SEO_COPY_DESCRIPTION = 'PLP_SEARCH_NO_RESULT_SEO_COPY_DESCRIPTION',
  PLP_MARKETING_BANNER_TITLE = 'PLP_MARKETING_BANNER_TITLE',
  PLP_MARKETING_BANNER_DESCRIPTION = 'PLP_MARKETING_BANNER_DESCRIPTION',
  PLP_MARKETING_BANNER_CTA = 'PLP_MARKETING_BANNER_CTA',
}
