export enum accountMicrocopy {
  ACCOUNT_NAV_GREETING = 'ACCOUNT_NAV_GREETING',
  ACCOUNT_NAV_MY_PURCHASES = 'ACCOUNT_NAV_MY_PURCHASES',
  ACCOUNT_NAV_PERSONAL_DETAILS = 'ACCOUNT_NAV_PERSONAL_DETAILS',
  ACCOUNT_NAV_SIGN_OUT = 'ACCOUNT_NAV_SIGN_OUT',
  ACCOUNT_NAV_MY_FINANCE_QUOTES = 'ACCOUNT_NAV_MY_FINANCE_QUOTES',
  ACCOUNT_PURCHASES_TITLE = 'ACCOUNT_PURCHASES_TITLE',
  ACCOUNT_PURCHASES_DELIVERY_STATUS_NOT_SCHEDULED = 'ACCOUNT_PURCHASES_DELIVERY_STATUS_NOT_SCHEDULED',
  ACCOUNT_PURCHASES_DELIVERY_STATUS_SCHEDULED = 'ACCOUNT_PURCHASES_DELIVERY_STATUS_SCHEDULED',
  ACCOUNT_PURCHASES_DELIVERY_STATUS_PREPARING_VEHICLE = 'ACCOUNT_PURCHASES_DELIVERY_STATUS_PREPARING_VEHICLE',
  ACCOUNT_PURCHASES_DELIVERY_STATUS_OUT_FOR_DELIVERY = 'ACCOUNT_PURCHASES_DELIVERY_STATUS_OUT_FOR_DELIVERY',
  ACCOUNT_PURCHASES_DELIVERY_STATUS_DELIVERED = 'ACCOUNT_PURCHASES_DELIVERY_STATUS_DELIVERED',
  ACCOUNT_PURCHASES_DELIVERY_STATUS_RETURN_REQUESTED = 'ACCOUNT_PURCHASES_DELIVERY_STATUS_RETURN_REQUESTED',
  ACCOUNT_PURCHASES_DELIVERY_STATUS_RETURNED = 'ACCOUNT_PURCHASES_DELIVERY_STATUS_RETURNED',
  ACCOUNT_PURCHASES_DELIVERY_STATUS_CANCELLED = 'ACCOUNT_PURCHASES_DELIVERY_STATUS_CANCELLED',
  ACCOUNT_PURCHASES_COLLECTION_STATUS_NOT_SCHEDULED = 'ACCOUNT_PURCHASES_COLLECTION_STATUS_NOT_SCHEDULED',
  ACCOUNT_PURCHASES_COLLECTION_STATUS_SCHEDULED = 'ACCOUNT_PURCHASES_COLLECTION_STATUS_SCHEDULED',
  ACCOUNT_PURCHASES_COLLECTION_STATUS_PREPARING_VEHICLE = 'ACCOUNT_PURCHASES_COLLECTION_STATUS_PREPARING_VEHICLE',
  ACCOUNT_PURCHASES_COLLECTION_STATUS_OUT_FOR_COLLECTION = 'ACCOUNT_PURCHASES_COLLECTION_STATUS_OUT_FOR_COLLECTION',
  ACCOUNT_PURCHASES_COLLECTION_STATUS_COLLECTED = 'ACCOUNT_PURCHASES_COLLECTION_STATUS_COLLECTED',
  ACCOUNT_PURCHASES_NO_CARS_PURCHASED = 'ACCOUNT_PURCHASES_NO_CARS_PURCHASED',
  ACCOUNT_PURCHASES_CAR_DETAILS = 'ACCOUNT_PURCHASES_CAR_DETAILS',
  ACCOUNT_PURCHASES_CARMA_CHECKLIST = 'ACCOUNT_PURCHASES_CARMA_CHECKLIST',
  ACCOUNT_PURCHASES_VIEW_PURCHASE_INFO = 'ACCOUNT_PURCHASES_VIEW_PURCHASE_INFO',
  ACCOUNT_FINANCE_TITLE = 'ACCOUNT_FINANCE_TITLE',
  ACCOUNT_NOTIFICATION_TITLE = 'ACCOUNT_NOTIFICATION_TITLE',
  ACCOUNT_NOTIFICATION_SETTINGS = 'ACCOUNT_NOTIFICATION_SETTINGS',
  ACCOUNT_PURCHASES_CUSTOMER_SERVICE = 'ACCOUNT_PURCHASES_CUSTOMER_SERVICE',
  ACCOUNT_PURCHASES_CUSTOMER_SERVICE_GUARANTEE = 'ACCOUNT_PURCHASES_CUSTOMER_SERVICE_GUARANTEE',
  ACCOUNT_PURCHASES_CUSTOMER_SERVICE_T_C = 'ACCOUNT_PURCHASES_CUSTOMER_SERVICE_T_C',
  ACCOUNT_PURCHASES_CUSTOMER_SERVICE_PRIVACY_POLICY = 'ACCOUNT_PURCHASES_CUSTOMER_SERVICE_PRIVACY_POLICY',
  ACCOUNT_PURCHASES_MY_PAYMENT_PROGRESS = 'ACCOUNT_PURCHASES_MY_PAYMENT_PROGRESS',
  ACCOUNT_PURCHASES_PAYING_YOUR_REMAINING_FUNDS = 'ACCOUNT_PURCHASES_PAYING_YOUR_REMAINING_FUNDS',
  ACCOUNT_PURCHASES_VIEW_BANK_TRANSFER_DETAILS_CTA = 'ACCOUNT_PURCHASES_VIEW_BANK_TRANSFER_DETAILS_CTA',
  ACCOUNT_PURCHASES_RESERVATION_DEPOSIT = 'ACCOUNT_PURCHASES_RESERVATION_DEPOSIT',
  ACCOUNT_PURCHASES_FINANCE_DEPOSIT = 'ACCOUNT_PURCHASES_FINANCE_DEPOSIT',
  ACCOUNT_PURCHASES_FINANCE = 'ACCOUNT_PURCHASES_FINANCE',
  ACCOUNT_PURCHASES_REMAINING_AMOUNT = 'ACCOUNT_PURCHASES_REMAINING_AMOUNT',
  ACCOUNT_PURCHASES_TRADE_IN = 'ACCOUNT_PURCHASES_TRADE_IN',
  ACCOUNT_PURCHASES_FINANCE_PAYOUT = 'ACCOUNT_PURCHASES_FINANCE_PAYOUT',
  ACCOUNT_PURCHASES_DEPOSIT_PAID_ON = 'ACCOUNT_PURCHASES_DEPOSIT_PAID_ON',
  ACCOUNT_PURCHASES_REMAINING_AMOUNT_PAYMENT = 'ACCOUNT_PURCHASES_REMAINING_AMOUNT_PAYMENT',
  ACCOUNT_PURCHASES_REMAINING_FUNDS = 'ACCOUNT_PURCHASES_REMAINING_FUNDS',
  ACCOUNT_PURCHASES_ORDER_COMPLETED = 'ACCOUNT_PURCHASES_ORDER_COMPLETED',
  ACCOUNT_PURCHASES_PAY_REMAINING_FUNDS = 'ACCOUNT_PURCHASES_PAY_REMAINING_FUNDS',
  ACCOUNT_PURCHASES_MY_DOCUMENTS = 'ACCOUNT_PURCHASES_MY_DOCUMENTS',
  ACCOUNT_PURCHASES_MY_DOCUMENTS_T_C = 'ACCOUNT_PURCHASES_MY_DOCUMENTS_T_C',
  ACCOUNT_PURCHASES_MY_DOCUMENTS_PRIVACY_POLICY = 'ACCOUNT_PURCHASES_MY_DOCUMENTS_PRIVACY_POLICY',
  ACCOUNT_PURCHASES_MY_DELIVERY = 'ACCOUNT_PURCHASES_MY_DELIVERY',
  ACCOUNT_PURCHASES_MY_COLLECTION = 'ACCOUNT_PURCHASES_MY_COLLECTION',
  ACCOUNT_PURCHASES_MY_DELIVERY_DESCRIPTION_SCHEDULED = 'ACCOUNT_PURCHASES_MY_DELIVERY_DESCRIPTION_SCHEDULED',
  ACCOUNT_PURCHASES_MY_DELIVERY_DESCRIPTION_NOT_SCHEDULED = 'ACCOUNT_PURCHASES_MY_DELIVERY_DESCRIPTION_NOT_SCHEDULED',
  ACCOUNT_PURCHASES_MY_DELIVERY_IMAGE_DESCRIPTION_DELIVERED = 'ACCOUNT_PURCHASES_MY_DELIVERY_IMAGE_DESCRIPTION_DELIVERED',
  ACCOUNT_PURCHASES_MY_COLLECTION_DESCRIPTION_SCHEDULED = 'ACCOUNT_PURCHASES_MY_COLLECTION_DESCRIPTION_SCHEDULED',
  ACCOUNT_PURCHASES_MY_COLLECTION_DESCRIPTION_NOT_SCHEDULED = 'ACCOUNT_PURCHASES_MY_COLLECTION_DESCRIPTION_NOT_SCHEDULED',
  ACCOUNT_PURCHASES_MY_COLLECTION_IMAGE_DESCRIPTION_COLLECTED = 'ACCOUNT_PURCHASES_MY_COLLECTION_IMAGE_DESCRIPTION_COLLECTED',
  ACCOUNT_PURCHASES_MY_DELIVERY_IMAGE_DESCRIPTION_RETURN_REQUESTED = 'ACCOUNT_PURCHASES_MY_DELIVERY_IMAGE_DESCRIPTION_RETURN_REQUESTED',
  ACCOUNT_PURCHASES_MY_DELIVERY_IMAGE_DESCRIPTION_RETURNED = 'ACCOUNT_PURCHASES_MY_DELIVERY_IMAGE_DESCRIPTION_RETURNED',
  ACCOUNT_PURCHASES_MY_DELIVERY_IMAGE_DESCRIPTION_CANCELLED = 'ACCOUNT_PURCHASES_MY_DELIVERY_IMAGE_DESCRIPTION_CANCELLED',
  ACCOUNT_PURCHASES_MY_DELIVERY_TIME = 'ACCOUNT_PURCHASES_MY_DELIVERY_TIME',
  ACCOUNT_PURCHASES_MY_COLLECTION_TIME = 'ACCOUNT_PURCHASES_MY_COLLECTION_TIME',
  ACCOUNT_PURCHASES_MY_DELIVERY_LOCATION = 'ACCOUNT_PURCHASES_MY_DELIVERY_LOCATION',
  ACCOUNT_PURCHASES_MY_COLLECTION_LOCATION = 'ACCOUNT_PURCHASES_MY_COLLECTION_LOCATION',
  ACCOUNT_PURCHASES_BANK_TRANSFER_DETAILS_TITLE = 'ACCOUNT_PURCHASES_BANK_TRANSFER_DETAILS_TITLE',
  ACCOUNT_PURCHASES_BANK_TRANSFER_DETAILS_DESCRIPTION = 'ACCOUNT_PURCHASES_BANK_TRANSFER_DETAILS_DESCRIPTION',
  ACCOUNT_PURCHASES_BANK_TRANSFER_DETAILS_ACCOUNT_NAME = 'ACCOUNT_PURCHASES_BANK_TRANSFER_DETAILS_ACCOUNT_NAME',
  ACCOUNT_PURCHASES_BANK_TRANSFER_DETAILS_BSB = 'ACCOUNT_PURCHASES_BANK_TRANSFER_DETAILS_BSB',
  ACCOUNT_PURCHASES_BANK_TRANSFER_DETAILS_ACCOUNT_NUMBER = 'ACCOUNT_PURCHASES_BANK_TRANSFER_DETAILS_ACCOUNT_NUMBER',
  ACCOUNT_PURCHASES_BANK_TRANSFER_DETAILS_REFERENCE_NUMBER = 'ACCOUNT_PURCHASES_BANK_TRANSFER_DETAILS_REFERENCE_NUMBER',
  ACCOUNT_PURCHASES_BANK_TRANSFER_DETAILS_PAY_ID = 'ACCOUNT_PURCHASES_BANK_TRANSFER_DETAILS_PAY_ID',
  ACCOUNT_PURCHASES_MY_DELIVERY_NOT_SCHEDULED_CONTENT = 'ACCOUNT_PURCHASES_MY_DELIVERY_NOT_SCHEDULED_CONTENT',
  ACCOUNT_PURCHASES_MY_DELIVERY_ABLE_SCHEDULE_CONTENT = 'ACCOUNT_PURCHASES_MY_DELIVERY_ABLE_SCHEDULE_CONTENT',
  ACCOUNT_PURCHASES_MY_DELIVERY_UNABLE_SCHEDULE_CONTENT = 'ACCOUNT_PURCHASES_MY_DELIVERY_UNABLE_SCHEDULE_CONTENT',
  ACCOUNT_PURCHASES_MY_DELIVERY_CAR_DELIVERED_MESSAGE = 'ACCOUNT_PURCHASES_MY_DELIVERY_CAR_DELIVERED_MESSAGE',
  ACCOUNT_PURCHASES_MY_DELIVERY_LOCATION_BUTTON_LABEL = 'ACCOUNT_PURCHASES_MY_DELIVERY_LOCATION_BUTTON_LABEL',
  ACCOUNT_PURCHASES_MY_DELIVERY_TIME_BUTTON_LABEL = 'ACCOUNT_PURCHASES_MY_DELIVERY_TIME_BUTTON_LABEL',
  ACCOUNT_PURCHASES_DELIVERY_HEADER = 'ACCOUNT_PURCHASES_DELIVERY_HEADER',
  ACCOUNT_PURCHASES_DELIVERY_NOT_SCHEDULED_CONTENT = 'ACCOUNT_PURCHASES_DELIVERY_NOT_SCHEDULED_CONTENT',
  ACCOUNT_PURCHASES_DELIVERY_SCHEDULED_CONTENT = 'ACCOUNT_PURCHASES_DELIVERY_SCHEDULED_CONTENT',
  ACCOUNT_PURCHASES_DELIVERY_DELIVERED_CONTENT = 'ACCOUNT_PURCHASES_DELIVERY_DELIVERED_CONTENT',
  ACCOUNT_PURCHASES_DELIVERY_LOCATION_TITLE = 'ACCOUNT_PURCHASES_DELIVERY_LOCATION_TITLE',
  ACCOUNT_PURCHASES_DELIVERY_DATE_TIME_TITLE = 'ACCOUNT_PURCHASES_DELIVERY_DATE_TIME_TITLE',
  ACCOUNT_PURCHASES_COLLECT_HEADER = 'ACCOUNT_PURCHASES_COLLECT_HEADER',
  ACCOUNT_PURCHASES_COLLECT_NOT_SCHEDULED_CONTENT = 'ACCOUNT_PURCHASES_COLLECT_NOT_SCHEDULED_CONTENT',
  ACCOUNT_PURCHASES_COLLECT_SCHEDULED_CONTENT = 'ACCOUNT_PURCHASES_COLLECT_SCHEDULED_CONTENT',
  ACCOUNT_PURCHASES_COLLECT_DELIVERED_CONTENT = 'ACCOUNT_PURCHASES_COLLECT_DELIVERED_CONTENT',
  ACCOUNT_PURCHASES_COLLECT_LOCATION_TITLE = 'ACCOUNT_PURCHASES_COLLECT_LOCATION_TITLE',
  ACCOUNT_PURCHASES_COLLECT_DATE_TIME_TITLE = 'ACCOUNT_PURCHASES_COLLECT_DATE_TIME_TITLE',
  ACCOUNT_PURCHASES_GET_DIRECTIONS_BUTTON_LABEL = 'ACCOUNT_PURCHASES_GET_DIRECTIONS_BUTTON_LABEL',
  ACCOUNT_PURCHASES_FUNDS_REMAINING_EXCLUDE_GOVERNMENT_CHARGES_LABEL = 'ACCOUNT_PURCHASES_FUNDS_REMAINING_EXCLUDE_GOVERNMENT_CHARGES_LABEL',
  ACCOUNT_PURCHASES_FUNDS_REMAINING_PROCESSING_FEE_LABEL = 'ACCOUNT_PURCHASES_FUNDS_REMAINING_PROCESSING_FEE_LABEL',
  ACCOUNT_PURCHASES_FUNDS_REMAINING_TBD_BY_PAYMENT_LABEL = 'ACCOUNT_PURCHASES_FUNDS_REMAINING_TBD_BY_PAYMENT_LABEL',
  ACCOUNT_PURCHASES_FUNDS_REMAINING_PAYMENT_METHOD_LABEL = 'ACCOUNT_PURCHASES_FUNDS_REMAINING_PAYMENT_METHOD_LABEL',
  ACCOUNT_PURCHASES_FUNDS_REMAINING_BILLING_ADDRESS_LABEL = 'ACCOUNT_PURCHASES_FUNDS_REMAINING_BILLING_ADDRESS_LABEL',
  ACCOUNT_PURCHASES_FUNDS_REMAINING_FUNDS_DUE_LABEL = 'ACCOUNT_PURCHASES_FUNDS_REMAINING_FUNDS_DUE_LABEL',
  ACCOUNT_PURCHASES_FUNDS_REMAINING_TOTAL_LABEL = 'ACCOUNT_PURCHASES_FUNDS_REMAINING_TOTAL_LABEL',
  ACCOUNT_PURCHASES_FUNDS_REMAINING_BACK_LABEL = 'ACCOUNT_PURCHASES_FUNDS_REMAINING_BACK_LABEL',
  ACCOUNT_PURCHASES_FUNDS_REMAINING_PAY_BALANCE_LABEL = 'ACCOUNT_PURCHASES_FUNDS_REMAINING_PAY_BALANCE_LABEL',
  ACCOUNT_PERSONAL_DETAILS_TITLE = 'ACCOUNT_PERSONAL_DETAILS_TITLE',
  ACCOUNT_PERSONAL_INFO_TITLE = 'ACCOUNT_PERSONAL_INFO_TITLE',
  ACCOUNT_PERSONAL_INFO_NAME = 'ACCOUNT_PERSONAL_INFO_NAME',
  ACCOUNT_PERSONAL_INFO_EMAIL = 'ACCOUNT_PERSONAL_INFO_EMAIL',
  ACCOUNT_PERSONAL_INFO_PHONE = 'ACCOUNT_PERSONAL_INFO_PHONE',
  ACCOUNT_PERSONAL_INFO_PASSWORD = 'ACCOUNT_PERSONAL_INFO_PASSWORD',
  ACCOUNT_PERSONAL_INFO_ADD_PHONE = 'ACCOUNT_PERSONAL_INFO_ADD_PHONE',
  ACCOUNT_PERSONAL_INFO_ADD_PASSWORD = 'ACCOUNT_PERSONAL_INFO_ADD_PASSWORD',
  ACCOUNT_PERSONAL_INFO_ADD_PASSWORD_DESCRIPTION = 'ACCOUNT_PERSONAL_INFO_ADD_PASSWORD_DESCRIPTION',
  ACCOUNT_PERSONAL_INFO_EDIT_DETAILS = 'ACCOUNT_PERSONAL_INFO_EDIT_DETAILS',
  ACCOUNT_PERSONAL_INFO_EDIT_PHONE = 'ACCOUNT_PERSONAL_INFO_EDIT_PHONE',
  ACCOUNT_PERSONAL_INFO_EDIT_PHONE_SUCCESS_TITLE = 'ACCOUNT_PERSONAL_INFO_EDIT_PHONE_SUCCESS_TITLE',
  ACCOUNT_PERSONAL_INFO_EDIT_PHONE_DESCRIPTION_SUCCESS = 'ACCOUNT_PERSONAL_INFO_EDIT_PHONE_DESCRIPTION_SUCCESS',
  ACCOUNT_PERSONAL_INFO_EDIT_PASSWORD_SUCCESS_TITLE = 'ACCOUNT_PERSONAL_INFO_EDIT_PASSWORD_SUCCESS_TITLE',
  ACCOUNT_PERSONAL_INFO_EDIT_PASSWORD_DESCRIPTION_SUCCESS = 'ACCOUNT_PERSONAL_INFO_EDIT_PASSWORD_DESCRIPTION_SUCCESS',
  ACCOUNT_PERSONAL_INFO_EDIT_PHONE_FORM_LABEL = 'ACCOUNT_PERSONAL_INFO_EDIT_PHONE_FORM_LABEL',
  ACCOUNT_PERSONAL_INFO_EDIT_PHONE_DESCRIPTION = 'ACCOUNT_PERSONAL_INFO_EDIT_PHONE_DESCRIPTION',
  ACCOUNT_PERSONAL_INFO_EDIT_PHONE_FORM_SAVE_BUTTON_LABEL = 'ACCOUNT_PERSONAL_INFO_EDIT_PHONE_FORM_SAVE_BUTTON_LABEL',
  ACCOUNT_PERSONAL_INFO_EDIT_PHONE_FORM_CANCEL_BUTTON_LABEL = 'ACCOUNT_PERSONAL_INFO_EDIT_PHONE_FORM_CANCEL_BUTTON_LABEL',
  ACCOUNT_PERSONAL_INFO_EDIT_PHONE_FORM_CLOSE_BUTTON_LABEL = 'ACCOUNT_PERSONAL_INFO_EDIT_PHONE_FORM_CLOSE_BUTTON_LABEL',
  ACCOUNT_PERSONAL_INFO_EDIT_DETAILS_TITLE = 'ACCOUNT_PERSONAL_INFO_EDIT_DETAILS_TITLE',
  ACCOUNT_PERSONAL_INFO_EDIT_DETAILS_SUCCESS_TITLE = 'ACCOUNT_PERSONAL_INFO_EDIT_DETAILS_SUCCESS_TITLE',
  ACCOUNT_PERSONAL_INFO_EDIT_DETAILS_DESCRIPTION_SUCCESS = 'ACCOUNT_PERSONAL_INFO_EDIT_DETAILS_DESCRIPTION_SUCCESS',
  ACCOUNT_PERSONAL_INFO_EDIT_DETAILS_FORM_FIRST_NAME_LABEL = 'ACCOUNT_PERSONAL_INFO_EDIT_DETAILS_FORM_FIRST_NAME_LABEL',
  ACCOUNT_PERSONAL_INFO_EDIT_DETAILS_FORM_LAST_NAME_LABEL = 'ACCOUNT_PERSONAL_INFO_EDIT_DETAILS_FORM_LAST_NAME_LABEL',
  ACCOUNT_PERSONAL_INFO_EDIT_DETAILS_FORM_EMAIL_ADDRESS_LABEL = 'ACCOUNT_PERSONAL_INFO_EDIT_DETAILS_FORM_EMAIL_ADDRESS_LABEL',
  ACCOUNT_PERSONAL_INFO_EDIT_DETAILS_FORM_SAVE_BUTTON_LABEL = 'ACCOUNT_PERSONAL_INFO_EDIT_DETAILS_FORM_SAVE_BUTTON_LABEL',
  ACCOUNT_PERSONAL_INFO_EDIT_DETAILS_FORM_CANCEL_BUTTON_LABEL = 'ACCOUNT_PERSONAL_INFO_EDIT_DETAILS_FORM_CANCEL_BUTTON_LABEL',
  ACCOUNT_PERSONAL_INFO_EDIT_DETAILS_FORM_CLOSE_BUTTON_LABEL = 'ACCOUNT_PERSONAL_INFO_EDIT_DETAILS_FORM_CLOSE_BUTTON_LABEL',
  ACCOUNT_PERSONAL_INFO_EDIT_PASSWORD = 'ACCOUNT_PERSONAL_INFO_EDIT_PASSWORD',
  ACCOUNT_UPDATE_PERSONAL_INFO_SAVE_BUTTON_LABEL = 'ACCOUNT_UPDATE_PERSONAL_INFO_SAVE_BUTTON_LABEL',
  ACCOUNT_UPDATE_PERSONAL_INFO_CANCEL_BUTTON_LABEL = 'ACCOUNT_UPDATE_PERSONAL_INFO_CANCEL_BUTTON_LABEL',
  ACCOUNT_UPDATE_PERSONAL_INFO_CLOSE_BUTTON_LABEL = 'ACCOUNT_UPDATE_PERSONAL_INFO_CLOSE_BUTTON_LABEL',
  ACCOUNT_PERSONAL_INFO_SUBSCRIPTIONS = 'ACCOUNT_PERSONAL_INFO_SUBSCRIPTIONS',
  ACCOUNT_PERSONAL_INFO_SUBSCRIPTIONS_OPTION = 'ACCOUNT_PERSONAL_INFO_SUBSCRIPTIONS_OPTION',
  ACCOUNT_PERSONAL_INFO_SEARCH_ALERT_TITLE = 'ACCOUNT_PERSONAL_INFO_SEARCH_ALERT_TITLE',
  ACCOUNT_PERSONAL_INFO_SEARCH_ALERT_BUTTON_LABEL = 'ACCOUNT_PERSONAL_INFO_SEARCH_ALERT_BUTTON_LABEL',
  ACCOUNT_PERSONAL_INFO_SEARCH_ALERT_EMPTY = 'ACCOUNT_PERSONAL_INFO_SEARCH_ALERT_EMPTY',
  ACCOUNT_PAYMENT_TITLE = 'ACCOUNT_PAYMENT_TITLE',
  ACCOUNT_PAYMENT_OPTION_BANK_TRANSFER_TITLE = 'ACCOUNT_PAYMENT_OPTION_BANK_TRANSFER_TITLE',
  ACCOUNT_PAYMENT_OPTION_BANK_TRANSFER_SUBTITLE = 'ACCOUNT_PAYMENT_OPTION_BANK_TRANSFER_SUBTITLE',
  ACCOUNT_PAYMENT_OPTION_BANK_TRANSFER_DESCRIPTION = 'ACCOUNT_PAYMENT_OPTION_BANK_TRANSFER_DESCRIPTION',
  ACCOUNT_PAYMENT_OPTION_BANK_TRANSFER_INFO_TITLE = 'ACCOUNT_PAYMENT_OPTION_BANK_TRANSFER_INFO_TITLE',
  ACCOUNT_PAYMENT_OPTION_BANK_TRANSFER_ACCOUNT_NAME = 'ACCOUNT_PAYMENT_OPTION_BANK_TRANSFER_ACCOUNT_NAME',
  ACCOUNT_PAYMENT_OPTION_BANK_TRANSFER_BSB = 'ACCOUNT_PAYMENT_OPTION_BANK_TRANSFER_BSB',
  ACCOUNT_PAYMENT_OPTION_BANK_TRANSFER_ACCOUNT = 'ACCOUNT_PAYMENT_OPTION_BANK_TRANSFER_ACCOUNT',
  ACCOUNT_PAYMENT_OPTION_BANK_TRANSFER_ACCOUNT_REF = 'ACCOUNT_PAYMENT_OPTION_BANK_TRANSFER_ACCOUNT_REF',
  ACCOUNT_PAYMENT_OPTION_BANK_TRANSFER_IMPORTANT_INFO = 'ACCOUNT_PAYMENT_OPTION_BANK_TRANSFER_IMPORTANT_INFO',
  ACCOUNT_PAYMENT_OPTION_BANK_TRANSFER_ACCEPT_TITLE = 'ACCOUNT_PAYMENT_OPTION_BANK_TRANSFER_ACCEPT_TITLE',
  ACCOUNT_PAYMENT_OPTION_BANK_TRANSFER_ACCEPT_INFO = 'ACCOUNT_PAYMENT_OPTION_BANK_TRANSFER_ACCEPT_INFO',
  ACCOUNT_PAYMENT_OPTION_CARD_TITLE = 'ACCOUNT_PAYMENT_OPTION_CARD_TITLE',
  ACCOUNT_PAYMENT_OPTION_CARD_SUBTITLE = 'ACCOUNT_PAYMENT_OPTION_CARD_SUBTITLE',
  ACCOUNT_PAYMENT_OPTION_CARD_SECURITY = 'ACCOUNT_PAYMENT_OPTION_CARD_SECURITY',
  ACCOUNT_PAYMENT_ERROR_NO_PAYMENT_METHOD = 'ACCOUNT_PAYMENT_ERROR_NO_PAYMENT_METHOD',
  ACCOUNT_PAYMENT_ERROR_CARD_INVALID = 'ACCOUNT_PAYMENT_ERROR_CARD_INVALID',
  ACCOUNT_PAYMENT_CARD_INVALID_SHOW_CONTACT_TEXT = 'ACCOUNT_PAYMENT_CARD_INVALID_SHOW_CONTACT_TEXT',
  ACCOUNT_PAYMENT_FUNDS_REMAINING = 'ACCOUNT_PAYMENT_FUNDS_REMAINING',
  ACCOUNT_PAYMENT_PROCESSING_FEE = 'ACCOUNT_PAYMENT_PROCESSING_FEE',
  ACCOUNT_PAYMENT_TOTAL = 'ACCOUNT_PAYMENT_TOTAL',
  ACCOUNT_PAYMENT_PROCESSING_FEE_DEFAULT = 'ACCOUNT_PAYMENT_PROCESSING_FEE_DEFAULT',
  ACCOUNT_PAYMENT_PROCESSING_FEE_FREE = 'ACCOUNT_PAYMENT_PROCESSING_FEE_FREE',
  ACCOUNT_PAYMENT_NEXT_CARD = 'ACCOUNT_PAYMENT_NEXT_CARD',
  ACCOUNT_PAYMENT_NEXT_BANK_TRANSFER = 'ACCOUNT_PAYMENT_NEXT_BANK_TRANSFER',
  ACCOUNT_PAYMENT_PREV = 'ACCOUNT_PAYMENT_PREV',
  ACCOUNT_PAYMENT_BANK_TRANSFER_POPUP_TITLE = 'ACCOUNT_PAYMENT_BANK_TRANSFER_POPUP_TITLE',
  ACCOUNT_PAYMENT_BANK_TRANSFER_POPUP_OK = 'ACCOUNT_PAYMENT_BANK_TRANSFER_POPUP_OK',
  ACCOUNT_PAYMENT_BANK_TRANSFER_POPUP_CANCEL = 'ACCOUNT_PAYMENT_BANK_TRANSFER_POPUP_CANCEL',
  ACCOUNT_PAYMENT_BANK_TRANSFER_POPUP_CONTENT = 'ACCOUNT_PAYMENT_BANK_TRANSFER_POPUP_CONTENT',
  ACCOUNT_PAYMENT_BANK_TRANSFER_COPY = 'ACCOUNT_PAYMENT_BANK_TRANSFER_COPY',
  ACCOUNT_NOTIFICATIONS_WATCHLIST_TITLE = 'ACCOUNT_NOTIFICATIONS_WATCHLIST_TITLE',
  ACCOUNT_NOTIFICATIONS_DISABLE_ALL_BUTTON = 'ACCOUNT_NOTIFICATIONS_DISABLE_ALL_BUTTON',
  ACCOUNT_NOTIFICATIONS_PRICE_DROP = 'ACCOUNT_NOTIFICATIONS_PRICE_DROP',
  ACCOUNT_NOTIFICATIONS_VEHICLE_AVAILABLE = 'ACCOUNT_NOTIFICATIONS_VEHICLE_AVAILABLE',
  ACCOUNT_NOTIFICATIONS_PRICE_DROP_TITLE = 'ACCOUNT_NOTIFICATIONS_PRICE_DROP_TITLE',
  ACCOUNT_NOTIFICATIONS_PRICE_DROP_SUBTITLE = 'ACCOUNT_NOTIFICATIONS_PRICE_DROP_SUBTITLE',
  ACCOUNT_NOTIFICATIONS_WATCHLIST_WATCHERS_INCREASE = 'ACCOUNT_NOTIFICATIONS_WATCHLIST_WATCHERS_INCREASE',
  ACCOUNT_PAYID_HEADER = 'ACCOUNT_PAYID_HEADER',
  ACCOUNT_PAYID_TITLE = 'ACCOUNT_PAYID_TITLE',
  ACCOUNT_PAYID_DEBIT_CREDIT_CARD_TITLE = 'ACCOUNT_PAYID_DEBIT_CREDIT_CARD_TITLE',
  ACCOUNT_PAYID_BANK_TRANSFER_TITLE = 'ACCOUNT_PAYID_BANK_TRANSFER_TITLE',
  ACCOUNT_PAYID_BUTTON_LABEL = 'ACCOUNT_PAYID_BUTTON_LABEL',
  ACCOUNT_PAYMENT_OPTIONS_FORM_PAY_ID_HEADER = 'ACCOUNT_PAYMENT_OPTIONS_FORM_PAY_ID_HEADER',
  ACCOUNT_PAYMENT_OPTIONS_FORM_DEBIT_CREDIT_CARD_HEADER = 'ACCOUNT_PAYMENT_OPTIONS_FORM_DEBIT_CREDIT_CARD_HEADER',
  ACCOUNT_PAYMENT_OPTIONS_FORM_ONLINE_BANK_TRANSFER_HEADER = 'ACCOUNT_PAYMENT_OPTIONS_FORM_ONLINE_BANK_TRANSFER_HEADER',
  ACCOUNT_PAYMENT_OPTIONS_FORM_REMAINING_AMOUNT_TITLE = 'ACCOUNT_PAYMENT_OPTIONS_FORM_REMAINING_AMOUNT_TITLE',
  ACCOUNT_PAYMENT_OPTIONS_FORM_PROCESS_FEE_TITLE = 'ACCOUNT_PAYMENT_OPTIONS_FORM_PROCESS_FEE_TITLE',
  ACCOUNT_PAYMENT_OPTIONS_FORM_TOTAL_TITLE = 'ACCOUNT_PAYMENT_OPTIONS_FORM_TOTAL_TITLE',
  ACCOUNT_PAYMENT_OPTIONS_FORM_PAYMENT_DETAILS_HEADER = 'ACCOUNT_PAYMENT_OPTIONS_FORM_PAYMENT_DETAILS_HEADER',
  ACCOUNT_PAYMENT_OPTIONS_FORM_PAYMENT_BANK_DETAILS_HEADER = 'ACCOUNT_PAYMENT_OPTIONS_FORM_PAYMENT_BANK_DETAILS_HEADER',
  ACCOUNT_PAYMENT_OPTIONS_FORM_PAYMENT_DEBIT_CREDIT_DETAILS_HEADER = 'ACCOUNT_PAYMENT_OPTIONS_FORM_PAYMENT_DEBIT_CREDIT_DETAILS_HEADER',
  ACCOUNT_PAYMENT_OPTIONS_FORM_PAYMENT_DEBIT_CREDIT_DESCRIPTION = 'ACCOUNT_PAYMENT_OPTIONS_FORM_PAYMENT_DEBIT_CREDIT_DESCRIPTION',
  ACCOUNT_PAYMENT_OPTIONS_FORM_PAYMENT_DETAILS_COPY_TEXT = 'ACCOUNT_PAYMENT_OPTIONS_FORM_PAYMENT_DETAILS_COPY_TEXT',
  ACCOUNT_PAYMENT_OPTIONS_FORM_PAYMENT_DETAILS_LEFT_TEXT = 'ACCOUNT_PAYMENT_OPTIONS_FORM_PAYMENT_DETAILS_LEFT_TEXT',
  ACCOUNT_PAYMENT_OPTIONS_FORM_PAYMENT_BANK_DETAILS_LEFT_TEXT_OPTION_1 = 'ACCOUNT_PAYMENT_OPTIONS_FORM_PAYMENT_BANK_DETAILS_LEFT_TEXT_OPTION_1',
  ACCOUNT_PAYMENT_OPTIONS_FORM_PAYMENT_BANK_DETAILS_LEFT_TEXT_OPTION_2 = 'ACCOUNT_PAYMENT_OPTIONS_FORM_PAYMENT_BANK_DETAILS_LEFT_TEXT_OPTION_2',
  ACCOUNT_PAYMENT_OPTIONS_FORM_PAYMENT_BANK_DETAILS_LEFT_TEXT_OPTION_3 = 'ACCOUNT_PAYMENT_OPTIONS_FORM_PAYMENT_BANK_DETAILS_LEFT_TEXT_OPTION_3',
  ACCOUNT_PAYMENT_OPTIONS_FORM_PAYMENT_BANK_DETAILS_LEFT_TEXT_OPTION_4 = 'ACCOUNT_PAYMENT_OPTIONS_FORM_PAYMENT_BANK_DETAILS_LEFT_TEXT_OPTION_4',
  ACCOUNT_PAYMENT_OPTIONS_FORM_PAYMENT_DETAILS_INFO = 'ACCOUNT_PAYMENT_OPTIONS_FORM_PAYMENT_DETAILS_INFO',
  ACCOUNT_PAYMENT_OPTIONS_FORM_PAYMENT_BANK_DETAILS_INFO = 'ACCOUNT_PAYMENT_OPTIONS_FORM_PAYMENT_BANK_DETAILS_INFO',
  ACCOUNT_PAYMENT_OPTIONS_FORM_PAYMENT_DETAILS_INFO_ACCEPTED_BY = 'ACCOUNT_PAYMENT_OPTIONS_FORM_PAYMENT_DETAILS_INFO_ACCEPTED_BY',
  ACCOUNT_PAYMENT_OPTIONS_FORM_PAYMENT_DETAILS_INFO_ACCEPTED_DESCRIPTION = 'ACCOUNT_PAYMENT_OPTIONS_FORM_PAYMENT_DETAILS_INFO_ACCEPTED_DESCRIPTION',
  ACCOUNT_PAYMENT_OPTIONS_FORM_PAID_BUTTON_LABEL = 'ACCOUNT_PAYMENT_OPTIONS_FORM_PAID_BUTTON_LABEL',
  ACCOUNT_PAYMENT_OPTIONS_FORM_SUBMIT_BUTTON_LABEL = 'ACCOUNT_PAYMENT_OPTIONS_FORM_SUBMIT_BUTTON_LABEL',
  MY_ACCOUNT_PURCHASES_MY_DOCUMENTS_BUTTON_1_LABEL = 'MY_ACCOUNT_PURCHASES_MY_DOCUMENTS_BUTTON_1_LABEL',
  MY_ACCOUNT_PURCHASES_MY_DOCUMENTS_BUTTON_2_LABEL = 'MY_ACCOUNT_PURCHASES_MY_DOCUMENTS_BUTTON_2_LABEL',
  MY_ACCOUNT_PURCHASES_MY_DOCUMENTS_BUTTON_3_LABEL = 'MY_ACCOUNT_PURCHASES_MY_DOCUMENTS_BUTTON_3_LABEL',
  MY_ACCOUNT_PURCHASES_MY_DOCUMENTS_BUTTON_4_LABEL = 'MY_ACCOUNT_PURCHASES_MY_DOCUMENTS_BUTTON_4_LABEL',
  MY_ACCOUNT_PURCHASES_PAYMENT_RECEIVED = 'MY_ACCOUNT_PURCHASES_PAYMENT_RECEIVED',
  MY_ACCOUNT_PURCHASES_REMAINING_AMOUNT_PAYMENT = 'MY_ACCOUNT_PURCHASES_REMAINING_AMOUNT_PAYMENT',
  MY_ACCOUNT_PURCHASES_RESERVATION_DEPOSIT = 'MY_ACCOUNT_PURCHASES_RESERVATION_DEPOSIT',
  MY_ACCOUNT_PURCHASE_EMPTY_ACCOUNT = 'MY_ACCOUNT_PURCHASE_EMPTY_ACCOUNT',
}
