import React, { ReactNode } from 'react';
import styles from './ToasterContent.module.scss';
import classNames from 'classnames';
import { Button } from '../Button/Button';

type ActionType =
  | {
      type: 'custom';
      actionComponent: React.ComponentType<any>;
    }
  | {
      type: 'retry' | 'undo';
      onAction: () => void;
    };

type ToasterProps = {
  variant: 'success' | 'error' | 'warning' | 'info';
  message: ReactNode;
  action?: ActionType;
  closeToast: () => void;
  toastId: string | number;
};

export const ToasterContent = ({ variant, message, action, closeToast, toastId }: ToasterProps) => {
  const getAction = () => {
    switch (action?.type) {
      case 'custom': {
        const CustomComponent = action.actionComponent;
        return <CustomComponent closeToast={closeToast} toastId={toastId} />;
      }
      case 'retry':
      case 'undo':
        return (
          <Button
            type="button"
            className={styles.actionButton}
            size="small"
            variant="tertiary"
            onClick={() => {
              action.onAction();
              closeToast();
            }}
          >
            {action.type === 'undo' ? 'Undo' : 'Retry'}
          </Button>
        );
      default:
        return null;
    }
  };

  return (
    <div id={'custom-toastify'} className={classNames(styles.container, `toastify__container--${variant}`)}>
      <div className={styles.content}>
        <div className={styles.message} id={'custom-toastify__message'}>
          {message}
        </div>
        {getAction()}
      </div>
    </div>
  );
};
