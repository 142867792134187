import { ContentfulMicrocopy } from 'src/data/Contentful/ContentfulGetMicrocopyListing';
import microcopyDefaultDictionary from 'src/data/microcopy/microcopyDefaultDictionary';
import { MicrocopyKey } from 'src/data/microcopy/microcopyDictionary';

export const getMicrocopyMap = (contentfulMicrocopy?: ContentfulMicrocopy[]) => {
  const microcopy = new Map<MicrocopyKey, string>(
    Object.entries(microcopyDefaultDictionary) as [MicrocopyKey, string][],
  );

  if (contentfulMicrocopy) {
    contentfulMicrocopy.forEach((item) => {
      microcopy.set(item.contentKey, item.content);
    });
  }

  return microcopy;
};
