import { Node } from 'unist';
import { visit } from 'unist-util-visit';

const supportedCustomDirectives = ['a', 'quote', 'img', 'video', 'instagram', 'carousel'];

export const customDirective: import('unified').Plugin<[], import('mdast').Root> = () => {
  return (tree: Node) => {
    visit(tree, (node) => {
      if (
        (node.type === 'leafDirective' || node.type === 'containerDirective' || node.type === 'textDirective') &&
        'name' in node &&
        typeof node.name === 'string'
      ) {
        if (supportedCustomDirectives.indexOf(node.name) < 0) {
          return;
        }

        const data = node.data || (node.data = {});
        const attributes = (node as any).attributes || {};

        (data as any).hName = node.name;
        (data as any).hProperties = attributes;
      }
    });
  };
};
